"use strict";

var module = angular.module("aanstellingspas.profile", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/profile/", {
    controller: "ProfileController",
    templateUrl: "app/modules/profile/profile.view.html",
    requireAuth: true,
    title: "titles.my_settings",
    navigation_id: "profile",
  });
});

module.controller("ProfileController", [
  "$scope",
  "$rootScope",
  "$resource",
  "backendBaseUrl",
  "organisatieService",
  "userpropertiesservice",
  "notificationservice",
  "$translate",
  "swangular",
  function (
    $scope,
    $rootScope,
    $resource,
    backendbaseUrl,
    organisatieService,
    userpropertiesservice,
    notificationService,
    $translate,
    swangular
  ) {
    $scope.factuurAdresModel = {};
    $scope.accountEmailAdres = $rootScope.userproperties.emailaddress;
    $scope.kvkNummer = "";
    $scope.btwNummer = "";

    var organisatieId;

    // initial loading of data

    // get the bedrijf for this user
    organisatieService
      .getOrganisatieResourceForThisUser()
      .then(function succes(organisatie) {
        organisatieId = organisatie.id;
        $scope.kvkNummer = organisatie.kvkNummer;
        $scope.btwNummer = organisatie.btwNummer;

        $resource(backendbaseUrl + "/organisatie/:organisatieId/adres")
          .get({
            organisatieId: organisatie.id,
          })
          .$promise.then(function succes(adresModel) {
            $scope.verzendAdresModel = adresModel.verzendAdres;
            $scope.factuurAdresModel = adresModel.factuurAdres;
          });
      });

    // get the userproperties from the service
    userpropertiesservice.getUserProperties().then(
      function success(userProperties) {
        $scope.userProperties = userProperties;
        console.log(userProperties);
      },
      function failure(result) {
        $log.error(result);
      }
    );

    /*
     * save the account email address
     */
    $scope.saveAccountEmailAdres = function () {
      $resource(backendbaseUrl + "/user/emailaddress", null, {
        post: {
          method: "POST",
        },
      })
        .save(
          {},
          {
            newEmailaddress: $scope.accountEmailAdres,
          }
        )
        .$promise.then(
          function succes() {
            // set forms pristine so the save button is disabled again after saving
            $scope.accountgegevensForm.$setPristine();
            swangular.open({
              title: $translate.instant("confirmationModal.titel"),
              htmlTemplate:
                "app/modules/general/modals/confirm-account/confirm-account.view.html",
              confirmButtonText: $translate.instant("confirmationModal.button"),
              customClass: "customModal",
            });
          },
          function failure(result) {
            if (result.status === 409) {
              $scope.accountgegevensForm.$setPristine();
              notificationService.error(
                $translate.instant("accountgegevens.emailadres.conflict")
              );
            }
          }
        );
    };

    /*
     * save the verzendadres
     */
    $scope.saveVerzendAdres = function () {
      $resource(
        backendbaseUrl + "/organisatie/:organisatieId/verzendadres",
        null,
        {
          update: {
            method: "PUT",
          },
        }
      )
        .update(
          {
            organisatieId: organisatieId,
          },
          $scope.verzendAdresModel
        )
        .$promise.then(function succes() {
          notificationService.info($translate.instant("saved_verzendadres"));
          // set forms pristine so the save button is disabled again after saving
          $scope.verzendAdresForm.$setPristine();
          $rootScope.$broadcast("profile-updated");
        });
    };

    /*
     * save the factuur adres
     * and updates digitaleFactuur adres
     */
    $scope.saveFactuurAdres = function () {
      $resource(
        backendbaseUrl + "/organisatie/:organisatieId/factuuradres",
        null,
        {
          update: {
            method: "PUT",
          },
        }
      )
        .update(
          {
            organisatieId: organisatieId,
          },
          $scope.factuurAdresModel
        )
        .$promise.then(function succes() {
          notificationService.info($translate.instant("saved_factuuradres"));
          // set forms pristine so the save button is disabled again after saving
          $scope.factuurAdresForm.$setPristine();
          $rootScope.$broadcast("profile-updated");
        });
    };

    /**
     * Save email preferences for almost expired passen
     */
    $scope.saveEmailPreferences = function () {
      $resource(backendbaseUrl + "/userproperties/pasexpirationemail", null, {
        update: { method: "PUT" },
      })
        .update(
          {
            organisatieId: organisatieId,
          },
          {
            receivePasExpirationEmailModel: $scope.receivePasExpirationEmail,
          }
        )
        .$promise.then(function succes() {
          notificationService.info($translate.instant("saved_voorkeuren"));
          $rootScope.$broadcast("profile-updated");
        });
    };
  },
]);
