var module = angular
  .module("aanstellingspas.service.feedbackservice", [])
  .factory("feedbackService", [
    "backendBaseUrl",
    "$resource",
    "$location",
    function (backendBaseUrl, $resource, $location) {
      var feedbackServiceInstance = {};

      /**
       * Sends feedback
       * @param  {Number} score               score (range 1-5)
       * @param  {string} comment  			comment (optional comment for score)
       */
      feedbackServiceInstance.sendFeedback = function (score, comment) {
        return $resource(backendBaseUrl + "/feedback").save({
          score: score,
          comment: comment,
          anonymous: false,
          url: $location.url(),
        });
      };

      return feedbackServiceInstance;
    },
  ]);
