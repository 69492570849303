var module = angular.module("aanstellingspas.gemeenteregistratie", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/gemeenteregistratie", {
    templateUrl:
      "app/modules/gemeenteregistratie/gemeenteregistratie.view.html",
    controller: "GemeenteregistratieController",
    title: "titles.gemeenteregistratie",
    hideTitle: true,
  });
});

module.controller("GemeenteregistratieController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$anchorScroll",
  "$timeout",
  "authService",
  "$rootScope",
  "gemeenteService",
  "notificationservice",
  "vcRecaptchaService",
  "$translate",
  "swangular",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $anchorScroll,
    $timeout,
    authService,
    $rootScope,
    gemeenteService,
    notificationService,
    vcRecaptchaService,
    $translate,
    swangular
  ) {
    $scope.gemeenten = [];
    $scope.showNotSubscribed = false;
    $scope.backHandler = function () {
      $location.path("/registratie-keuze");
    };

    $scope.$on("validPasswordEvent", function (event, data) {
      $scope.validPassword = data;
    });

    $scope.showUitlegModal = function () {
      swangular
        .open({
          title: $translate.instant("gemeenteregistratie.aansluiting.title"),
          width: "50%",
          type: "info",
          htmlTemplate:
            "app/modules/general/modals/abonnement-info/abonnement-info.view.html",
          showCancelButton: true,
          confirmButtonText: $translate.instant(
            "gemeenteregistratie.aansluiting.inschrijven"
          ),
          cancelButtonText: $translate.instant(
            "gemeenteregistratie.aansluiting.cancel"
          ),
        })
        .then(function (result) {
          if (result.value === true) {
            $scope.sendMail(
              "helpdesk@verkeersregelaarsexamen.nl",
              "Aanmelding gemeente"
            );
          }
        });
    };

    /**
     * Does a request to EVRM to get all gemeenten currently active in that system
     */
    var getGemeentenFromEvrm = function () {
      $resource(backendBaseUrl + "/evrmgemeenten")
        .query()
        .$promise.then(
          function success(result) {
            $scope.gemeenten = result;
          },
          function failure(result) {
            $log.error(result);
            if (result.status === 502) {
              swangular.swal({
                title: $translate.instant(
                  "gemeenteregistratie.error.evrm_unavailable"
                ),
                text: $translate.instant(
                  "gemeenteregistratie.error.evrm_unavailable_info"
                ),
                type: "error",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
          }
        );
    };

    getGemeentenFromEvrm();

    $scope.checkGemeenteSubscribed = function (gemeenteId) {
      $resource(backendBaseUrl + "/evrmgemeentesubscribed/" + gemeenteId)
        .get()
        .$promise.then(
          function success(result) {
            if (result.Subscribed) {
              $scope.showNotSubscribed = false;
            } else {
              $scope.showNotSubscribed = true;
            }
          },
          function failure(result) {
            $log.error(result);
            if (result.status === 502) {
              swangular.swal({
                title: $translate.instant(
                  "gemeenteregistratie.error.evrm_unavailable"
                ),
                text: $translate.instant(
                  "gemeenteregistratie.error.evrm_unavailable_info"
                ),
                type: "error",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
          }
        );
    };

    /*
     * checks via the backend if a username is available
     */
    $scope.checkemailaddressAvailable = function () {
      var emailaddress = $scope.registerModel.emailaddress;
      if (!_.isEmpty(emailaddress)) {
        authService.emailaddressAvailable(emailaddress).$promise.then(
          function (result) {
            // set the validity of the 'unique' validator on the username form fields
            // 'unique' is a custom validation that gets 'spawned' here
            $rootScope.registerForm.emailaddress.$setValidity(
              "unique",
              result.available
            );
          },
          function failure() {}
        );
      }
    };

    /*
     * Function that checks if a prijs has a decimal.
     * It is used in the view to set an ' ,- ' sign
     */
    $scope.hasDecimals = function (prijs) {
      prijs = prijs + "";
      var decimal = ".",
        comma = ",";
      if (prijs.indexOf(decimal) !== -1 || prijs.indexOf(comma) !== -1) {
        return true;
      }
      return false;
    };

    $scope.sendMail = function (emailAddress, subject) {
      $window.open("mailto:" + emailAddress + "?subject=" + subject, "_blank");
    };

    /*
     * Function for setting registermodel when the user has clicked on the 'choose this company' button
     */
    var setRegisterModel = function (subdossiernummer) {
      // creating scopes
      $scope.registerModel = {};
      $scope.gekozenBedrijf;

      // gets KvkNummer and subdossiernummer from previous step by URL parameters
      $scope.registerModel.kvkNummer = $scope.kvkNummer;
      $scope.registerModel.subdossiernummer = subdossiernummer;
    };

    /*
     * Does the actual registration api call after all fields are valid
     */
    $scope.register = function () {
      var gekozenGemeente = _.find($scope.gemeenten, function (g) {
        return g.Id === $scope.registerModel.gemeenteId;
      });

      gemeenteService
        .createGemeente(
          $scope.registerModel.emailaddress,
          $scope.registerModel.contactpersoonvoornaam +
            " " +
            $scope.registerModel.contactpersoonachternaam,
          gekozenGemeente.Gemeente,
          gekozenGemeente.Id,
          $scope.registerModel.afdeling,
          $scope.registerModel.huisnummer,
          $scope.registerModel.huisnummertoevoeging,
          $scope.registerModel.plaats,
          $scope.registerModel.vestigingsplaats,
          $scope.registerModel.postcode,
          $scope.registerModel.straatnaam,
          $scope.registerModel.telefoonnummer,
          $scope.registerModel.password,
          $scope.registerModel.consentGiven,
          $scope.gRecaptchaResponse
        )

        .$promise.then(
          function (result) {
            $scope.doneRegistering = true;
          },
          function (result) {
            $log.error("Could not create user/bedrijf, error: ", result);
            notificationService.error(
              $translate.instant("gemeenteregistratie.error.general")
            );
            vcRecaptchaService.reload();
          }
        );
    };
  },
]);
