"use strict";

var module = angular.module("aanstellingspas.kruispunten", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/kruispunten/", {
    templateUrl: "app/modules/kruispunten/kruispunten.view.html",
    controller: "KruispuntenController",
    title: "titles.kruispunten",
    requireAuth: false,
    navigation_id: "kruispunten",
  });
});

module.controller("KruispuntenController", [
  "$scope",
  "$log",
  "$resource",
  "backendBaseUrl",
  "$rootScope",
  "$interval",
  function ($scope, $log, $resource, backendBaseUrl, $rootScope, $interval) {
    //kruispunten from the get request
    $scope.kruispunten = [];

    //the displayed and filtered kruispunten
    $scope.displayedKruispunten = [];

    // Check every second for language change and update currentLanguage if so
    var languageCheck = $interval(function () {
      if ($rootScope.getLanguage() == "nl") {
        $scope.currentLanguage = "nl";
      } else if ($rootScope.getLanguage() == "en") {
        $scope.currentLanguage = "en";
      }
    }, 1000);

    // Clear language check when leaving this module
    $scope.$on("$destroy", function () {
      $interval.cancel(languageCheck);
    });

    // Get kruispunten list from the backend
    $resource(backendBaseUrl + "/kruispuntenlijst")
      .query()
      .$promise.then(
        function success(result) {
          $scope.kruispunten = result;
          $scope.kruispunten = _.sortBy($scope.kruispunten, "plaats");
          $scope.kruispunten = _.filter(
            $scope.kruispunten,
            function (kruispunt) {
              return kruispunt.goedgekeurd;
            }
          );
        },
        function failure(result) {
          $log.error(result);
        }
      );

    $scope.orderBy = function (column) {
      // if the currently sorted column was clicked again
      if ($scope.currentOrder === column) {
        if ($scope.currentOrderDirection === "desc") {
          $scope.currentOrderDirection = "asc";
        } else {
          $scope.currentOrderDirection = "desc";
        }
      } else {
        $scope.currentOrder = column;
        $scope.currentOrderDirection = "asc";
      }

      $scope.kruispunten = _.sortByOrder(
        $scope.kruispunten,
        column,
        $scope.currentOrderDirection
      );
    };
  },
]);
