"use strict";

var module = angular
  .module("aanstellingspas.home", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/home/", {
      templateUrl: "app/modules/home/home.view.html",
      controller: "HomeController",
      title: "titles.home",
      hideTitle: true,
    });
  })

  .controller("HomeController", [
    "$anchorScroll",
    "$scope",
    "$location",
    "$rootScope",
    "$translate",
    function ($anchorScroll, $scope, $location, $rootScope, $translate) {
      /*
       * Scroll to anchor set in view function (ng-click="scrollTo(anchor)")
       * Make global module for use in whole application if needed
       */
      $scope.scrollTo = function (anchor) {
        // set the location.hash to the id of
        // the element you wish to scroll to.
        $location.hash(anchor);

        // always scroll to 60 pixels earlier because of header height
        $anchorScroll.yOffset = 60;
        // scroll to appended url anchor
        $anchorScroll();
      };

      if ($rootScope.loggedin) {
        $location.path("/dashboard");
      }
    },
  ]);
