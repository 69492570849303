"use strict";

var module = angular
  .module("aanstellingspas.pas", [
    "ui.bootstrap.tpls",
    "ui.bootstrap.paging",
    "ui.bootstrap.pager",
    "ui.bootstrap.pagination",
  ])

  .config(function ($routeProvider) {
    $routeProvider.when("/pas", {
      templateUrl: "app/modules/pas/pas.view.html",
      controller: "PasController",
      title: "titles.pas",
      navigation_id: "pas",
      reloadOnSearch: false,
    });
  })

  .controller("PasController", [
    "$scope",
    "$log",
    "$location",
    "$resource",
    "backendBaseUrl",
    "$routeParams",
    function (
      $scope,
      $log,
      $location,
      $resource,
      backendBaseUrl,
      $routeParams
    ) {
      $scope.passen = [];

      //get all queryparams from the route
      $scope.pageToReturnTo = $routeParams.currentPage;
      $scope.totalAmount = 0;
      $scope.currentPage = $routeParams.currentPage;
      $scope.currentpageSize = $routeParams.currentpageSize
        ? $routeParams.currentpageSize
        : 10;
      $scope.currentOrder = $routeParams.currentOrder
        ? $routeParams.currentOrder
        : "afgiftedatum";
      $scope.currentOrderDirection = $routeParams.currentOrderDirection
        ? $routeParams.currentOrderDirection
        : "desc";

      //wrapper function for returning the currentPage
      $scope.getCurrentpage = function () {
        return $scope.currentPage;
      };

      //wrapper function for returning the currentOrder
      $scope.getCurrentOrder = function () {
        return $scope.currentOrder;
      };
      //wrapper function for returning the currentOrderDirection
      $scope.getCurrentOrderDirection = function () {
        return $scope.currentOrderDirection;
      };
      //wrapper function for returning the currentpageSize
      $scope.getCurrentpageSize = function () {
        return $scope.currentpageSize;
      };
      $scope.resource = $resource(backendBaseUrl + "/pas", {
        //this sets all default queryparams if they are set
        pageNumber: $scope.getCurrentpage,
        pageSize: $scope.getCurrentpageSize,
        orderBy: $scope.getCurrentOrder,
        direction: $scope.getCurrentOrderDirection,
      });
      $scope.getPassen = function getPassen() {
        $scope.resource.get().$promise.then(
          function success(result) {
            if ($scope.totalAmount === 0) {
              //if there were no results before, we want to go to the pageToReturnTo when results are in
              if ($scope.pageToReturnTo) {
                $scope.currentPage = $scope.pageToReturnTo;
              }
            }
            $scope.totalAmount = result.totalAmount;
            $scope.passen = result.passen;
            _.forEach($scope.passen, function (pas) {
              const afgifteDatum = new Date(pas.afgifteDatum);
              const geldigTotDatum = new Date(pas.geldigTot);
              pas.searchableData = [
                afgifteDatum.getDate() +
                  "-" +
                  (afgifteDatum.getMonth() + 1) +
                  "-" +
                  afgifteDatum.getFullYear(),
                geldigTotDatum.getDate() +
                  "-" +
                  (geldigTotDatum.getMonth() + 1) +
                  "-" +
                  geldigTotDatum.getFullYear(),
                pas.achternaam,
                pas.pasnummer,
                pas.status,
                pas.type,
              ];
            });
          },
          function failure(result) {
            $log.error(result);
          }
        );
      };

      $scope.getPassen();

      $scope.verlengingNeeded = function (pas) {
        if (
          moment(pas.geldigTot).isBetween(moment(), moment().add(6, "months"))
        ) {
          return ($scope.verlengingVerplicht = true);
        }
      };

      //Changes the current route to the pasdetail view
      $scope.navigateToPasDetail = function (pasId) {
        $location.url($location.path());
        $location.path("/pas/" + pasId);
      };

      //This watches the currentPage scopevalue that changes when switching pages.
      $scope.$watch("currentPage", function (newValue, oldValue) {
        $scope.updateUrlParams();
        $scope.getPassen();
      });

      //This sets the url parameters to the current scopevalues for paging and sorting
      $scope.updateUrlParams = function () {
        $location.search({
          currentPage: $scope.currentPage,
          currentpageSize: $scope.currentpageSize,
          currentOrder: $scope.currentOrder,
          currentOrderDirection: $scope.currentOrderDirection,
        });
      };

      $scope.orderBy = function (column) {
        // if the currently sorted column was clicked again
        if ($scope.currentOrder === column) {
          if ($scope.currentOrderDirection === "desc") {
            $scope.currentOrderDirection = "asc";
          } else {
            $scope.currentOrderDirection = "desc";
          }
        } else {
          $scope.currentOrder = column;
          $scope.currentOrderDirection = "asc";
        }
        $scope.updateUrlParams();
        $scope.getPassen();
      };
    },
  ]);
