var module = angular.module("aanstellingspas.emailverification", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/emailverification", {
    templateUrl: "app/modules/emailverification/emailverification.view.html",
    controller: "EmailVerificationController",
    title: "titles.emailverification",
  });
  $routeProvider.when("/resendemailverification", {
    templateUrl:
      "app/modules/emailverification/resendemailverification.view.html",
    controller: "ResendEmailVerificationController",
    title: "titles.resendemailverification",
    hideTitle: true,
  });
});

module.controller("EmailVerificationController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$routeParams",
  "authService",
  "bedrijfsService",
  "$translate",
  "swangular",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $routeParams,
    authService,
    bedrijfsService,
    $translate,
    swangular
  ) {
    // gets userId and mailactivationcode from URL parameters
    $scope.userId = $routeParams.userId;
    $scope.code = $routeParams.code;

    /*
     *  Verifies user's mailactivationcode in backend and redirects user
     *  to dashboard on success and to home on failure (i.e wrong activation code).
     */
    return $resource(backendBaseUrl + "/emailverification")
      .save({
        userId: $scope.userId,
        code: $scope.code,
      })
      .$promise.then(
        function success(result) {
          swangular.swal({
            title: $translate.instant("successSwal.title"),
            text: $translate.instant("successSwal.text"),
            type: "success",
            confirmButtonText: $translate.instant(
              "successSwal.buttons.confirm"
            ),

            preConfirm: function confirmAction(confirmed) {
              if (confirmed == true) {
                $location.path("/dashboard");
                $location.search("userId", null);
                $location.search("code", null);
                $scope.$apply();
              }
            },
          });
        },
        function failure(result) {
          swangular.swal({
            title: $translate.instant("failureSwal.title"),
            text: $translate.instant("failureSwal.text"),
            type: "error",
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,

            preConfirm: function () {
              $location.path("/home");
              $location.search("userId", null);
              $location.search("code", null);
              $scope.$apply();
            },
          });
        }
      );
  },
]);

module.controller("ResendEmailVerificationController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "notificationservice",
  "vcRecaptchaService",
  "$translate",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    notificationService,
    vcRecaptchaService,
    $translate
  ) {
    $scope.resendEmailConfirmationModel = {};

    $scope.resendEmailVerification = function () {
      $resource(backendBaseUrl + "/emailverificationresend")
        .save({
          emailAddress: $scope.resendEmailConfirmationModel.emailAddress,
          RecaptchaResponse:
            $scope.resendEmailConfirmationModel.RecaptchaResponse,
        })
        .$promise.then(
          function succes() {
            vcRecaptchaService.reload();
            $translate("resendSuccessNotification", {
              emailaddress: $scope.resendEmailConfirmationModel.emailAddress,
            }).then(function (translation) {
              notificationService.success(translation);
            });
          },
          function error(response) {
            if (response.status === 404) {
              $translate("resendNotFoundNotification").then(function (
                translation
              ) {
                notificationService.error(translation);
              });
              vcRecaptchaService.reload();
            } else {
              vcRecaptchaService.reload();
              $translate("resendErrorNotification").then(function (
                translation
              ) {
                notificationService.error(translation);
              });
            }
          }
        );
    };
  },
]);
