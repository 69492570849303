var module = angular.module("aanstellingspas.service.dirtycheckservice", [
  "angularDirtyformCheck",
]);

module.service("dirtyCheckDialog", dirtyCheckDialog);

dirtyCheckDialog.$inject = ["$translate", "swangular"];
function dirtyCheckDialog($translate, swangular) {
  return {
    show: function () {
      return swangular
        .swal({
          title: $translate.instant("services.dirtycheckservice.titel"),
          text: $translate.instant("services.dirtycheckservice.text"),
          type: "warning",
          allowOutsideClick: false,
          confirmButtonText: $translate.instant(
            "services.dirtycheckservice.annuleren"
          ),
          cancelButtonText: $translate.instant(
            "services.dirtycheckservice.doorgaan"
          ),
          showCancelButton: true,
        })
        .then(function (result) {
          if (result.value === true) {
            return;
          } else {
            //this causes dirtycheck plugin to not execute navigation. It does show an error in console though :/
            throw false;
          }
        });
    },
  };
}
