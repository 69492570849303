angular.module("idle-notification", []).directive("idleNotification", [
  "$translate",
  function ($rootScope, $log, $resource, backendBaseUrl, $location) {
    return {
      scope: {},
      controller: function (
        $scope,
        $log,
        $resource,
        backendBaseUrl,
        $location,
        $translate,
        swangular
      ) {
        $scope.showIdleNotification = $rootScope.showIdleNotification;
        $scope.amountOfItems = $rootScope.showIdleNotificationAmountOfProducts;

        $resource(backendBaseUrl + "/winkelmand")
          .get()
          .$promise.then(function succes(result) {
            $scope.showIdleNotification = result.showIdleNotification;
            $scope.amountOfItems = result.items.length;

            // Form single or plural modal html
            $scope.modalHtml = "<p>";
            if ($scope.amountOfItems === 1) {
              $scope.modalHtml +=
                $translate.instant("single.first") +
                " <strong>" +
                $scope.amountOfItems +
                " " +
                $translate.instant("single.second") +
                " </strong>" +
                $translate.instant("single.third");
            } else if ($scope.amountOfItems > 1) {
              $scope.modalHtml +=
                $translate.instant("plural.first") +
                " <strong>" +
                $scope.amountOfItems +
                " " +
                $translate.instant("plural.second") +
                " </strong>" +
                $translate.instant("plural.third");
            }

            $scope.modalHtml +=
              ".</p><p>" +
              $translate.instant("uitleg.first") +
              "</p><p>" +
              $translate.instant("uitleg.second") +
              "</p>";

            // Create modal if any producten in winkelmand
            if ($scope.showIdleNotification === true) {
              swangular
                .open({
                  title: $translate.instant("title"),
                  html: $scope.modalHtml,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true,
                  cancelButtonText: $translate.instant("later"),
                  confirmButtonText: $translate.instant("afrekenen"),
                  customClass: "customModal",
                })
                .then(function (result) {
                  if (result.value) {
                    $scope.goToBestelling();
                  } else if (result.dismiss === "cancel") {
                    $scope.snoozeNotification();
                  }
                });
            }
          });

        /*
         * sets snoozed date to today
         */
        $scope.snoozeNotification = function () {
          $resource(backendBaseUrl + "/winkelmand/snooze")
            .save()
            .$promise.then(function succes(result) {
              $rootScope.showIdleNotification = false;
              $scope.showIdleNotification = false;
            });

          $rootScope.showIdleNotification = false;
          $scope.showIdleNotification = false;
          $log.debug("notification has been snoozed");
        };

        $scope.goToBestelling = function () {
          $scope.snoozeNotification();

          $location.path("/winkelmand");
        };
      },
    };
  },
]);
