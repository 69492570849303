/* eslint-disable angular/interval-service */
/* eslint-disable angular/document-service */
/* eslint-disable angular/no-run-logic */
/* eslint-disable angular/window-service */
"use strict";

var initInjector = angular.injector(["ng"]);

// Declare app level module which depends on views, and components
var app = angular
  .module("aanstellingspas", [
    "ngRoute",
    "ngResource",
    "ngStorage",
    "angular-loading-bar",
    "ngFileUpload",
    "lrNotifier",
    "ngAnimate",
    "angularMoment",
    "angular-clipboard",
    "rt.select2",
    "mwl.calendar",
    "mgo-angular-wizard",
    "pascalprecht.translate",
    "app.i18n", //pre compiled translation file
    // services
    "aanstellingspas.backendbaseurl",
    "aanstellingspas.service.authinterceptorservice",
    "aanstellingspas.service.servererrorinterceptorservice",
    "aanstellingspas.service.authservice",
    "aanstellingspas.service.bedrijfsservice",
    "aanstellingspas.service.organisatieservice",
    "aanstellingspas.service.gemeenteservice",
    "aanstellingspas.service.knockknock",
    "aanstellingspas.service.aanstellingspasbanner",
    "aanstellingspas.service.notificationservice",
    "aanstellingspas.service.aanvullennotificatieservice",
    "aanstellingspas.service.gemeenteaansluitingservice",
    "aanstellingspas.service.userpropertiesservice",
    "aanstellingspas.service.provincieservice",
    "aanstellingspas.service.downloadfile",
    "aanstellingspas.service.winkelmandservice",
    "aanstellingspas.service.dirtycheckservice",
    // components
    "aanstellingspas.templates",
    "aanstellingspas.bedrijfsregistratie",
    "aanstellingspas.gemeenteregistratie",
    "aanstellingspas.rijkswaterstaatregistratie",
    "aanstellingspas.bestellingen",
    "aanstellingspas.bvo",
    "aanstellingspas.dashboard",
    "aanstellingspas.emailverification",
    "aanstellingspas.examenoverzicht",
    "aanstellingspas.examenplannen",
    "aanstellingspas.examenreserveren",
    "aanstellingspas.home",
    "aanstellingspas.landingpage",
    "aanstellingspas.login",
    "aanstellingspas.logout",
    "aanstellingspas.profile",
    "aanstellingspas.reservering",
    "aanstellingspas.wachtwoordvergeten",
    "aanstellingspas.winkelmandbekijken",
    "aanstellingspas.fileuploader",
    "aanstellingspas.redirect-pasaanvraag-wizard",
    "aanstellingspas.pasaanvraagwizard",
    "angularDirtyformCheck",
    "aanstellingspas.privacy-verklaring",
    "aanstellingspas.aanvragen",
    "aanstellingspas.pasaanvraag-keuze",
    "aanstellingspas.pasaanvraag-eerste",
    "aanstellingspas.pasaanvraag-verlenging",
    "aanstellingspas.pasaanvraag-verlenging-correct",
    "aanstellingspas.registratie-keuze",
    "aanstellingspas.pasaanvraag-eerste-correct",
    "aanstellingspas.ctabar",
    "aanstellingspas.pasaanvraag-duplicaat",
    "aanstellingspas.pasaanvraag-duplicaat-correct",
    "aanstellingspas.pasaanvraag-omzetting",
    "aanstellingspas.pasaanvraag-omzetting-correct",
    "aanstellingspas.benodigdedocumenten",
    "aanstellingspas.provincieregistratie",
    "aanstellingspas.passen",
    "aanstellingspas.helpbar",
    "aanstellingspas.vrijstellingsaanvraag",
    "aanstellingspas.passwordChecker",
    "aanstellingspas.verlengingModal",
    "aanstellingspas.emailverificationupdate",
    "aanstellingspas.pas",
    "aanstellingspas.pasdetail",
    "aanstellingspas.profile-medewerker-uitnodigen",
    "aanstellingspas.registratie-bestaande-organisatie",
    "aanstellingspas.kruispunten",
    "aanstellingspas.language-selection",
    "aanstellingspas.toegankelijkheid",
    "aanstellingspas.sitemap",
    "aanstellingspas.werknemersverklaring",

    // other stuff
    "aanstellingspas.directives",
    "aanstellingspas.filters.getextention",
    "angularUtils.directives.dirPagination",
    "cookie-wall",
    "idle-notification",
    "ngFileSaver",
    "ApplicationInsightsModule",
    "text-mask",
    "aanstellingspas.service.feedbackservice",
    "ngSanitize",
    "ngAria",
    "swangular",
  ])
  .config([
    "$routeProvider",
    "cfpLoadingBarProvider",
    "$localStorageProvider",
    "$httpProvider",
    "lrNotifierProvider",
    "$compileProvider",
    "calendarConfig",
    "dirtyCheckServiceProvider",
    "stConfig",
    "applicationInsightsServiceProvider",
    "$translateProvider",
    "swangularProvider",
    function (
      $routeProvider,
      cfpLoadingBarProvider,
      $localStorageProvider,
      $httpProvider,
      lrNotifierProvider,
      $compileProvider,
      calendarConfig,
      dirtyCheckServiceProvider,
      stConfig,
      applicationInsightsServiceProvider,
      $translateProvider,
      swangularProvider
    ) {
      // Default configuration options for SWAL2
      swangularProvider.setDefaults({
        reverseButtons: true,
        buttonsStyling: false,
      });

      // global route configuration, see the modules for individual routes
      $routeProvider.otherwise({
        redirectTo: "/home",
      });
      $routeProvider.when("/faq", {
        redirectTo: function (routeParams) {
          window.location = "https://svnl-info.nl";
        },
      });

      $httpProvider.interceptors.push("authInterceptorService");
      $httpProvider.interceptors.push("serverErrorInterceptorService");

      // cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
      cfpLoadingBarProvider.includeSpinner = true;
      // only show loading bar after 500 msec XHR loading
      cfpLoadingBarProvider.latencyThreshold = 500;

      // we cant translate this one. It will only be triggered when closing the browserwindow or tab.
      dirtyCheckServiceProvider.setDirtyMessage(
        "Uw wijzigingen worden mogelijk niet opgeslagen. Wilt u deze pagina verlaten?"
      );

      $localStorageProvider.setKeyPrefix("aanstellingspas-customerfrontend-");

      // sets notification lifetime in ms
      lrNotifierProvider.setTimer(10000); //in ms

      //set the pagination for smart-table to a nicer template
      stConfig.pagination.template =
        "app/shared/smart-table-templates/pagination.custom.html";

      // Set monday as first day of the week
      moment.updateLocale("nl", {
        week: {
          dow: 1, // Monday is the first day of the week
        },
      });

      var insightsOptions = {
        // applicationName: used as a 'friendly name' prefix to url paths
        // ex: myAmazingapp/mainView
        applicationName: "aanstellingspas-frontoffice",
        // autoPageViewTracking: enables the sending a event to Application Insights when
        // ever the $locationChangeSuccess event is fired on the rootScope
        autoPageViewTracking: true,
        // autoStateChangeTracking: enables the sending a event to Application Insights when
        // ever the $stateChangeSuccess event is fired on the rootScope. To enable 'autoPageViewTracking' option should be set too.
        autoStateChangeTracking: false,
        // autoLogTracking: enables the interception of calls to the $log service and have the trace
        // data sent to Application Insights.
        autoLogTracking: false,
        // autoExceptionTracking: enables calls to the $exceptionHandler service, usually unhandled exceptions, to have the error and stack data sent to Application Insights.
        autoExceptionTracking: true,
        // sessionInactivityTimeout: The time (in milliseconds) that a user session can be inactive, before a new session will be created (on the next api call). Default is 30mins.
        sessionInactivityTimeout: 1800000,
        // developerMode: Makes the service not post anything to AI but print it to the console instead
        developerMode: window.__env.appInsightsDevmode,
      };
      applicationInsightsServiceProvider.configure(
        window.__env.appInsightsTrackingId,
        insightsOptions
      );

      // configure i18n
      $translateProvider
        .useLoader("$translatePartialLoader", {
          urlTemplate: "i18n/app/modules/{part}/i18n/{lang}.json",
        })
        .preferredLanguage("nl")
        .fallbackLanguage("nl")
        .useLoaderCache("$translationCache")
        .useSanitizeValueStrategy("sanitize");
    },
  ])

  .run(function (
    $rootScope,
    $interval,
    authService,
    aanstellingspasbanner,
    $route,
    $log,
    $localStorage,
    $location,
    $window,
    select2Config,
    gemeenteAansluitingService,
    notificationservice,
    userpropertiesservice,
    $timeout,
    feedbackService,
    $translate,
    $translatePartialLoader,
    swangular
  ) {
    var getSelectedLanguage = function () {
      if (!$localStorage.userproperties) {
        if ($localStorage.temporaryLanguage) {
          return $localStorage.temporaryLanguage;
        } else {
          // if no userproperties and no localstorage is detected, return default nl
          return "nl";
        }
      } else {
        return $localStorage.userproperties.preferredLanguage;
      }
    };

    // Check every second for language change and update currentLanguage if so
    var languageCheck = $interval(function () {
      if (getSelectedLanguage() == "nl") {
        $rootScope.currentLanguage = "nl";
      } else if (getSelectedLanguage() == "en") {
        $rootScope.currentLanguage = "en";
      }
    }, 1000);

    // Clear language check when leaving this module
    $rootScope.$on("$destroy", function () {
      $interval.cancel(languageCheck);
    });

    var loadTranslations = function () {
      $translatePartialLoader.addPart("general");
      $translatePartialLoader.addPart("services");
      $translatePartialLoader.addPart("aanvragen");
      $translatePartialLoader.addPart("bedrijfsregistratie");
      $translatePartialLoader.addPart("bestellingen");
      $translatePartialLoader.addPart("bvo");
      $translatePartialLoader.addPart("cookie-wall");
      $translatePartialLoader.addPart("dashboard");
      $translatePartialLoader.addPart("directives/benodigde-documenten");
      $translatePartialLoader.addPart("directives/fileuploader");
      $translatePartialLoader.addPart("directives/helpbar");
      $translatePartialLoader.addPart("directives/pasaanvraag-wizard");
      $translatePartialLoader.addPart("directives/passwordchecker");
      $translatePartialLoader.addPart("directives/verlenging-modal");
      $translatePartialLoader.addPart("emailverification");
      $translatePartialLoader.addPart("examenoverzicht");
      $translatePartialLoader.addPart("examenplannen");
      $translatePartialLoader.addPart("examenreserveren");
      $translatePartialLoader.addPart("gemeenteregistratie");
      $translatePartialLoader.addPart("home");
      $translatePartialLoader.addPart("idle-notification");
      $translatePartialLoader.addPart("landingpage");
      $translatePartialLoader.addPart("login");
      $translatePartialLoader.addPart("logout");
      $translatePartialLoader.addPart("pasaanvraag-keuze");
      $translatePartialLoader.addPart("passen");
      $translatePartialLoader.addPart("privacy-verklaring");
      $translatePartialLoader.addPart("profile");
      $translatePartialLoader.addPart("profile-medewerker-uitnodigen");
      $translatePartialLoader.addPart("provincieregistratie");
      $translatePartialLoader.addPart("registratie-keuze");
      $translatePartialLoader.addPart("reservering");
      $translatePartialLoader.addPart("rijkswaterstaatregistratie");
      $translatePartialLoader.addPart("vrijstellingsaanvraag");
      $translatePartialLoader.addPart("wachtwoordvergeten");
      $translatePartialLoader.addPart("winkelmandbekijken");
      $translatePartialLoader.addPart("emailverificationupdate");
      $translatePartialLoader.addPart("pas");
      $translatePartialLoader.addPart("pasdetail");
      $translatePartialLoader.addPart("registratie-bestaande-organisatie");
      $translatePartialLoader.addPart("kruispunten");
      $translatePartialLoader.addPart("toegankelijkheid");
      $translatePartialLoader.addPart("werknemersverklaring");
    };
    loadTranslations();
    var updateLanguagesArray = function () {
      $rootScope.languages = [
        {
          language: "nl",
          selected: getSelectedLanguage() == "nl",
          imageUrl: "assets/images/nl.svg",
          altText: "Nederlands",
        },
        {
          language: "en",
          selected: getSelectedLanguage() == "en",
          imageUrl: "assets/images/en.svg",
          altText: "English",
        },
      ];
    };
    updateLanguagesArray();

    // set default language if no localstorage is found
    if (!$localStorage.userproperties) {
      if ($localStorage.temporaryLanguage) {
        $translate.use($localStorage.temporaryLanguage);
      } else {
        $localStorage.temporaryLanguage = "nl";
      }
    } else {
      //is preferredLanguage missing from userproperties?
      if (!$localStorage.userproperties.preferredLanguage) {
        if ($localStorage.temporaryLanguage) {
          $translate.use($localStorage.temporaryLanguage);
        } else {
          $localStorage.temporaryLanguage = "nl";
        }
      } else {
        $translate.use($localStorage.userproperties.preferredLanguage);
      }
    }

    $rootScope.$on(
      "$translateChangeSuccess",
      function (event, current, previous) {
        updateLanguagesArray();
      }
    );

    $rootScope.getLanguage = function () {
      return $translate.proposedLanguage() || $translate.use();
    };
    // Nederlandse tekst bij geen resultaten select2
    select2Config.formatNoMatches = function () {
      return $translate.instant("geen_resultaten");
    };
    //check if the user is logged in, set that in the rootscope, and check if
    //the auth ticket hasn't expired yet
    $rootScope.loggedin = authService.loggedin();

    //set the menu to collapsed initally
    $rootScope.showMenu = false;

    $rootScope.$on("feedbackProvided", function (event, feedback) {
      feedbackService
        .sendFeedback(feedback.score, feedback.comment)
        .$promise.then(function success() {
          $timeout(function () {
            swangular.close();
          }, 1500);
          $timeout(function () {
            $rootScope.verzonden = false;
          }, 2000);
        });
    });

    $rootScope.submitFeedback = function (
      score,
      feedbackComment,
      feedbackForm
    ) {
      $rootScope.$broadcast("feedbackProvided", {
        score: score,
        comment: feedbackComment,
        form: feedbackForm,
      });
      $rootScope.verzonden = true;
    };

    $rootScope.openFeedbackModal = function () {
      swangular.open({
        title: $translate.instant("feedback.titel"),
        htmlTemplate:
          "app/modules/general/modals/feedback/feedback-form.view.html",
        scope: $rootScope,
        showConfirmButton: false,
        showCloseButton: true,
      });
    };

    // retrieve the userproperties and load them in the rootscope
    if ($rootScope.loggedin) {
      $rootScope.userproperties = $localStorage.userproperties;
      $log.debug("userproperties loaded into rootscope from localStorage");
    }

    $rootScope.$on(
      "$routeChangeStart",
      function (event, nextRoute, currentRoute) {
        if (nextRoute.requireAuth && !$rootScope.loggedin) {
          event.preventDefault();

          var redirectUrl = $location.url();

          // User isn't authenticated
          $location.replace();
          $location.path("/login").search({
            redirectTo: redirectUrl,
          });
        }

        if (nextRoute.requireGemeente && !$rootScope.isInRole("gemeente")) {
          event.preventDefault();

          $location.path("/dashboard");
        }
      }
    );

    $rootScope.$on(
      "$routeChangeSuccess",
      function (currentRoute, previousRoute) {
        //Change page title, based on Route information
        if (!$route.current.title || $route.current.title === null) {
          $rootScope.title = "titles.SVNL";
          document.title = "Aanstellingspas";
          $log.debug("Page title not set, enter page title in module router");
        } else {
          $rootScope.title = $route.current.title;
          $translate($route.current.title).then(function (title) {
            document.title = title + " - Aanstellingspas";
          });
        }

        // set a variable to make sure the cookiewall doesn't show up on this
        // particular page
        if ($route.current.isPrivacyVerklaringPage) {
          $rootScope.isPrivacyVerklaringPage = true;
        } else {
          $rootScope.isPrivacyVerklaringPage = false;
        }

        //hide login header on login page
        if ($route.current.isLoginPage) {
          $rootScope.isLoginPage = true;
        } else {
          $rootScope.isLoginPage = false;
        }
        //check if the routeparam hideTitle is set and smite it in the rootScope
        if ($route.current.hideTitle) {
          $rootScope.hideTitle = true;
        } else {
          $rootScope.hideTitle = false;
        }

        $rootScope.currentRoute = $location.path();

        // set the current navigation id so we can highlight a button in the lefthand navigation
        // dont forget to set this value in each module that you display in the lefthand navigation
        $rootScope.current_navigation_id = $route.current.navigation_id;

        if ($rootScope.loggedin) {
          notificationservice.getNotifications();
        }

        /*
         * Set showMenu to false if not already to hide the overlay menu on mobile
         * Note that some routes are named with a forward slash behind the URL.
         * We do this because it causes AngularJS to reload the route when the user clicks
         * on a link when on a page with the same URL as in the link. The link has to be
         * written without the forward slash, so the URL is different from the URL in the routing.
         * The $routeChangeSuccess event will not be triggered by AngularJS if a user
         * clicks on the aforementioned link with the same URL as the current page.
         */
        if ($rootScope.showMenu === true) {
          $rootScope.showMenu = false;
        }

        // After route navigation set focus on main content for screenreaders and remove focus again
        var mainContent = document.getElementById("main-content");
        mainContent.tabIndex = 0;
        mainContent.focus();
        mainContent.tabIndex = -1;

        // add script html2pdf when we are at werknemersverklaring, remove if not
        $rootScope.scriptSrc =
          "assets/libs/html2pdf/v0.9.3/html2pdf.bundle.min.js";
        if ($route.current.isWerknemersVerklaringPage) {
          var node = document.createElement("script");
          node.type = "application/javascript";
          node.src = $rootScope.scriptSrc;
          node.async = false;
          document.getElementsByTagName("body")[0].appendChild(node);
        } else {
          var body = document.getElementsByTagName("body")[0];
          var scripts = body.getElementsByTagName("script");
          if (scripts.length >= 1) {
            for (var i = 0; i < scripts.length; i++) {
              if (scripts[i].src.includes($rootScope.scriptSrc)) {
                var parentElement = scripts[i].parentElement;
                parentElement.removeChild(scripts[i]);
              }
            }
          }
        }
      }
    );

    // Add this function to set focus on the main content
    $rootScope.skipToMainContent = function () {
      var mainContent = document.getElementById("main-content");
      mainContent.tabIndex = 0;
      mainContent.focus();
      mainContent.tabIndex = -1;
    };
    // Get H1 for screenreader
    $rootScope.getH1Screenreader = function () {
      if ($rootScope.title === "titles.dashboard") {
        return $rootScope.getDashboardTitle() + ". Dashboard.";
      } else {
        return document.getElementById("h1-desktop").innerHTML;
      }
    };

    $rootScope.$on("userpropertieschanged", function () {
      var prefferedLang = $localStorage.userproperties.preferredLanguage;

      if (prefferedLang != null && prefferedLang != undefined) {
        $translate.use(prefferedLang);
      } else {
        $translate.use("nl");
      }
    });

    $rootScope.switchLang = function () {
      var currentLang = $translate.use();
      var langToSave = "";
      if (currentLang === "en") {
        $translate.use("nl");
        langToSave = "nl";
        moment.updateLocale("nl", {
          week: {
            dow: 1, // Monday is the first day of the week
          },
        });
        $rootScope.screenreaderSwitchedLanguage =
          "Geselecteerde taal: Nederlands";
      } else {
        $translate.use("en");
        langToSave = "en";
        moment.updateLocale("en", {
          week: {
            dow: 1, // Monday is the first day of the week
          },
        });
        $rootScope.screenreaderSwitchedLanguage = "Selected language: English";
      }

      userpropertiesservice.updateLanguageSetting(langToSave);

      // send request to backend to save this setting

      // update setting in localstorage?
    };
    //show the banner
    aanstellingspasbanner();

    // run a timer that checks every x second if the auth ticket hasn't expired yet
    // don't run when at werknemersverklaring page

    setInterval(function () {
      if (!$route.current.isWerknemersVerklaringPage) {
        $rootScope.loggedin = authService.loggedin();
        $rootScope.$digest(); // because this runs outside the main angular loop
        // run digest to let angular know that this value has changed
      }
    }, 10000);

    // watch the window size and make the lefthand navigation forcibly visible when we are on 'desktop'/md size
    angular.element($window).on("resize", function () {
      if ($window.innerWidth >= 992) {
        $rootScope.showMenu = true;
      }
      if ($window.innerWidth < 992) {
        $rootScope.showMenu = false;
      }
      $rootScope.$digest();
    });

    // make the lefthand navigation forcibly visible when we are on 'desktop'/md size on page load
    $rootScope.$on("$viewContentLoaded", function () {
      if ($window.innerWidth >= 992) {
        $rootScope.showMenu = true;
      }
    });

    $rootScope.cookieConsentGiven = false;

    //when cookies are accepted, enable Google Analytics
    $rootScope.$on("cookie-consent-given", function () {
      $rootScope.cookieConsentGiven = true;
    });

    //if cookies were accepted before, enable Google Analytics
    if ($localStorage.cookieConsent) {
      //cookies are accepted
      $rootScope.cookieConsentGiven = true;
    }

    $rootScope.getCurrentUserRole = function () {
      return authService.getCurrentUserRoles()[0];
    };

    $rootScope.isInRole = function (role) {
      return authService.isInRole(role);
    };

    $rootScope.hasAansluiting = function () {
      if ($rootScope.isInRole("gemeente")) {
        return gemeenteAansluitingService.hasAansluiting();
      }
    };
    var waitForRenderAndDoSomething = function () {
      if (!$rootScope.userproperties) {
        $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
      } else {
        var currentHour = new Date().getHours();
        var friendlyTimeIndicator;

        // check if username has a whitespace
        if ($rootScope.userproperties.realName.indexOf(" ") >= 0) {
          var realName = $rootScope.userproperties.realName.substr(
            0,
            $rootScope.userproperties.realName.indexOf(" ")
          );
        } else {
          var realName = $rootScope.userproperties.realName;
        }

        if (currentHour >= 0 && currentHour <= 12) {
          friendlyTimeIndicator = $translate.instant("greeting.goedemorgen", {
            username: realName,
          });
        }
        if (currentHour >= 12 && currentHour <= 18) {
          friendlyTimeIndicator = $translate.instant("greeting.goedemiddag", {
            username: realName,
          });
        }
        if (currentHour >= 18 && currentHour <= 24) {
          friendlyTimeIndicator = $translate.instant("greeting.goedenavond", {
            username: realName,
          });
        }

        return friendlyTimeIndicator;
      }
    };

    $rootScope.getDashboardTitle = function () {
      var title = waitForRenderAndDoSomething();
      return title;
    };

    if ($rootScope.loggedin) {
      notificationservice.getNotifications();
    }
  });
