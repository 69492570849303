"use strict";

var module = angular
  .module("aanstellingspas.pasaanvraag-keuze", [])

  .config(function ($routeProvider) {
    $routeProvider
      .when("/pasaanvraag", {
        templateUrl:
          "app/modules/pasaanvraag-keuze/pasaanvraag-keuze.view.html",
        controller: "PasaanvraagKeuzeController",
        title: "titles.keuze_nieuw",
        requireAuth: true,
        navigation_id: "pasaanvraag",
      })
      .when("/pasaanvraag-soort/:soort", {
        templateUrl:
          "app/modules/pasaanvraag-keuze/pasaanvraag-soort.view.html",
        controller: "PasaanvraagKeuzeController",
        title: "titles.keuze_aanvraag",
        requireAuth: true,
        navigation_id: "pasaanvraag",
      })
      .when("/pasaanvraag-soort/:soort/beroeps", {
        templateUrl:
          "app/modules/pasaanvraag-keuze/pasaanvraag-soort.view.html",
        controller: "PasaanvraagKeuzeController",
        title: "titles.keuze_beroeps",
        requireAuth: true,
        navigation_id: "pasaanvraag",
      })
      .when("/pasaanvraag-soort/:soort/transport", {
        templateUrl:
          "app/modules/pasaanvraag-keuze/pasaanvraag-soort.view.html",
        controller: "PasaanvraagKeuzeController",
        title: "titles.keuze_transport",
        requireAuth: true,
        navigation_id: "pasaanvraag",
      });
  })

  .controller("PasaanvraagKeuzeController", [
    "backendBaseUrl",
    "$scope",
    "$location",
    "$log",
    "$routeParams",
    "$localStorage",
    "organisatieService",
    "BedrijfBvoService",
    "authService",
    "$rootScope",
    "$translate",
    function (
      backendBaseUrl,
      $scope,
      $location,
      $log,
      $routeParams,
      $localStorage,
      organisatieService,
      BedrijfBvoService,
      authService,
      $rootScope,
      $translate
    ) {
      $scope.validBvo;
      organisatieService
        .getOrganisatieResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          // we now have the bedrijf for this user

          $scope.organisatieId = result.id;
        });
      $scope.soort = $routeParams.soort;

      $scope.goToEersteAanvraag = function () {
        // Gemeente only does beroeps
        if ($rootScope.isInRole("gemeente")) {
          $location.path("/pasaanvraag-eerste-gemeente/beroeps");
        } else if ($rootScope.isInRole("rijkswaterstaatgebruiker")) {
          $location.path("pasaanvraag-eerste/beroeps");
        } else {
          $location.path("/pasaanvraag-soort/eerste");
        }
      };

      $scope.goToVerlenging = function () {
        // Gemeente only does beroeps
        if ($rootScope.isInRole("gemeente")) {
          $location.path("/pasaanvraag-verlenging-gemeente/beroeps");
        } else if ($rootScope.isInRole("rijkswaterstaatgebruiker")) {
          $location.path("pasaanvraag-verlenging/beroeps");
        } else {
          $location.path("/pasaanvraag-soort/verlenging");
        }
      };

      $scope.goToDuplicaat = function () {
        // Gemeente only does beroeps
        if ($rootScope.isInRole("gemeente")) {
          $location.path("/pasaanvraag-duplicaat-gemeente/beroeps");
        } else if ($rootScope.isInRole("rijkswaterstaatgebruiker")) {
          $location.path("pasaanvraag-duplicaat/beroeps");
        } else {
          $location.path("/pasaanvraag-soort/duplicaat");
        }
      };

      $scope.goToOmzetting = function () {
        // Gemeente only does beroeps
        if ($rootScope.isInRole("gemeente")) {
          $location.path("/pasaanvraag-omzetting-gemeente/beroeps");
        } else if ($rootScope.isInRole("rijkswaterstaatgebruiker")) {
          $location.path("pasaanvraag-omzetting/beroeps");
        } else {
          $location.path("/pasaanvraag-soort/omzetting");
        }
      };

      $scope.goToBeroeps = function () {
        var done = organisatieService.handlePermissionPasaanvraag(
          $scope.organisatieId
        );

        if (done === "done" || done === undefined) {
          if ($scope.soort === "eerste") {
            $location.path("/pasaanvraag-eerste/beroeps");
          } else if ($scope.soort === "verlenging") {
            $location.path("/pasaanvraag-verlenging/beroeps");
          } else if ($scope.soort === "duplicaat") {
            $location.path("/pasaanvraag-duplicaat/beroeps");
          } else if ($scope.soort === "omzetting") {
            $location.path("/pasaanvraag-omzetting/beroeps");
          }
        }
      };

      $scope.goToTransport = function () {
        if ($scope.soort === "eerste") {
          $location.path("/pasaanvraag-eerste/transport");
        } else if ($scope.soort === "verlenging") {
          $location.path("/pasaanvraag-verlenging/transport");
        } else if ($scope.soort === "duplicaat") {
          $location.path("/pasaanvraag-duplicaat/transport");
        } else if ($scope.soort === "omzetting") {
          $location.path("/pasaanvraag-omzetting/transport");
        }
      };

      $scope.goToVrijstelling = function () {
        $location.path("/vrijstellingsaanvraag");
      };
    },
  ]);
