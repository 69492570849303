var module = angular.module("aanstellingspas.bedrijfsregistratie", [
  "ngSanitize",
  "rt.select2",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/bedrijfsregistratie", {
    templateUrl:
      "app/modules/bedrijfsregistratie/bedrijfsregistratie.view.html",
    controller: "BedrijfsregistratieController",
    title: "titles.bedrijfsregistratie",
    hideTitle: true,
  });
});

module.controller("BedrijfsregistratieController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$anchorScroll",
  "$timeout",
  "authService",
  "$rootScope",
  "bedrijfsService",
  "notificationservice",
  "vcRecaptchaService",
  "$translate",
  "swangular",
  "$sce",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $anchorScroll,
    $timeout,
    authService,
    $rootScope,
    bedrijfsService,
    notificationService,
    vcRecaptchaService,
    $translate,
    swangular
  ) {
    $scope.registerModel = {};

    /**
     * Set select for choosing country
     */
    $scope.registerModel.land = "Nederland";

    $scope.landen = [
      { value: "Nederland", name: "Nederland" },
      { value: "Belgi\u00EB", name: "Belgi\u00EB" },
      { value: "Duitsland", name: "Duitsland" },
      { value: "Frankrijk", name: "Frankrijk" },
      { value: "Ierland", name: "Ierland" },
      { value: "Luxemburg", name: "Luxemburg" },
      { value: "Verenigd_Koninkrijk", name: "Verenigd Koninkrijk" },
    ];

    if ($translate.use() == "en") {
      $scope.landen = [
        { value: "Nederland", name: "The Netherlands" },
        { value: "Belgi\u00EB", name: "Belgium" },
        { value: "Frankrijk", name: "France" },
        { value: "Duitsland", name: "Germany" },
        { value: "Ierland", name: "Ireland" },
        { value: "Luxemburg", name: "Luxembourg" },
        { value: "Verenigd_Koninkrijk", name: "United Kingdom" },
      ];
    }

    $scope.showKvk = true;
    $scope.onChangeLand = function (land) {
      if (land !== "Nederland") {
        $scope.showKvk = false;
      } else {
        $scope.showKvk = true;
      }
    };

    $scope.backHandler = function () {
      $location.path("/registratie-keuze");
    };

    $scope.$on("validPasswordEvent", function (event, data) {
      $scope.validPassword = data;
    });

    /*
     * checks via the backend if a username is available
     */
    $scope.checkemailaddressAvailable = function () {
      var emailaddress = $scope.registerModel.emailaddress;
      if (!_.isEmpty(emailaddress)) {
        authService.emailaddressAvailable(emailaddress).$promise.then(
          function (result) {
            // set the validity of the 'unique' validator on the username form fields
            // 'unique' is a custom validation that gets 'spawned' here
            $rootScope.registerForm.emailaddress.$setValidity(
              "unique",
              result.available
            );
          },
          function failure() {}
        );
      }
    };

    /*
     * Does the actual registration api call after all fields are valid
     */
    $scope.register = function () {
      if ($scope.validPassword === true) {
        bedrijfsService
          .createBedrijf(
            $scope.registerModel.emailaddress,
            $scope.registerModel.contactpersoonvoornaam,
            $scope.registerModel.contactpersoonachternaam,
            $scope.registerModel.afdeling,
            $scope.registerModel.telefoonnummer,
            $scope.registerModel.password,
            $scope.registerModel.kvkNummer,
            $scope.registerModel.btwNummer,
            $scope.registerModel.land,
            $scope.registerModel.bedrijfsnaam,
            $scope.registerModel.vestigingsplaats,
            $scope.registerModel.straatnaam,
            $scope.registerModel.huisnummer,
            $scope.registerModel.toevoeging,
            $scope.registerModel.postcode,
            $scope.registerModel.plaatsnaam,
            $scope.registerModel.consentGiven,
            $scope.gRecaptchaResponse
          )
          .$promise.then(
            function (result) {
              $scope.doneRegistering = true;
            },
            function (result) {
              $log.error("Could not create user/bedrijf, error: ", result);
              vcRecaptchaService.reload();
              swangular.swal({
                title: $translate.instant(
                  "bedrijfsregistratie.error.general_titel"
                ),
                text: $translate.instant("bedrijfsregistratie.error.general"),
                type: "error",
              });
            }
          );
      }
    };
  },
]);
