"use strict";

var module = angular
  .module("aanstellingspas.login", ["ngMessages"])

  .config(function ($routeProvider) {
    $routeProvider.when("/login/", {
      templateUrl: "app/modules/login/login.view.html",
      controller: "LoginController",
      title: "titles.login",
      hideTitle: true,
      isLoginPage: true,
    });
  });

module.controller("LoginController", [
  "$scope",
  "authService",
  "$location",
  "$rootScope",
  "$routeParams",
  "$localStorage",
  "$resource",
  "backendBaseUrl",
  "$timeout",
  "$route",
  "$translate",
  function (
    $scope,
    authService,
    $location,
    $rootScope,
    $routeParams,
    $localStorage,
    $resource,
    backendBaseUrl,
    $timeout,
    $route,
    $translate
  ) {
    /*
     * function to log in user
     * checks whether the form fields are filled
     * if empty, focuses cursor to empty field
     *
     * if filled in completely, redirects user
     * to dashboard (or url parameter 'redirectTo' if set)
     * and logs user in via authService
     */
    $scope.login = function () {
      if (!$scope.username || !$scope.password) {
        if (!$scope.username) {
          document.getElementsByName("username")[0].focus();
        } else {
          document.getElementsByName("password")[0].focus();
        }
      } else {
        var redirectTo = $routeParams.redirectTo;

        if (!redirectTo || redirectTo === null) {
          redirectTo = "/dashboard";
        }

        authService.login($scope.username, $scope.password, redirectTo);
      }
    };

    /*
     * init
     */

    // always redirects user to dashboard if he's logged in
    if ($rootScope.loggedin) {
      $location.path("/dashboard");
    }

    /*
     * checks whether emailAddress url parameter is set
     * if so, fill in the e-mailaddress form and
     * focus cursor on password field, if not, focus
     * cursor on emailAddress input
     */
    if ($routeParams.emailAddress) {
      $scope.username = $routeParams.emailAddress;
      document.getElementsByName("password")[0].focus();
    } else {
      document.getElementsByName("username")[0].focus();
    }
  },
]);
