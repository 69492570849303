var module = angular
  .module("aanstellingspas.service.gemeenteservice", [])
  .factory("gemeenteService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location
    ) {
      var gemeenteServiceInstance = {};

      /**
       * Create a new bedrijf
       * @param  {string} email               email
       * @param  {string} contactpersoonnaam  contactpersoonnaam
       * @param  {string} gemeentenaam        gemeentenaam
       * @param  {string} gemeenteId          gemeenteId
       * @param  {string} afdeling            afdeling
       * @param  {string} huisnummer          huisnummer
       * @param  {string} huisnummertoevoeging
       * @param  {string} plaats              plaats
       * @param  {string} vestigingsplaats    vestigingsplaats
       * @param  {string} postcode            postcode
       * @param  {string} straat              straat
       * @param  {string} telefoonnummer      telefoonnummer
       * @param  {string} password            password
       * @param  {bool}   consent             consent
       */

      gemeenteServiceInstance.createGemeente = function (
        email,
        contactpersoonnaam,
        gemeentenaam,
        gemeenteId,
        afdeling,
        huisnummer,
        huisnummertoevoeging,
        plaats,
        vestigingsplaats,
        postcode,
        straat,
        telefoonnummer,
        password,
        consent,
        captchatoken
      ) {
        return $resource(backendBaseUrl + "/gemeente").save({
          email: email,
          contactpersoonnaam: contactpersoonnaam,
          password: password,
          telefoonnummer: telefoonnummer,
          gemeenteNaam: gemeentenaam,
          gemeenteId: gemeenteId,
          huisnummer: huisnummer,
          huisnummertoevoeging: huisnummertoevoeging,
          plaats: plaats,
          vestigingsplaats: vestigingsplaats,
          postcode: postcode,
          afdeling: afdeling,
          straat: straat,
          consent: consent,
          captchatoken: captchatoken,
          preferredLanguage: $localStorage.temporaryLanguage,
        });
      };

      gemeenteServiceInstance.getGemeenteResourceForUser = function (userId) {
        return $resource(backendBaseUrl + "/user/" + userId + "/bedrijf").get()
          .$promise;
      };

      gemeenteServiceInstance.getBestellingen = function (bedrijfsId) {
        return $resource(
          backendBaseUrl + "/bedrijfbestellingen/" + bedrijfsId
        ).get().$promise;
      };

      gemeenteServiceInstance.getGemeenteResourceForThisUser = function () {
        return this.getBedrijfResourceForUser($localStorage.authticket.user_id);
      };

      return gemeenteServiceInstance;
    },
  ]);
