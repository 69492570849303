"use strict";

var module = angular
  .module("aanstellingspas.pasaanvraag-verlenging", [
    "ngMessages",
    "validbirthdate",
    "validaanstellingsdate",
    "angularDirtyformCheck",
  ])

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-verlenging/beroeps", {
      templateUrl:
        "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-beroeps.view.html",
      controller: "PasaanvraagVerlengingController",
      title: "titles.verlenging",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "beroeps",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-verlenging-gemeente/beroeps", {
      templateUrl:
        "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-beroeps-gemeente.view.html",
      controller: "PasaanvraagVerlengingController",
      title: "titles.verlenging",
      requireAuth: true,
      requireGemeente: true,
      navigation_id: "pasaanvraag",
      pasType: "beroeps",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-verlenging/transport", {
      templateUrl:
        "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-transport.view.html",
      controller: "PasaanvraagVerlengingController",
      title: "titles.verlenging",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "transport",
    });
  })

  .controller("PasaanvraagVerlengingController", [
    "backendBaseUrl",
    "$scope",
    "authService",
    "$location",
    "BedrijfBvoService",
    "organisatieService",
    "$localStorage",
    "$rootScope",
    "$resource",
    "$log",
    "$timeout",
    "$route",
    "$routeParams",
    "WizardHandler",
    "swangular",
    "$translate",
    function (
      backendBaseUrl,
      $scope,
      authService,
      $location,
      bvoService,
      organisatieService,
      $localStorage,
      $rootScope,
      $resource,
      $log,
      $timeout,
      $route,
      $routeParams,
      WizardHandler,
      swangular,
      $translate
    ) {
      $scope.backendBaseUrl = backendBaseUrl;
      $scope.organisatieId = null;
      $scope.showConfirmRenewalModal = true;

      $scope.pasAanvraagModel = {};

      if (!$rootScope.isInRole("rijkswaterstaatgebruiker")) {
        swangular
          .open({
            title: $rootScope.isInRole("gemeente")
              ? $translate.instant("popup.gemeente.titel")
              : $translate.instant("popup.titel"),
            htmlTemplate:
              "app/modules/directives/verlenging-modal/verlenging-modal.view.html",
            customClass: "customModal",
            allowOutsideClick: false,
            confirmButtonText: $translate.instant("popup.ga_verder"),
            cancelButtonText: $translate.instant("popup.naar_omzetting"),
            showCancelButton: true,
            showConfirmButton: true,
            scope: $scope,
          })
          .then(function (result) {
            if (result.value === true) {
              swangular.close();
            } else {
              if ($rootScope.isInRole("gemeente")) {
                $location.path("/pasaanvraag-omzetting-gemeente/beroeps");
              } else {
                $location.path("/pasaanvraag-soort/omzetting");
              }
            }
          });
      }

      organisatieService
        .getOrganisatieResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          // we now have the bedrijf for this user

          $scope.organisatieId = result.id;

          if ($route.current.pasType === "beroeps") {
            organisatieService.handlePermissionPasaanvraag(
              $scope.organisatieId
            );
          }
        });

      $scope.getstep = function () {
        return WizardHandler.wizard("aanvraag").currentStepNumber();
      };

      $scope.pasType = $routeParams.type;

      $scope.indienen = function () {
        var onderdelen = $scope.createOnderdelenFromForms();

        // do a correction for transport
        $resource(backendBaseUrl + "/pasaanvraag/").save(
          {
            type: "verlenging",
            pasType: $route.current.pasType,
            onderdelen: onderdelen,
          },
          function success(result) {
            $scope.setAllFormsPristine();
            $location.path("/pasaanvraag/ingediend");
            $rootScope.$broadcast("winkelmandchanged");
          },
          function fail(error) {
            $log.error(error);
          }
        );
      };
    },
  ]);
