/**
 *                 <ctabar
                        back-handler="handleBackButton()"
                        back-button-text="Go BACK!"
                        primary-handler="handlePrimaryButton()"
                        primary-button-text="Maak weloverwogen keuzes"
                        disable-primary-button="!someForm.$valid"
                        back-icon="fa-sign-out"
                        primary-icon="fa-cog"
                        >
                </ctabar>
 */

var module = angular.module("aanstellingspas.ctabar", []).directive("ctabar", [
  function () {
    return {
      // http://stackoverflow.com/a/21714314/6077862
      restrict: "E",
      transclude: true,
      replace: true,
      scope: {
        backHandler: "&",
        enableBackButton: "&",
        primaryHandler: "&",
        skipHandler: "&",
        disablePrimaryButton: "&",
        backButtonText: "@",
        primaryButtonText: "@",
        primaryButtonAriaLabel: "@",
        primaryIcon: "@",
        backIcon: "@",
        hideBackButton: "&",
        skipButtonText: "@",
        hideSkipButton: "&",
        enableSkipButton: "&",
      },
      templateUrl: "app/modules/directives/ctabar/ctabar.view.html",
      link: function (scope, element, attrs) {
        scope.showPrimary = function () {
          return angular.isDefined(attrs.primaryHandler);
        };
      },
    };
  },
]);
