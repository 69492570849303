var module = angular
  .module("aanstellingspas.service.userpropertiesservice", [])
  .factory("userpropertiesservice", [
    "lrNotifier",
    "backendBaseUrl",
    "$sce",
    "$rootScope",
    "$resource",
    "$localStorage",
    function (
      lrNotifier,
      backendBaseUrl,
      $sce,
      $rootScope,
      $resource,
      $localStorage
    ) {
      var userpropertiesServiceInstance = {};

      $rootScope.$on("profile-updated", function () {
        userpropertiesServiceInstance.updateUserProperties();
      });

      userpropertiesServiceInstance.getUserProperties = function () {
        return $resource(backendBaseUrl + "/userproperties").get().$promise;
      };

      userpropertiesServiceInstance.updateUserProperties = function () {
        var userId = $localStorage.authticket.user_id;

        $resource(backendBaseUrl + "/user/:userid/properties")
          .get({
            userid: userId,
          })
          .$promise.then(function (properties) {
            // construct an userproperties model and save it to local storage
            // this model will be retrieved and set to the view when the app starts
            $localStorage.userproperties = {};

            $localStorage.userproperties.needsToCheckBedrijfGegevens =
              properties.needsToCheckBedrijfGegevens;
            $localStorage.userproperties.emailaddress = properties.emailaddress;
            $localStorage.userproperties.realName = properties.realName;
            $localStorage.userproperties.roles = properties.roles;
            $localStorage.userproperties.preferredLanguage =
              properties.preferredLanguage;
            $localStorage.temporaryLanguage = properties.preferredLanguage;

            // because we are not restarting the app, set the properties now!

            $rootScope.userproperties = $localStorage.userproperties;
          });
      };

      userpropertiesServiceInstance.updateLanguageSetting = function (lang) {
        if ($localStorage.userproperties) {
          var request = $resource(
            backendBaseUrl + "/userproperties/language",
            {},
            {
              saveData: {
                method: "PUT",
                isArray: false,
              },
            }
          );

          var model = {};
          model.preferredLanguage = lang;

          request.saveData({}, model).$promise.then(
            function success(result) {
              // succes callback
              $localStorage.userproperties.preferredLanguage = lang;
              $localStorage.temporaryLanguage = lang;
              $rootScope.$broadcast("userpropertieschanged");
            },
            function failure(failureResult) {
              //failure callback
            }
          );
        } else {
          $localStorage.temporaryLanguage = lang;
        }
      };

      return userpropertiesServiceInstance;
    },
  ]);
