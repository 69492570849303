var module = angular
  .module("aanstellingspas.service.gemeenteaansluitingservice", [])
  .factory("gemeenteAansluitingService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    "authService",
    "$q",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location,
      authService,
      $q
    ) {
      var gemeenteaansluitingservice = {};

      var resource = $resource(backendBaseUrl + "/evrmgemeentesubscribed/");

      $rootScope.aansluitingRequestInProgress = false;

      /**
       * Checks if the current logged in user has gemeente role and if it has an aansluiting
       */
      gemeenteaansluitingservice.hasAansluiting = function () {
        if (!lscache.supported()) {
          alert("Local storage is unsupported in this browser");
          return;
        }

        // Remove all outdated data before checking
        lscache.flushExpired();

        var status = lscache.get("aansluitingsStatus");
        // if we have data in the cache, return it
        if (status) {
          return status.Subscribed;
        } else if (
          !status &&
          $rootScope.aansluitingRequestInProgress == false
        ) {
          getNewAansluitingStatus().then(function (result) {
            lscache.set("aansluitingsStatus", result, 60);
            $rootScope.aansluitingRequestInProgress = false;
          });
        }
      };

      gemeenteaansluitingservice.destroyAansluiting = function () {};

      $rootScope.$on("loggedout", function () {
        console.debug("destorying aansluitingstatus from storage...");
        delete $localStorage.aansluitingstatus;
        lscache.remove("aansluitingsStatus");
      });

      $rootScope.$on("loggedin", function () {
        if ($rootScope.isInRole("gemeente")) {
          getNewAansluitingStatus().then(function (result) {
            lscache.set("aansluitingsStatus", result, 60);
          });
        }
      });

      var getNewAansluitingStatus = function () {
        $rootScope.aansluitingRequestInProgress = true;
        return resource.get().$promise;
      };

      return gemeenteaansluitingservice;
    },
  ]);
