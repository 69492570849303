var module = angular
  .module("aanstellingspas.pasaanvraagwizard", [])

  .config(function ($routeProvider) {
    $routeProvider
      .when("/pasaanvraag/ingediend", {
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/ingediend.tpl.html",
        hideTitle: true,
        requireAuth: true,
        navigation_id: "pasaanvraag",
      })
      .when("/pasaanvraag/verbeterd", {
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/verbeterd.tpl.html",
        hideTitle: true,
        requireAuth: true,
        navigation_id: "pasaanvraag",
      });
  })

  //master templates
  .directive("aanvraagWizard", [
    "$timeout",
    "WizardHandler",
    "$localStorage",
    "$anchorScroll",
    "$translate",
    function (
      $timeout,
      WizardHandler,
      $localStorage,
      $anchorScroll,
      $translate
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        template: '<div ng-include="contentUrl"></div>',
        link: function (scope, element, attrs) {
          if (attrs.aanvraagtype === "verlenging-beroeps") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/verlenging-beroeps-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "verlenging-beroeps-gemeente") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/gemeente/verlenging-beroeps-gemeente-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "verlenging-transport") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/verlenging-transport-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "eersteaanvraag-beroeps") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/eersteaanvraag-beroeps-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "eersteaanvraag-beroeps-gemeente") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/gemeente/eersteaanvraag-beroeps-gemeente-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "eersteaanvraag-transport") {
            scope.vogTransport = true;
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/eersteaanvraag-transport-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "duplicaat") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/duplicaat-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "duplicaat-transport") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/duplicaat-transport-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "duplicaat-gemeente") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/gemeente/duplicaat-wizard-gemeente.tpl.html";
          } else if (attrs.aanvraagtype === "omzetting") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/omzetting-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "omzetting-transport") {
            scope.aanvraagtype = "omzetting-transport";
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/omzetting-transport-wizard.tpl.html";
          } else if (attrs.aanvraagtype === "omzetting-gemeente") {
            scope.contentUrl =
              "app/modules/directives/pasaanvraag-wizard/wizards/gemeente/omzetting-gemeente-wizard.tpl.html";
          }
          scope.$on("show-intro", function (event, args) {
            scope.showIntro = true;
            scope.showWizard = false;
          });

          scope.$on("hide-intro", function (event, args) {
            scope.showIntro = false;
            scope.showWizard = true;
          });
          scope.finished = false;
          scope.boundFormsToSteps = false;
          scope.FirstStep = true;
          scope.unSkippable = true;
          scope.forms = {};
          scope.model = {};

          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.correctableAanvraag) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.model.aanstelling = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "aanstelling",
                  }
                );
                scope.model.vog = _.find(scope.correctableAanvraag.onderdelen, {
                  type: "vog",
                });
                scope.model.pasfoto = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "pasfoto",
                  }
                );
                scope.model.persoonsgegevens = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "persoonsgegevens",
                  }
                );
                scope.model.code95 = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "code95",
                  }
                );
                scope.model.rijbewijs = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "rijbewijs",
                  }
                );
                scope.model.waarmerk = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "waarmerk",
                  }
                );

                // if we got a waarmerk onderdeel we need to do some stuff
                if (angular.isDefined(scope.model.waarmerk)) {
                  // this is a legacy waarmerk
                  if (scope.model.waarmerk.waarmerk.length === 5) {
                    scope.model.waarmerk.legacyWaarmerk =
                      scope.model.waarmerk.waarmerk + "";
                    scope.model.waarmerk.waarmerk = "";
                  }
                }
                if (angular.isDefined(scope.model.vog)) {
                  scope.isVerlenging = true;
                }
                scope.model.pasnummer = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "pasnummer",
                  }
                );
                scope.model.procesverbaal = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "procesVerbaal",
                  }
                );
                scope.model.werknemersverklaring = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "werknemersVerklaring",
                  }
                );
                scope.model.werkgever = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "werkgever",
                  }
                );
                scope.model.vrijstelling = _.find(
                  scope.correctableAanvraag.onderdelen,
                  {
                    type: "vrijstelling",
                  }
                );
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }

          var initializedWizard = {};
          scope.$watch(
            function () {
              return WizardHandler.wizard("aanvraag");
            },
            function (wizard) {
              if (wizard) {
                initializedWizard = wizard;
              }
            }
          );

          var getInitializedWizard = function () {
            var wizard = WizardHandler.wizard("aanvraag");
            if (wizard === undefined) {
              getInitializedWizard();
            } else {
              return wizard;
            }
          };

          scope.goToFirstUncorrectedStep = function () {
            $timeout(getInitializedWizard).then(function success(result) {
              var steps = result.getEnabledSteps();

              var steps = _.sortBy(steps, ["wzOrder"]);

              var afgekeurdeOnderdelen = _.filter(
                scope.correctableAanvraag.onderdelen,
                {
                  isGoedgekeurd: false,
                }
              );
              var afgekeurdeOnderdelenNaamString = [];

              for (var key in afgekeurdeOnderdelen) {
                var onderdeel = afgekeurdeOnderdelen[key];
                afgekeurdeOnderdelenNaamString.push(
                  onderdeel.type.toLowerCase()
                );
              }

              for (var stepKey in steps) {
                var step = steps[stepKey];
                for (var stringKey in afgekeurdeOnderdelenNaamString) {
                  var onderdeelString =
                    afgekeurdeOnderdelenNaamString[stringKey];
                  if (step.title.toLowerCase() == onderdeelString) {
                    result.goTo(step.title);
                    return;
                  }
                }
              }
            });
          };

          scope.allFormsValid = function () {
            for (var key in scope.forms) {
              var form = scope.forms[key];
              if (!form.$valid) {
                return false;
              }
            }
            return true;
          };
          scope.bindFormsToSteps = function () {
            for (var key in scope.forms) {
              var form = scope.forms[key];
              if (angular.isDefined(form)) {
                var formName = form.$name.split("forms.").pop();

                var step = scope.getStepByName(formName);

                step.form = form;
              }
            }
            scope.boundFormsToSteps = true;
          };

          // Little function that sets all available forms to pristine
          // to not get annoyed by the 'Are you sure you want to leave this page' popup
          scope.setAllFormsPristine = function () {
            for (var key in scope.forms) {
              var obj = scope.forms[key];
              obj.$setPristine();
            }
          };
          scope.next = function () {
            if (scope.boundFormsToSteps == false) {
              scope.bindFormsToSteps();
            }

            if (
              (scope.finished && !scope.editMode) ||
              (scope.editMode && scope.allFormsValid())
            ) {
              WizardHandler.wizard("aanvraag").goTo("Overzicht");
            }
            WizardHandler.wizard("aanvraag").next();
            $anchorScroll();
          };
          scope.back = function () {
            WizardHandler.wizard("aanvraag").previous();
            $anchorScroll();
          };
          scope.skip = function (formName, modelName) {
            _.forIn(scope.forms, function (value, key, object) {
              if (key === formName) {
                value.$setPristine();
                value.$valid = true;
              }
            });
            _.forIn(scope.model, function (value, key, object) {
              if (key === modelName) {
                scope.model[key] = {};
              }
            });
            scope.next();
          };
          scope.getStepByName = function (name) {
            var steps = WizardHandler.wizard("aanvraag").getEnabledSteps();

            var stepResult = _.find(steps, function (step) {
              return step.title === name;
            });
            return stepResult;
          };
          scope.$on("wizard:stepChanged", function (event, args) {
            // Little hack to allow overzicht to always be clicked once it has been visiteda
            if (args.step.title === "Overzicht") {
              args.step.completed = true;
              args.step.disabled = false;
              scope.finished = true;
            }

            if (WizardHandler.wizard("aanvraag").currentStepNumber() !== 1) {
              scope.FirstStep = false;
            } else {
              scope.FirstStep = true;
            }
          });
          scope.waarmerkOrBirthyearChanged = function () {
            //dont do this if we are in edit mode
            if (scope.editMode === true) {
              scope.forms.Waarmerk.waarmerk.$setValidity("valid", true);
              scope.forms.Waarmerk.geboortejaar.$setValidity("valid", true);
              return;
            }

            scope.forms.Waarmerk.waarmerk.$setValidity("invalid", true);
            scope.forms.Waarmerk.geboortejaar.$setValidity("invalid", true);

            _.forIn(scope.forms, function (value, key, object) {
              if (key !== "Waarmerk") {
                value.$setPristine();
              }
            });
            _.forIn(scope.model, function (value, key, object) {
              if (key !== "waarmerk") {
                scope.model[key] = {};
              }
            });

            scope.finished = false;
          };

          scope.createOnderdelenFromForms = function () {
            var onderdelen = [];
            scope.verlengingEndDate = moment()
              .add(5, "years")
              .format("DD-MM-Y");

            for (var key in scope.forms) {
              var form = scope.forms[key];

              if (key === "Aanstelling") {
                var onderdeel = {};
                onderdeel.aanstellingsnummer =
                  scope.model.aanstelling.aanstellingsnummer;
                if (angular.isDefined(scope.model.aanstelling.id)) {
                  onderdeel.id = scope.model.aanstelling.id;
                }

                if (scope.model.aanstelling.isVerlenging) {
                  onderdeel.looptijd = scope.verlengingEndDate;
                }
                onderdeel.isVerlenging = scope.model.aanstelling.isVerlenging;
                onderdeel.beperking = scope.model.aanstelling.beperking;
                onderdeel.type = "aanstelling";
                onderdelen.push(onderdeel);
              }

              if (key === "Code95") {
                var onderdeel = {};
                if (angular.isDefined(scope.model.code95.id)) {
                  onderdeel.id = scope.model.code95.id;
                }

                onderdeel.url = scope.model.code95.uploadedFile.url;
                onderdeel.originalFilename =
                  scope.model.code95.uploadedFile.originalFilename;
                onderdeel.filename = scope.model.code95.uploadedFile.fileName;
                onderdeel.type = "code95";

                onderdelen.push(onderdeel);
              }

              if (key === "Rijbewijs") {
                // it can happen that rijbewijs was already added in VOG form. If that is the case, we don't add it again.
                if (
                  !_.some(onderdelen, function (onderdeel) {
                    return onderdeel.type === "rijbewijs";
                  })
                ) {
                  var onderdeel = {};
                  if (angular.isDefined(scope.model.rijbewijs.id)) {
                    onderdeel.id = scope.model.rijbewijs.id;
                  }

                  onderdeel.url = scope.model.rijbewijs.uploadedFile.url;
                  onderdeel.originalFilename =
                    scope.model.rijbewijs.uploadedFile.originalFilename;
                  onderdeel.filename =
                    scope.model.rijbewijs.uploadedFile.fileName;
                  onderdeel.type = "rijbewijs";

                  onderdelen.push(onderdeel);
                }
              }
              if (key === "Pasfoto") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.pasfoto.id)) {
                  onderdeel.id = scope.model.pasfoto.id;
                }
                onderdeel.url = scope.model.pasfoto.uploadedFile.url;
                onderdeel.originalFilename =
                  scope.model.pasfoto.uploadedFile.originalFilename;
                onderdeel.filename = scope.model.pasfoto.uploadedFile.fileName;
                onderdeel.type = "pasfoto";

                onderdelen.push(onderdeel);
              }

              if (key === "Pasnummer") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.pasnummer.id)) {
                  onderdeel.id = scope.model.pasnummer.id;
                }
                onderdeel.pasnummer = scope.model.pasnummer.pasnummer;
                onderdeel.type = "pasnummer";

                onderdelen.push(onderdeel);
              }

              if (key === "Persoonsgegevens") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.persoonsgegevens.id)) {
                  onderdeel.id = scope.model.persoonsgegevens.id;
                }

                var parts = scope.model.persoonsgegevens.geboortedatum.split(
                  "-"
                );
                var geboorteDatum = new Date(parts[2], parts[1] - 1, parts[0]);

                onderdeel.voorletters =
                  scope.model.persoonsgegevens.voorletters;
                onderdeel.tussenvoegsel =
                  scope.model.persoonsgegevens.tussenvoegsel;
                onderdeel.achternaam = scope.model.persoonsgegevens.achternaam;
                onderdeel.geboortedatum = geboorteDatum.toISOString();
                onderdeel.type = "persoonsgegevens";

                onderdelen.push(onderdeel);
              }

              if (key === "Vog") {
                if (scope.model.vog && scope.model.vog.uploadedFile) {
                  var onderdeel = {};

                  if (angular.isDefined(scope.model.vog.id)) {
                    onderdeel.id = scope.model.vog.id;
                  }
                  onderdeel.url = scope.model.vog.uploadedFile.url;
                  onderdeel.originalFilename =
                    scope.model.vog.uploadedFile.originalFilename;
                  onderdeel.filename = scope.model.vog.uploadedFile.fileName;
                  onderdeel.type = "vog";

                  onderdelen.push(onderdeel);
                }

                //vog form can also contain rijbewijs in case of omzetting for tpb

                //if onderdelen already contains rijbewijs, we don't add it again
                if (
                  !_.some(onderdelen, function (onderdeel) {
                    return onderdeel.type === "rijbewijs";
                  })
                ) {
                  if (
                    scope.model.rijbewijs &&
                    scope.model.rijbewijs.uploadedFile
                  ) {
                    var onderdeel = {};
                    if (angular.isDefined(scope.model.rijbewijs.id)) {
                      onderdeel.id = scope.model.rijbewijs.id;
                    }

                    onderdeel.url = scope.model.rijbewijs.uploadedFile.url;
                    onderdeel.originalFilename =
                      scope.model.rijbewijs.uploadedFile.originalFilename;
                    onderdeel.filename =
                      scope.model.rijbewijs.uploadedFile.fileName;
                    onderdeel.type = "rijbewijs";

                    onderdelen.push(onderdeel);
                  }
                }
              }

              if (key === "Aangifte") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.procesverbaal.id)) {
                  onderdeel.id = scope.model.procesverbaal.id;
                }
                onderdeel.url = scope.model.procesverbaal.uploadedFile.url;
                onderdeel.originalFilename =
                  scope.model.procesverbaal.uploadedFile.originalFilename;
                onderdeel.filename =
                  scope.model.procesverbaal.uploadedFile.fileName;
                onderdeel.type = "procesverbaal";

                onderdelen.push(onderdeel);
              }

              if (key === "Waarmerk") {
                var onderdeel = {};
                var waarmerkWithoutSpaces = "";
                onderdeel.isLegacyWaarmerk =
                  angular.isDefined(scope.model.waarmerk.legacyWaarmerk) &&
                  scope.model.waarmerk.legacyWaarmerk !== null &&
                  scope.model.waarmerk.legacyWaarmerk.length > 0;

                if (onderdeel.isLegacyWaarmerk) {
                  waarmerkWithoutSpaces = scope.model.waarmerk.legacyWaarmerk;
                } else {
                  waarmerkWithoutSpaces = scope.model.waarmerk.waarmerk.replace(
                    /\s/g,
                    ""
                  );
                }

                if (angular.isDefined(scope.model.waarmerk.id)) {
                  onderdeel.id = scope.model.waarmerk.id;
                }

                onderdeel.waarmerk = waarmerkWithoutSpaces;
                onderdeel.type = "waarmerk";
                onderdelen.push(onderdeel);
              }
              if (key === "Werknemersverklaring") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.werknemersverklaring.id)) {
                  onderdeel.id = scope.model.werknemersverklaring.id;
                }
                onderdeel.url =
                  scope.model.werknemersverklaring.uploadedFile.url;
                onderdeel.originalFilename =
                  scope.model.werknemersverklaring.uploadedFile.originalFilename;
                onderdeel.filename =
                  scope.model.werknemersverklaring.uploadedFile.fileName;
                onderdeel.type = "werknemersverklaring";

                onderdelen.push(onderdeel);
              }

              if (key === "Werkgever") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.werkgever.id)) {
                  onderdeel.id = scope.model.werkgever.id;
                }

                onderdeel.customwerkgeversupplied =
                  scope.model.werkgever.customwerkgeversupplied;
                onderdeel.werkgevernaam = scope.model.werkgever.werkgevernaam;
                onderdeel.werkgeverplaats =
                  scope.model.werkgever.werkgeverplaats;

                onderdeel.type = "werkgever";

                onderdelen.push(onderdeel);
              }
              if (key === "Vrijstelling") {
                var onderdeel = {};

                if (angular.isDefined(scope.model.vrijstelling.id)) {
                  onderdeel.id = scope.model.vrijstelling.id;
                }

                onderdeel.addVrijstelling =
                  scope.model.vrijstelling.addVrijstelling;

                onderdeel.type = "vrijstelling";

                onderdelen.push(onderdeel);
              }
            }
            return onderdelen;
          };
        },
      };
    },
  ])

  //all view templates
  .directive("vog", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    "$rootScope",
    "swangular",
    "$translate",
    function (
      Upload,
      $timeout,
      backendBaseUrl,
      $rootScope,
      swangular,
      $translate
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/vog.tpl.html",
        link: function (scope, element, attrs) {
          scope.openConfirmVogModal = function () {
            swangular
              .open({
                title: $translate.instant("pasaanvraagWizard.vog.popup.titel"),
                htmlTemplate:
                  "app/modules/general/modals/confirm-vog/confirm-vog.view.html",
                width: "33%",
                allowOutsideClick: false,
                allowEscapeKey: false,
                scope: scope,
                customClass: "customModal",
                confirmButtonText: $translate.instant(
                  "pasaanvraagWizard.vog.popup.ga_verder"
                ),
                cancelButtonText: $translate.instant(
                  "pasaanvraagWizard.vog.popup.annuleren"
                ),
                showCancelButton: true,
              })
              .then(function (result) {
                if (result.value === true) {
                  scope.next();
                  swangular.close();
                } else {
                  swangular.close();
                }
              });
          };

          // for now, we only check if the user is RWS gebruiker. If he is, he doesn't need VOG
          var vogBypass = $rootScope.isInRole("rijkswaterstaatgebruiker");

          if (vogBypass) {
            scope.vogNeeded = false;
          } else {
            scope.vogNeeded = true;

            scope.model.vog = {};

            if (scope.editMode === true) {
              var waitForRenderAndDoSomething = function () {
                if (!scope.model.vog.type) {
                  $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
                } else {
                  scope.$watch(
                    "model.vog.uploadedFile",
                    function (newVal, oldVal) {
                      if (!!newVal && newVal !== oldVal) {
                        scope.forms.Vog.$setValidity("disapproved", true);
                      }
                    }
                  );

                  if (scope.model.vog.isGoedgekeurd === false) {
                    scope.forms.Vog.$setValidity("disapproved", false);
                    scope.forms.Vog.$setDirty();
                  } else {
                    scope.forms.Vog.vogUrl.$setValidity("required", true);

                    scope.model.vog.uploadedFile = {};
                    scope.model.vog.uploadedFile.url =
                      backendBaseUrl + scope.model.vog.url;
                    scope.model.vog.uploadedFile.fileName =
                      scope.model.vog.filename;
                    scope.model.vog.uploadedFile.originalFilename =
                      scope.model.vog.originalFilename;
                  }
                }
              };
              $timeout(waitForRenderAndDoSomething);
            }
          }
        },
      };
    },
  ])
  //vog-optioneel
  .directive("vogOptioneel", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    "$rootScope",
    function (Upload, $timeout, backendBaseUrl, $rootScope) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/vog-optioneel.tpl.html",
        link: function (scope, element, attrs) {
          // for now, we only check if the user is RWS gebruiker. If he is, he doesn't need VOG
          var vogBypass = $rootScope.isInRole("rijkswaterstaatgebruiker");
          scope.isVerlengingChanged = function (isVerleniging) {
            if (isVerleniging) {
              scope.model.vog = {};
              scope.vogNeeded = true;
              scope.screenreaderLabelId = "vogUitleg";
            } else {
              scope.model.vog = null;
              scope.vogNeeded = false;
              scope.screenreaderLabelId = "isVerlengingLabelId";
            }
          };
          if (vogBypass) {
            scope.vogNeeded = false;
          } else {
            scope.vogNeeded = false;

            scope.model.vog = {};

            if (scope.editMode === true) {
              var waitForRenderAndDoSomething = function () {
                if (!scope.model.vog.type) {
                  $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
                } else {
                  scope.vogNeeded = true;
                  scope.$watch(
                    "model.vog.uploadedFile",
                    function (newVal, oldVal) {
                      if (!!newVal && newVal !== oldVal) {
                        scope.forms.Vog.$setValidity("disapproved", true);
                      }
                    }
                  );

                  if (scope.model.vog.isGoedgekeurd === false) {
                    scope.forms.Vog.$setValidity("disapproved", false);
                    scope.forms.Vog.$setDirty();
                  } else {
                    scope.forms.Vog.vogUrl.$setValidity("required", true);

                    scope.model.vog.uploadedFile = {};
                    scope.model.vog.uploadedFile.url =
                      backendBaseUrl + scope.model.vog.url;
                    scope.model.vog.uploadedFile.fileName =
                      scope.model.vog.filename;
                    scope.model.vog.uploadedFile.originalFilename =
                      scope.model.vog.originalFilename;
                  }
                }
              };
              $timeout(waitForRenderAndDoSomething);
            }
          }
        },
      };
    },
  ])

  //vog-optioneel
  .directive("vogRijbewijsOptioneel", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    "$rootScope",
    function (Upload, $timeout, backendBaseUrl, $rootScope) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/vogrijbewijs-optioneel.tpl.html",
        link: function (scope, element, attrs) {
          // for now, we only check if the user is RWS gebruiker. If he is, he doesn't need VOG
          var vogBypass = $rootScope.isInRole("rijkswaterstaatgebruiker");
          scope.isVerlengingChanged = function (isVerlenging) {
            if (isVerlenging) {
              scope.model.vog = {};
              scope.vogNeeded = true;

              scope.model.rijbewijs = {};
              scope.rijbewijsNeeded = true;
            } else {
              scope.model.vog = null;
              scope.vogNeeded = false;

              scope.model.rijbewijs = null;
              scope.rijbewijsNeeded = false;
            }
          };
          if (vogBypass) {
            scope.vogNeeded = false;
          } else {
            scope.vogNeeded = false;

            scope.model.vog = {};

            if (scope.editMode === true) {
              var waitForRenderAndDoSomething = function () {
                if (!scope.model.vog.type) {
                  $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
                } else {
                  scope.$watch(
                    "model.vog.uploadedFile",
                    function (newVal, oldVal) {
                      if (!!newVal && newVal !== oldVal) {
                        scope.forms.Vog.$setValidity("disapproved", true);
                      }
                    }
                  );
                  scope.$watch(
                    "model.rijbewijs.uploadedFile",
                    function (newVal, oldVal) {
                      if (!!newVal && newVal !== oldVal) {
                        scope.forms.Vog.$setValidity("disapproved", true);
                      }
                    }
                  );
                  if (scope.model.vog.isGoedgekeurd === false) {
                    scope.forms.Vog.$setValidity("disapproved", false);
                    scope.forms.Vog.$setDirty();
                  } else {
                    scope.forms.Vog.vogUrl.$setValidity("required", true);

                    scope.model.vog.uploadedFile = {};
                    scope.model.vog.uploadedFile.url =
                      backendBaseUrl + scope.model.vog.url;
                    scope.model.vog.uploadedFile.fileName =
                      scope.model.vog.filename;
                    scope.model.vog.uploadedFile.originalFilename =
                      scope.model.vog.originalFilename;
                  }

                  if (
                    scope.model.rijbewijs.isGoedgekeurd === false &&
                    scope.aanvraagtype === "omzetting-transport"
                  ) {
                    scope.forms.Vog.$setValidity("disapproved", false);
                    scope.forms.Vog.$setDirty();
                  } else {
                    scope.forms.Vog.rijbewijsUrl.$setValidity("required", true);

                    scope.model.rijbewijs.uploadedFile = {};
                    scope.model.rijbewijs.uploadedFile.url =
                      backendBaseUrl + scope.model.rijbewijs.url;
                    scope.model.rijbewijs.uploadedFile.fileName =
                      scope.model.rijbewijs.filename;
                    scope.model.rijbewijs.uploadedFile.originalFilename =
                      scope.model.rijbewijs.originalFilename;
                  }
                }
              };
              $timeout(waitForRenderAndDoSomething);
            }
          }
        },
      };
    },
  ])
  .directive("werknemersverklaring", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/werknemersverklaring.tpl.html",
        link: function (scope, element, attrs) {
          scope.werknemersverklaringNeeded = true;
          scope.model.werknemersverklaring = {};

          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.werknemersverklaring.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.$watch(
                  "model.werknemersverklaring.uploadedFile",
                  function (newVal, oldVal) {
                    if (!!newVal && newVal !== oldVal) {
                      scope.forms.Werknemersverklaring.$setValidity(
                        "disapproved",
                        true
                      );
                    }
                  }
                );

                if (scope.model.werknemersverklaring.isGoedgekeurd === false) {
                  scope.forms.Werknemersverklaring.$setValidity(
                    "disapproved",
                    false
                  );
                  scope.forms.Werknemersverklaring.$setDirty();
                } else {
                  scope.forms.Werknemersverklaring.vogUrl.$setValidity(
                    "required",
                    true
                  );

                  scope.model.werknemersverklaring.uploadedFile = {};
                  scope.model.werknemersverklaring.uploadedFile.url =
                    backendBaseUrl + scope.model.werknemersverklaring.url;
                  scope.model.werknemersverklaring.uploadedFile.fileName =
                    scope.model.werknemersverklaring.filename;
                  scope.model.werknemersverklaring.uploadedFile.originalFilename =
                    scope.model.werknemersverklaring.originalFilename;
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("procesverbaal", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/procesverbaal.tpl.html",
        link: function (scope, element, attrs) {
          scope.procesVerbaalNeeded = true;
          scope.model.procesverbaal = {};
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.procesverbaal.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.$watch(
                  "model.procesverbaal.uploadedFile",
                  function (newVal, oldVal) {
                    if (!!newVal && newVal !== oldVal) {
                      scope.forms.Aangifte.$setValidity("disapproved", true);
                    }
                  }
                );

                if (!scope.model.procesverbaal.isGoedgekeurd) {
                  scope.forms.Aangifte.$setValidity("disapproved", false);
                  scope.forms.Aangifte.$setDirty();
                } else {
                  scope.forms.Aangifte.procesVerbaalUrl.$setValidity(
                    "required",
                    true
                  );

                  scope.model.procesverbaal.uploadedFile = {};
                  scope.model.procesverbaal.uploadedFile.url =
                    backendBaseUrl + scope.model.procesverbaal.url;
                  scope.model.procesverbaal.uploadedFile.fileName =
                    scope.model.procesverbaal.filename;
                  scope.model.procesverbaal.uploadedFile.originalFilename =
                    scope.model.procesverbaal.originalFilename;
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("pasfoto", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/pasfoto.tpl.html",
        link: function (scope, element, attrs) {
          scope.pasfotoNeeded = true;
          scope.model.pasfoto = {};

          if (scope.editMode === true) {
            // check if a new pasfoto has been uploaded, if so, remove the disapproved validation error
            scope.$watch(
              "model.pasfoto.uploadedFile",
              function (newVal, oldVal) {
                if (!!newVal && newVal !== oldVal) {
                  scope.forms.Pasfoto.$setValidity("disapproved", true);
                }
              }
            );

            var waitForRenderAndDoSomething = function () {
              if (!scope.model.pasfoto.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                if (!scope.model.pasfoto.isGoedgekeurd) {
                  scope.forms.Pasfoto.$setDirty();
                  scope.forms.Pasfoto.$setValidity("disapproved", false);
                } else {
                  scope.forms.Pasfoto.pasfotoUrl.$setValidity("required", true);

                  scope.model.pasfoto.uploadedFile = {};
                  scope.model.pasfoto.uploadedFile.url =
                    backendBaseUrl + scope.model.pasfoto.url;
                  scope.model.pasfoto.uploadedFile.fileName =
                    scope.model.pasfoto.filename;
                  scope.model.pasfoto.uploadedFile.originalFilename =
                    scope.model.pasfoto.originalFilename;
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("vrijstelling", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    "$rootScope",
    function (Upload, $timeout, backendBaseUrl, $rootScope) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/vrijstelling.tpl.html",
        link: function (scope, element, attrs) {
          scope.checkboxValue = false;
          scope.model.vrijstelling = {};
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.vrijstelling || !scope.model.vrijstelling.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.checkboxValue = scope.model.vrijstelling.addVrijstelling;
              }
            };
            $timeout(waitForRenderAndDoSomething);
          } else {
            scope.model.vrijstelling.addVrijstelling = false;
            scope.vrijstellingStatus = false;
          }

          // for now, we only check if the user is RWS gebruiker. If he is, he doesn't need an exemption
          var aanstellingBypass = $rootScope.isInRole(
            "rijkswaterstaatgebruiker"
          );

          if (aanstellingBypass) {
            scope.aanstellingBypass = false;
          } else {
            scope.aanstellingBypass = true;

            scope.model.vrijstelling.addVrijstelling = false;

            scope.vrijstellingTrueOrFalse = function () {
              if (scope.vrijstellingStatus) {
                scope.vrijstellingStatus = false;
                scope.model.vrijstelling.addVrijstelling = false;
              } else {
                scope.vrijstellingStatus = true;
                scope.model.vrijstelling.addVrijstelling = true;
              }
            };
          }
        },
      };
    },
  ])
  .directive("persoonsgegevens", [
    "Upload",
    "$timeout",
    "WizardHandler",
    function (Upload, $timeout, WizardHandler) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/persoonsgegevens.tpl.html",
        link: function (scope, element, attrs) {
          scope.persoonsGegevensNeeded = true;

          scope.model.persoonsgegevens = {};
          scope.birthdatemask = {
            mask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
            placeholderChar: "\u2000",
          };
          if (attrs.gegevensBekend === "true") {
            scope.gegevensBekend = true;
          }

          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.persoonsgegevens.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.forms.Persoonsgegevens.voorletters.$setDirty();
                scope.forms.Persoonsgegevens.tussenvoegsel.$setDirty();
                scope.forms.Persoonsgegevens.achternaam.$setDirty();
                scope.forms.Persoonsgegevens.geboortedatum.$setDirty();

                if (scope.model.persoonsgegevens.isGoedgekeurd === false) {
                  scope.forms.Persoonsgegevens.$setValidity(
                    "disapproved",
                    false
                  );
                  // we remove the validation error later using ng-change in the DOM
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("code95", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/code95.tpl.html",
        link: function (scope, element, attrs) {
          scope.model.code95 = {};

          scope.code95Needed = true;

          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.code95.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.$watch(
                  "model.code95.uploadedFile",
                  function (newVal, oldVal) {
                    if (!!newVal && newVal !== oldVal) {
                      scope.forms.Code95.$setValidity("disapproved", true);
                    }
                  }
                );

                if (scope.model.code95.isGoedgekeurd === false) {
                  scope.forms.Code95.$setValidity("disapproved", false);
                  scope.forms.Code95.$setDirty();
                } else {
                  scope.forms.Code95.c95Url.$setValidity("required", true);

                  scope.model.code95.uploadedFile = {};
                  scope.model.code95.uploadedFile.url =
                    backendBaseUrl + scope.model.code95.url;
                  scope.model.code95.uploadedFile.fileName =
                    scope.model.code95.filename;
                  scope.model.code95.uploadedFile.originalFilename =
                    scope.model.code95.originalFilename;
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("rijbewijs", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/rijbewijs.tpl.html",
        link: function (scope, element, attrs) {
          scope.model.rijbewijs = {};

          scope.rijbewijsNeeded = true;

          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.rijbewijs.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.$watch(
                  "model.rijbewijs.uploadedFile",
                  function (newVal, oldVal) {
                    if (!!newVal && newVal !== oldVal) {
                      scope.forms.Rijbewijs.$setValidity("disapproved", true);
                    }
                  }
                );

                if (scope.model.rijbewijs.isGoedgekeurd === false) {
                  scope.forms.Rijbewijs.$setValidity("disapproved", false);
                  scope.forms.Rijbewijs.$setDirty();
                } else {
                  scope.forms.Rijbewijs.rijbewijsUrl.$setValidity(
                    "required",
                    true
                  );

                  scope.model.rijbewijs.uploadedFile = {};
                  scope.model.rijbewijs.uploadedFile.url =
                    backendBaseUrl + scope.model.rijbewijs.url;
                  scope.model.rijbewijs.uploadedFile.fileName =
                    scope.model.rijbewijs.filename;
                  scope.model.rijbewijs.uploadedFile.originalFilename =
                    scope.model.rijbewijs.originalFilename;
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("pasnummer", [
    "Upload",
    "$timeout",
    function (Upload, $timeout) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/pasnummer.tpl.html",
        link: function (scope, element, attrs) {
          scope.model.pasnummer = {};
          scope.unSkippable = false;
          scope.pasnummerNeeded = true;
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.pasnummer.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("aanstelling", [
    "Upload",
    "$timeout",
    function (Upload, $timeout) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/aanstelling.tpl.html",
        link: function (scope, element, attrs) {
          scope.model.aanstelling = {};
          scope.unSkippable = false;
          scope.aanstellingNeeded = true;
          scope.isVerlenging = scope.model.aanstelling.isVerlenging;
          scope.verlengingEndDate = moment().add(5, "years").format("DD-MM-Y");
          if (attrs.isVerlengingWizard === "true") {
            scope.isVerlengingWizard = true;
          }

          scope.isVerlengingChanged = function (isVerlenging) {
            if (isVerlenging) {
              scope.isVerlenging = true;
            } else {
              scope.isVerlenging = false;
            }
          };

          $timeout(waitForRenderAndDoSomething);
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.aanstelling.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.forms.Aanstelling.beperking.$setDirty();
                scope.forms.Aanstelling.aanstellingsnummer.$setDirty();

                if (
                  scope.model.aanstelling.isGoedgekeurd === false &&
                  scope.model.aanstelling.isVerlenging === false
                ) {
                  scope.forms.Aanstelling.$setValidity("disapproved", false);
                  // we remove the validation error later using ng-change in the DOM
                }
              }
            };

            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])
  .directive("werkgeversverklaring", [
    "Upload",
    "$timeout",
    function (Upload, $timeout) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/werkgeversverklaring.tpl.html",
        link: function (scope, element, attrs) {
          scope.werkgeversverklaringNeeded = true;
          scope.model.werkgeversverklaring = {};
          if (scope.editMode === true) {
            scope.model.werkgeversverklaring.verklaard = true;
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.werkgeversverklaring.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])

  .directive("gemeentewerkgever", [
    "Upload",
    "$timeout",
    function (Upload, $timeout) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/gemeentewerkgever.tpl.html",
        link: function (scope, element, attrs) {
          scope.customWerkgeverNeeded = true;

          scope.model.werkgever = {};
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.werkgever.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                scope.forms.Werkgever.werkgever.$setDirty();
                scope.forms.Werkgever.plaats.$setDirty();

                if (scope.model.werkgever.isGoedgekeurd === false) {
                  scope.forms.Werkgever.$setValidity("disapproved", false);
                  // we remove the validation error later using ng-change in the DOM
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }
        },
      };
    },
  ])

  .directive("waarmerk", [
    "Upload",
    "$timeout",
    "$resource",
    "backendBaseUrl",
    "$location",
    "vcRecaptchaService",
    "notificationservice",
    "$route",
    "swangular",
    function (
      Upload,
      $timeout,
      $resource,
      backendBaseUrl,
      $location,
      vcRecaptchaService,
      notificationService,
      $route,
      swangular
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/waarmerk.tpl.html",
        link: function (scope, element, attrs) {
          scope.parameters = $location.search();
          scope.waarmerkNeeded = true;
          scope.legacyWaarmerkRequired = false;
          scope.waarmerkRequired = true;
          scope.model.waarmerk = {};
          scope.waarmerkmask = {
            mask: [
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
            ],
            placeholderChar: "\u2000",
          };
          scope.geboortejaarmask = {
            mask: [/\d/, /\d/, /\d/, /\d/],
            placeholderChar: "\u2000",
          };
          scope.legacywaarmerkmask = {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
            placeholderChar: "\u2000",
          };
          if (scope.editMode === true) {
            var waitForRenderAndDoSomething = function () {
              if (!scope.model.waarmerk.type) {
                $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
              } else {
                if (scope.model.waarmerk.isLegacyWaarmerk) {
                  if (!scope.model.waarmerk.isGoedgekeurd) {
                    scope.forms.Waarmerk.legacyWaarmerk.$setValidity(
                      "disapproved",
                      false
                    );
                  }
                  scope.legacyWaarmerkRequired = true;
                  scope.waarmerkRequired = false;
                  scope.forms.Waarmerk.legacyWaarmerk.$setDirty();
                } else {
                  scope.model.waarmerk.geboortejaar = scope.model.waarmerk.geboortejaar.slice(
                    -4
                  );
                  scope.forms.Waarmerk.waarmerk.$setValidity("invalid", true);
                  scope.forms.Waarmerk.geboortejaar.$setValidity(
                    "invalid",
                    true
                  );
                }
              }
            };
            $timeout(waitForRenderAndDoSomething);
          }

          /// Does a request to the backend with waarmerk and geboortejaar
          /// If this is a valid combination, we send the data back
          /// If not, we add one failed attempt to the user
          /// If there is a captcha on the page, we also reset it
          /// And we set the validity of the inputs to false
          var checkWaarmerkAndGeboorteJaar = function () {
            return new Promise(function (resolve, reject) {
              if (scope.userProperties.failedDeelnemerGegevensRequest >= 3) {
                var recaptchaResponse = vcRecaptchaService.getResponse(
                  scope.widgetId
                );
              }
              $resource(backendBaseUrl + "/pasaanvrag/deelnemergegevens")
                .save({
                  Waarmerk: scope.model.waarmerk.waarmerk.replace(/\s/g, ""),
                  GeboorteJaar: scope.model.waarmerk.geboortejaar,
                  TypeRegelaar: $route.current.pasType,
                  RecaptchaResponse: recaptchaResponse,
                })
                .$promise.then(
                  function success(result) {
                    resolve(result);
                  },

                  function failure(result) {
                    // Get the userproperties again to see if we need to set a captcha
                    scope.getUserProperties();
                    // Reset the captcha if it exists
                    reject(result);
                  }
                );
            });
          };
          scope.$watch(
            "model.waarmerk.isLegacyWaarmerk",
            function (newValue, oldValue) {
              if (newValue === true) {
                scope.legacyWaarmerkRequired = true;
                scope.waarmerkRequired = false;

                scope.model.waarmerk.waarmerk = null;
                scope.model.waarmerk.geboortejaar = null;

                scope.forms.Waarmerk.waarmerk.$setPristine();
                scope.forms.Waarmerk.geboortejaar.$setPristine();
              }
              if (newValue === false) {
                scope.legacyWaarmerkRequired = false;
                scope.waarmerkRequired = true;

                scope.model.waarmerk.legacyWaarmerk = null;

                scope.forms.Waarmerk.legacyWaarmerk.$setPristine();
              }
            }
          );

          scope.validateWaarmerk = function () {
            if (scope.legacyWaarmerkRequired) {
              scope.next();
              return;
            }
            // Check if the waarmerk and geboortejaar combination are valid
            checkWaarmerkAndGeboorteJaar()
              // if so
              .then(
                function success(result) {
                  // bind the result to a scope variable,
                  // so we can use it in the view
                  scope.model.persoonsgegevens.voorletters = result.voorletters;
                  scope.model.persoonsgegevens.tussenvoegsel =
                    result.tussenvoegsel;
                  scope.model.persoonsgegevens.achternaam = result.achternaam;
                  scope.model.persoonsgegevens.geboortedatum =
                    result.geboortedatum;
                  scope.next();
                },
                function failure(result) {
                  // the combination was not correct
                  // or there was an other error
                  if (typeof scope.widgetId !== "undefined") {
                    vcRecaptchaService.reload(scope.widgetId);
                  }

                  // If this is the case, there is already an aanvraag for this waarmerk.
                  // notify user, and cancel
                  if (result.status === 409) {
                    swangular.swal({
                      title: "Reeds aangevraagd",
                      text: "Voor dit waarmerk is al een pas aangevraagd",
                      type: "info",
                      allowOutsideClick: false,
                      allowEscapeKey: false,

                      preConfirm: function confirmAction(confirmed) {
                        if (confirmed == true) {
                          scope.setAllFormsPristine();
                          $route.reload();
                          scope.$apply();
                        }
                      },
                    });
                  } else if (result.status === 410) {
                    // this means the waarmerk has expired
                    swangular.swal({
                      title: "Verlopen",
                      text:
                        "Dit waarmerk is verlopen en kan niet meer worden gebruikt",
                      type: "error",
                      allowEscapeKey: false,
                      allowOutsideClick: false,

                      preConfirm: function confirmAction(confirmed) {
                        if (confirmed == true) {
                          scope.setAllFormsPristine();
                          $route.reload();
                          scope.$apply();
                        }
                      },
                    });
                  } else if (result.status === 418) {
                    swangular.swal({
                      title: "Verkeerd type",
                      text:
                        "U kunt met deze waarmerkcode alleen een transportbegeleiders pas aanvragen",
                      type: "error",
                      allowOutsideClick: false,
                      allowEscapeKey: false,

                      preConfirm: function confirmAction(confirmed) {
                        if (confirmed == true) {
                          scope.setAllFormsPristine();
                          $route.reload();
                          scope.$apply();
                        }
                      },
                    });
                  } else if (result.status === 417) {
                    swangular.swal({
                      title: "Verkeerd type",
                      text:
                        "U kunt met deze waarmerkcode alleen een beroepsverkeersregelaars pas aanvragen",
                      type: "error",
                      allowOutsideClick: false,
                      allowEscapeKey: false,

                      preConfirm: function confirmAction(confirmed) {
                        if (confirmed == true) {
                          scope.setAllFormsPristine();
                          $route.reload();
                          scope.$apply();
                        }
                      },
                    });
                  } else {
                    // Set some sort of variable to let the view know there was an error
                    scope.forms.Waarmerk.waarmerk.$setValidity(
                      "invalid",
                      false
                    );
                    scope.forms.Waarmerk.geboortejaar.$setValidity(
                      "invalid",
                      false
                    );
                  }
                }
              );
          };
        },
      };
    },
  ])
  .directive("overzicht", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    "$resource",
    "WizardHandler",
    function (Upload, $timeout, backendBaseUrl, $resource, WizardHandler) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/pasaanvraag-wizard/views/overzicht.tpl.html",
        link: function (scope, element, attrs) {
          scope.verlengingEndDate = moment().add(5, "years").format("DD-MM-Y");

          $resource(backendBaseUrl + "/blobendpointurl/vog").get(
            function success(result) {
              scope.vogUrlPrefix = backendBaseUrl + result.url;
            }
          );
          $resource(backendBaseUrl + "/blobendpointurl/bewijsvancode95").get(
            function success(result) {
              scope.code95UrlPrefix = backendBaseUrl + result.url;
            }
          );
          $resource(backendBaseUrl + "/blobendpointurl/rijbewijs").get(
            function success(result) {
              scope.rijbewijsUrlPrefix = backendBaseUrl + result.url;
            }
          );
          var initializedWizard = {};
          scope.$watch(
            function () {
              return WizardHandler.wizard("aanvraag");
            },
            function (wizard) {
              if (wizard) {
                initializedWizard = wizard;
              }
            }
          );
          scope.goToStep = function (step) {
            initializedWizard.goTo(step);
          };
        },
      };
    },
  ]);
