var module = angular.module("cookie-wall", []).directive("cookieWall", [
  "$localStorage",
  "$rootScope",
  "$location",
  "$log",
  "$translate",
  "swangular",
  function ($localStorage, $rootScope, $location, $log, $translate, swangular) {
    return {
      scope: {},
      templateUrl: "/app/modules/cookie-wall/cookie-wall.view.html",
      controller: function ($scope, $localStorage, $location, $log) {
        $scope.showCookieWall = true;

        if ($localStorage.cookieConsent) {
          $log.debug("cookie consent given, not showing cookiewall");
          $scope.showCookieWall = false;
        } else {
          $log.debug("cookie consent not given, showing cookiewall");
        }

        /*
         * triggered when the user clicks the 'I consent' button
         */
        $scope.giveCookieConsent = function () {
          $localStorage.cookieConsent = true;
          $scope.showCookieWall = false;

          // tell analytics to fire up it's engines
          $rootScope.$broadcast("cookie-consent-given");
          $log.debug("cookie consent given");
        };

        if ($scope.showCookieWall === true) {
          swangular
            .open({
              title: $translate.instant("cookiewall.titel"),
              html:
                "<p>" + $translate.instant("cookiewall.beschrijving") + "</p>",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: $translate.instant("cookiewall.toestemming"),
              type: "info",
            })
            .then(function (result) {
              if (result.value) {
                $scope.giveCookieConsent();
              }
            });
        }
      },
    };
  },
]);
