"use strict";

var module = angular
  .module("aanstellingspas.pasdetail", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/pas/:id", {
      templateUrl: "app/modules/pasdetail/pasdetail.view.html",
      controller: "PasdetailController",
      title: "titles.pasdetail",
    });
  })

  .controller("PasdetailController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$log",
    "$routeParams",
    "$window",
    function ($scope, $resource, backendBaseUrl, $log, $routeParams, $window) {
      $scope.pasId = $routeParams.id;
      $scope.pasfotoUrl = "";
      $scope.pas = {};

      function getPas() {
        $resource(backendBaseUrl + "/pas/:pasId", {
          pasId: $scope.pasId,
        })
          .get()
          .$promise.then(
            function success(result) {
              $scope.pas = result;
              $scope.pasfotoUrl = backendBaseUrl + result.pasfotoUrl;
            },
            function failure(result) {
              $log.error(result);
            }
          );
      }
      getPas();

      $scope.verlengingNeeded = function (pas) {
        if (
          moment(pas.geldigTot).isBetween(moment(), moment().add(6, "months"))
        ) {
          return ($scope.verlengingVerplicht = true);
        }
      };

      $scope.back = function () {
        $window.history.back();
      };
    },
  ]);
