"use strict";

var module = angular
  .module("aanstellingspas.passen", ["smart-table"])

  .config(function ($routeProvider) {
    $routeProvider.when("/passen", {
      templateUrl: "app/modules/passen/passen.view.html",
      controller: "PassenController",
      title: "titles.passen",
      navigation_id: "passen",
    });
  })

  .controller("PassenController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$log",
    "$location",
    "bedrijfsService",
    "$localStorage",
    "$rootScope",
    "$translate",
    function (
      $scope,
      $resource,
      backendBaseUrl,
      $log,
      $location,
      bedrijfService,
      $localStorage,
      $rootScope,
      $translate
    ) {
      //reserveringen from the get request
      $scope.passen = [];

      //the displayed and filtered reserveringen
      $scope.displayedPassen = [];

      //gets all reserveringen for bedrijf
      $resource(backendBaseUrl + "/rijkswaterstaat/pas")
        .query()
        .$promise.then(
          function success(result) {
            $scope.passen = result;
          },
          function failure(result) {
            $log.error(result);
          }
        );
    },
  ]);
