"use strict";

var module = angular
  .module("aanstellingspas.bestellingen", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/bestellingen/", {
      templateUrl: "app/modules/bestellingen/bestellingen.view.html",
      controller: "BestellingenController",
      title: "titles.mijn_bestellingen",
      navigation_id: "bestellingen",
    });
  })

  .controller("BestellingenController", [
    "$scope",
    "$log",
    "$resource",
    "backendBaseUrl",
    "$window",
    "$location",
    "notificationservice",
    "downloadfileService",
    "$translate",
    function (
      $scope,
      $log,
      $resource,
      backendBaseUrl,
      $window,
      $location,
      notificationService,
      downloadfileService,
      $translate
    ) {
      // config
      $scope.itemsPerPage = 10;

      $scope.bestellingenresult = [];

      $scope.displayedbestellingenresult = [];
      $scope.totalItems = 0;

      //calling this with 0 means we want all the results
      getBestellingen(0);

      $translate("bestellingen.status.alles").then(function () {
        $scope.status = [
          {
            id: "ALLES",
            text: $translate.instant("bestellingen.status.alles"),
          },
          {
            id: "Open",
            text: $translate.instant("bestellingen.status.open"),
          },
          {
            id: "Verwerkt",
            text: $translate.instant("bestellingen.status.verwerkt"),
          },
        ];
      });

      function getBestellingen(pageNumber) {
        $resource(backendBaseUrl + "/bestellingen")
          .get({
            pageNumber: pageNumber,
            pageSize: $scope.itemsPerPage,
          })
          .$promise.then(function success(result) {
            $scope.bestellingenresult = result.bestellingen;
            $scope.totalItems = result.totalItems;
          });
      }

      $scope.afronden = function (id) {
        $resource(backendBaseUrl + "/openbetaling/")
          .save({
            id: id,
          })
          .$promise.then(
            function succes(result) {
              $window.location.href = result.returnUrl;
            },
            function failure(result) {
              if (result.status === 403) {
                notificationService.info(
                  $translate.instant("bestellingen.status_gewijzigd")
                );
                $location.path("/bestellingen");
                $scope.$apply();
              }
            }
          );
      };

      $scope.downloadFactuur = function (bestelling) {
        downloadfileService.downloadFactuur(bestelling.factuurId);
      };

      $scope.getBestellingStatusTranslation = function (status) {
        return $translate.instant(
          "bestellingen.status." + status.toLowerCase()
        );
      };

      $scope.findBestellingByFactuur = function (factuurNummer) {
        $resource(backendBaseUrl + "/bestellingbyfactuur/" + factuurNummer)
          .get()
          .$promise.then(
            function success(result) {
              $scope.bestellingenresult = result.bestellingen;
            },
            function failure(result) {
              $log.error(result);
            }
          );
      };

      // Do some formatting on select2 options
      $scope.s2_status_options = {
        language: "nl",
        placeholder: $translate.instant("bestellingen.filter_status_title"),
        formatSelection: function (status) {
          return (
            $translate.instant("bestellingen.filter_status") +
            " " +
            "<strong>" +
            status.text.toLowerCase() +
            "</strong>"
          );
        },
        allowClear: false,
        //don't display the searchbar
        minimumResultsForSearch: -1,
      };

      $scope.orderBy = function (column) {
        // if the currently sorted column was clicked again
        if ($scope.currentOrder === column) {
          if ($scope.currentOrderDirection === "desc") {
            $scope.currentOrderDirection = "asc";
          } else {
            $scope.currentOrderDirection = "desc";
          }
        } else {
          $scope.currentOrder = column;
          $scope.currentOrderDirection = "asc";
        }

        if (column == "totaalBedrag") {
          $scope.bestellingenresult = _.sortByOrder(
            $scope.bestellingenresult,
            function (o) {
              return parseFloat(o.totaalBedrag);
            },
            $scope.currentOrderDirection
          );
        } else {
          $scope.bestellingenresult = _.sortByOrder(
            $scope.bestellingenresult,
            column,
            $scope.currentOrderDirection
          );
        }
      };
    },
  ]);
