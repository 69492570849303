var module = angular.module("aanstellingspas.emailverificationupdate", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/emailverificationupdate", {
    templateUrl:
      "app/modules/emailverificationupdate/emailverificationupdate.view.html",
    controller: "EmailVerificationUpdateController",
    title: "titles.emailverification",
  });
  $routeProvider.when("/resendemailverification", {
    templateUrl:
      "app/modules/emailverification/resendemailverification.view.html",
    controller: "ResendEmailVerificationController",
    title: "titles.resendemailverificationupdate",
    hideTitle: true,
  });
});

module.controller("EmailVerificationUpdateController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$routeParams",
  "authService",
  "bedrijfsService",
  "$translate",
  "$rootScope",
  "$localStorage",
  "swangular",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $routeParams,
    authService,
    bedrijfsService,
    $translate,
    $rootScope,
    $localStorage,
    swangular
  ) {
    // gets userId and mailactivationcode from URL parameters
    $scope.userId = $routeParams.userId;
    $scope.code = $routeParams.code;

    /*
     *  Verifies user's mailactivationcode in backend and redirects user
     *  to dashboard on success and to home on failure (i.e wrong activation code).
     */
    return $resource(backendBaseUrl + "/emailverificationupdate")
      .save({
        userId: $scope.userId,
        code: $scope.code,
      })
      .$promise.then(
        function success(result) {
          authService.logout();

          $rootScope.userproperties = null;
          delete $localStorage.userproperties;
          swangular
            .swal({
              title: $translate.instant("successSwal.title"),
              text: $translate.instant("successSwal.text"),
              type: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,

              confirmButtonText: $translate.instant(
                "successSwal.buttons.confirm"
              ),
            })
            .then(
              function confirmAction(confirmed) {
                if (confirmed == true) {
                  $location.path("/login");
                  $location.search("userId", null);
                  $location.search("code", null);
                  $scope.$apply();
                }
              },
              function cancelAction() {}
            );
        },
        function failure(result) {
          swangular.swal({
            title: $translate.instant("failureSwal.title"),
            text: $translate.instant("failureSwal.text"),
            type: "error",
            allowOutsideClick: false,
            allowEscapeKey: false,

            preConfirm: function () {
              $location.path("/home");
              $location.search("userId", null);
              $location.search("code", null);
              $scope.$apply();
            },
          });
        }
      );
  },
]);
