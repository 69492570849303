"use strict";

var module = angular
  .module("aanstellingspas.winkelmandbekijken", ["ngMessages"])

  .config(function ($routeProvider) {
    $routeProvider.when("/winkelmand/", {
      templateUrl:
        "app/modules/winkelmandbekijken/winkelmandbekijken.view.html",
      controller: "WinkelmandBekijkController",
      title: "titles.winkelmand",
      requireAuth: true,
    });
  })

  .controller("WinkelmandHeaderController", [
    "backendBaseUrl",
    "$scope",
    "$resource",
    "$rootScope",
    "$log",
    "$timeout",
    "$location",
    "$translate",
    "organisatieService",
    "vcRecaptchaService",
    "swangular",
    function (
      backendBaseUrl,
      $scope,
      $resource,
      $rootScope,
      $log,
      $timeout,
      $location,
      $translate,
      organisatieService,
      vcRecaptchaService,
      swangular
    ) {
      $scope.paymentStarted = false;
      // event binding
      $rootScope.$on("authorizationchanged", function () {
        updateWinkelmand();
        checkVestigingsplaatsConfirmationNeeded();
        $log.debug("authorization changed");
      });

      $rootScope.$on("winkelmandchanged", function () {
        updateWinkelmand();

        $log.debug("winkelmand changed");
      });
      function checkVestigingsplaatsConfirmationNeeded() {
        if ($rootScope.loggedin) {
          organisatieService
            .getOrganisatieResourceForThisUser()
            .then(function success(organisatie) {
              organisatieService
                .vestigingsplaatsNeedsConfirmation(organisatie.id)
                .then(function success(confirmationNeeded) {
                  if (confirmationNeeded.vestigingsplaatsConfirmationNeeded) {
                    swangular
                      .swal({
                        title: $translate.instant(
                          "winkelmand_bekijken.notifications.confirm_vestigingsplaats.title"
                        ),
                        text:
                          $translate.instant(
                            "winkelmand_bekijken.notifications.confirm_vestigingsplaats.text"
                          ) +
                          " " +
                          confirmationNeeded.vestigingsplaats +
                          ".",
                        type: "info",
                        confirmButtonText: $translate.instant(
                          "winkelmand_bekijken.notifications.confirm_vestigingsplaats.confirm"
                        ),
                        cancelButtonText: $translate.instant(
                          "winkelmand_bekijken.notifications.confirm_vestigingsplaats.cancel"
                        ),
                        showCancelButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      })
                      .then(function confirmAction(confirmed) {
                        if (confirmed.value === true) {
                          organisatieService
                            .confirmVestigingsplaats(organisatie.id)
                            .then(function success() {
                              console.log(
                                "successfully confirmed vestigingsplaats"
                              );
                            });
                        } else {
                          $location.path("/profile");
                          $scope.$apply();
                        }
                      });
                  }
                });
            });
        }
      }

      // functions
      function updateWinkelmand() {
        if ($rootScope.loggedin) {
          $log.debug("logged in, so updating winkelmand");

          $resource(backendBaseUrl + "/winkelmand")
            .get()
            .$promise.then(function succes(result) {
              var itemCountBefore = 0;

              if (
                typeof $scope.winkelmand !== "undefined" &&
                typeof $scope.winkelmand.items !== "undefined"
              ) {
                itemCountBefore = $scope.winkelmand.items.length;

                if (itemCountBefore != result.items.length) animateWinkelmand();
              }

              $scope.winkelmand = {};
              $scope.winkelmand.items = result.items;

              $scope.winkelmand.totaalBedrag = result.totaal;

              $rootScope.showIdleNotification = result.showIdleNotification;
              $rootScope.showIdleNotificationAmountOfProducts =
                result.items.length;

              if ($rootScope.hasAansluiting()) {
                $scope.winkelmand.totaalBedrag = 0;

                _.each($scope.winkelmand.items, function (item) {
                  item.prijs = 0;
                });
              }
            });
        } else {
          $log.debug("not logged in, so not updating winkelmand");
        }
      }

      function animateWinkelmand() {
        $scope.winkelmandChangeInProgress = true;

        $timeout(function () {
          $scope.winkelmandChangeInProgress = false;
        }, 3000);
      }

      // init
      updateWinkelmand();
      checkVestigingsplaatsConfirmationNeeded();

      // functions for the profile menu
      $rootScope.profileMenuOpened = false;

      $rootScope.toggleMenu = function (event) {
        $rootScope.profileMenuOpened = !$rootScope.profileMenuOpened;

        // Important part in the implementation
        // Stopping event propagation means window.onclick won't get called when someone clicks
        // on the menu div. Without this, menu will be hidden immediately
        event.stopPropagation();
      };

      window.onclick = function () {
        if ($rootScope.profileMenuOpened) {
          $rootScope.profileMenuOpened = false;

          // You should let angular know about the update that you have made, so that it can refresh the UI
          $rootScope.$apply();
        }
      };

      $rootScope.goToProfile = function (event) {
        event.stopPropagation();
        $rootScope.profileMenuOpened = false;
        $location.path("/profile");
      };

      $rootScope.goToLogout = function (event) {
        $location.path("/logout/");
      };
    },
  ])

  .controller("WinkelmandBekijkController", [
    "backendBaseUrl",
    "$scope",
    "$location",
    "$resource",
    "$log",
    "$rootScope",
    "$window",
    "$translate",
    "$route",
    "vcRecaptchaService",
    "swangular",
    function (
      backendBaseUrl,
      $scope,
      $location,
      $resource,
      $log,
      $rootScope,
      $window,
      $translate,
      $route,
      vcRecaptchaService,
      swangular
    ) {
      $scope.submitted = false;

      var updateWinkelmand = function () {
        // broadcast event to (including but not limited to) the header winkelmand view
        $rootScope.$broadcast("winkelmandchanged");

        $resource(backendBaseUrl + "/winkelmand")
          .get()
          .$promise.then(function succes(result) {
            $scope.items = result.items;
            _.each($scope.items, function (item) {
              item.originalType = item.type;
            });
            $scope.tegoedcodes = result.tegoedcodes;
            $scope.bestellingId = result.bestellingId;
            $scope.subTotaal = result.subTotaal;
            $scope.totaal = result.totaal;
            $scope.btwBedrag = result.btwBedrag;
            $scope.hasDigitaleFactuurId = result.hasDigitaleFactuurId;
            $scope.amountBtw = "(21%)";

            if (result.btwBedrag === "\u20AC 0,00") {
              $scope.amountBtw = "(0%)";
            }

            $scope.betaalmethoden = [];
            var idealOptie = {
              id: "0",
              text: "iDeal",
            };

            $scope.betaalmethoden.push(idealOptie);

            if (
              result.bevatExamens === false &&
              result.bevatDeelnemerToevoegingen === false
            ) {
              var bankOverschrijvingOptie = {
                id: "1",
                text: "Bankoverschrijving",
              };
              $scope.betaalmethoden.push(bankOverschrijvingOptie);
            }

            if (
              $rootScope.hasAansluiting() ||
              $rootScope.isInRole("rijkswaterstaatgebruiker")
            ) {
              $scope.totaal = "\u20AC 0,-";

              $scope.subTotaal = "\u20AC 0,-";

              $scope.btwBedrag = "\u20AC 0,-";

              _.each($scope.items, function (item) {
                item.prijs = "\u20AC 0,-";
              });
            }
            translateItems();
          });
      };

      $scope.deleteItemModal = function (itemId, itemDescription) {
        swangular
          .swal({
            title: $translate.instant("winkelmand_bekijken.item_verwijderen"),
            html:
              $translate.instant("winkelmand_bekijken.weet_u_zeker") +
              "<i> " +
              itemDescription +
              "</i>",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: $translate.instant(
              "winkelmand_bekijken.ja_verwijderen"
            ),
            cancelButtonText: $translate.instant(
              "winkelmand_bekijken.nee_terug"
            ),
            focusConfirm: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: true,
          })
          .then(function confirmAction(confirmed) {
            if (confirmed.value === true) {
              $scope.deleteItem(itemId);
            }
          });
      };

      $scope.deleteItem = function (id) {
        $resource(backendBaseUrl + "/winkelmand/item/:id")
          .delete({
            id: id,
          })
          .$promise.then(function succes(result) {
            $log.debug("deleted", id);

            updateWinkelmand();
          });
      };

      $scope.addTegoedcode = function (tegoedcode, gRecaptchaResponse) {
        $scope.submitted = true;
        $scope.model = {};
        $scope.model.code = tegoedcode;
        $scope.model.grecaptcharesponse = gRecaptchaResponse;
        $scope.recaptcha = true;
        $resource(backendBaseUrl + "/winkelmand/tegoedcode", null, {
          post: {
            method: "POST",
          },
        })
          .post($scope.model)
          .$promise.then(
            function success() {
              updateWinkelmand();
              vcRecaptchaService.reload();
              $scope.submitted = false;
              $scope.tegoedcodeForm.$setPristine();
              document.getElementById("tegoedcodeForm").reset();
            },
            function failure(result) {
              if (result.status === 400) {
                swangular
                  .swal({
                    title: $translate.instant(
                      "winkelmand_bekijken.error.titel"
                    ),
                    text: $translate.instant(
                      "winkelmand_bekijken.error.gebruikt"
                    ),
                    type: "warning",
                    allowOutsideClick: false,
                  })
                  .then(function () {
                    vcRecaptchaService.reload();
                    $scope.submitted = false;
                  });
              }
              if (result.status === 403) {
                vcRecaptchaService.reload();
              }
              if (result.status === 404) {
                swangular
                  .swal({
                    title: $translate.instant(
                      "winkelmand_bekijken.error.titel"
                    ),
                    text: $translate.instant(
                      "winkelmand_bekijken.error.niet_gevonden"
                    ),
                    type: "warning",
                    allowOutsideClick: false,
                  })
                  .then(function () {
                    vcRecaptchaService.reload();
                    $scope.submitted = false;
                  });
              }
            }
          );
      };

      $scope.deleteTegoedbonModal = function (itemId, itemDescription) {
        swangular
          .swal({
            title: $translate.instant(
              "winkelmand_bekijken.tegoedbon_verwijderen"
            ),
            text:
              $translate.instant(
                "winkelmand_bekijken.weet_u_zeker_tegoedbon1"
              ) +
              itemDescription +
              $translate.instant("winkelmand_bekijken.weet_u_zeker_tegoedbon2"),
            type: "warning",
            confirmButtonText: $translate.instant(
              "winkelmand_bekijken.ja_verwijderen"
            ),
            cancelButtonText: $translate.instant(
              "winkelmand_bekijken.nee_terug"
            ),
            showCancelButton: true,
          })
          .then(function confirmAction(confirmed) {
            if (confirmed.value === true) {
              $scope.deleteTegoedcode(itemId);
            }
          });
      };

      $scope.deleteTegoedcode = function (id) {
        $resource(backendBaseUrl + "/winkelmand/tegoedcode/:id")
          .delete({
            id: id,
          })
          .$promise.then(function succes(result) {
            $log.debug("deleted", id);

            updateWinkelmand();
          });
      };

      var translateItems = function () {
        _.each($scope.items, function (item) {
          item.type = item.originalType;

          $translate("winkelmand_bekijken.duplicaat").then(function (
            duplicaat
          ) {
            item.type = item.type.replace("Duplicaat", duplicaat);
          });

          $translate("winkelmand_bekijken.extra_deelnemer").then(function (
            extra_deelnemer
          ) {
            item.type = item.type.replace(
              "extra deelnemer voor examen op",
              extra_deelnemer
            );
          });

          $translate("winkelmand_bekijken.extra_deelnemers").then(function (
            extra_deelnemers
          ) {
            item.type = item.type.replace(
              "extra deelnemers voor examen op",
              extra_deelnemers
            );
          });

          $translate("winkelmand_bekijken.voor").then(function (voor) {
            item.type = item.type.replace("voor", voor);
          });
          $translate("winkelmand_bekijken.pasaanvraag").then(function (
            pasaanvraag
          ) {
            item.type = item.type.replace("Pasaanvraag", pasaanvraag);
          });

          $translate("winkelmand_bekijken.verlenging").then(function (
            verlenging
          ) {
            item.type = item.type.replace("Verlenging", verlenging);
          });
          $translate("winkelmand_bekijken.beroepsverkeersregelaar").then(
            function (beroepsverkeersregelaar) {
              item.type = item.type.replace(
                "beroepsverkeersregelaar",
                beroepsverkeersregelaar
              );
            }
          );

          $translate("winkelmand_bekijken.transportbegeleider").then(function (
            transportbegeleider
          ) {
            item.type = item.type.replace(
              "transportbegeleider",
              transportbegeleider
            );
          });

          $translate("winkelmand_bekijken.waarmerk").then(function (waarmerk) {
            item.type = item.type.replace("Waarmerk", waarmerk);
          });

          $translate("winkelmand_bekijken.omzetting_bijplaatsing").then(
            function (omzetting_bijplaatsing) {
              item.type = item.type.replace(
                "Omzetting/bijplaatsing",
                omzetting_bijplaatsing
              );
            }
          );

          $translate("winkelmand_bekijken.vrijstelling").then(function (
            vrijstelling
          ) {
            item.type = item.type.replace("Vrijstelling", vrijstelling);
          });

          $translate("winkelmand_bekijken.examenblok").then(function (
            examenblok
          ) {
            item.type = item.type.replace("Examenblok op", examenblok);
          });

          $translate(
            "winkelmand_bekijken.examenblok_transportbegeleiders"
          ).then(function (examenblok_transportbegeleiders) {
            item.type = item.type.replace(
              "Examenblok transportbegeleiders op",
              examenblok_transportbegeleiders
            );
          });
        });

        // find bankoverschrijving option
        var select2BankOverschrijving = _.find($scope.betaalmethoden, {
          id: "1",
        });
        // if it exists, translate it
        if (select2BankOverschrijving) {
          $translate("winkelmand_bekijken.bankoverschrijving").then(function (
            bankoverschrijving
          ) {
            select2BankOverschrijving.text = bankoverschrijving;
          });
        }
      };

      $rootScope.$on("$translateChangeEnd", function (e) {
        translateItems();
      });

      var startiDealPayment = function () {
        $resource(backendBaseUrl + "/bestellingbetaling/")
          .save({
            id: $scope.bestellingId,
            referentienummer: $scope.referentienummer,
          })
          .$promise.then(
            function succes(result) {
              updateWinkelmand();
              $window.location.href = result.returnUrl;
            },
            function error(reason) {
              $scope.paymentStarted = false;
              console.error(reason);
            }
          );
      };

      var startBankTransferPayment = function () {
        $resource(backendBaseUrl + "/bestellingbetaling/banktransfer/")
          .save({
            id: $scope.bestellingId,
            referentienummer: $scope.referentienummer,
          })
          .$promise.then(
            function succes(result) {
              updateWinkelmand();
              $window.location.href = result.returnUrl;
            },
            function error(reason) {
              $scope.paymentStarted = false;
              console.error(reason);
            }
          );
      };

      $scope.initiatePayment = function () {
        $scope.paymentStarted = true;
        if (
          $scope.gekozenBetaalMethode == 0 ||
          $rootScope.hasAansluiting() ||
          $rootScope.isInRole("rijkswaterstaatgebruiker")
        ) {
          startiDealPayment();
        }

        if ($scope.gekozenBetaalMethode == 1) {
          startBankTransferPayment();
        }
      };

      //init
      updateWinkelmand();

      $scope.back = function () {
        $location.path("/dashboard");
      };
    },
  ]);
