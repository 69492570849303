"use strict";

var module = angular
  .module("aanstellingspas.service.knockknock", [])
  .factory("knockKnockService", [
    "$rootScope",
    "$location",
    "$log",
    function ($rootScope, $location, $log) {
      var service = function () {
        if (!$rootScope.loggedin) {
          var redirectUrl = $location.path();
          $log.debug("user not logged in, redirecting to" + redirectUrl);
          // removes tried (authorized) page from history to prevent browser history looping
          $location.replace();
          $location.path("/login").search({ redirectTo: redirectUrl });
        } else {
          $log.debug("user already logged in.");
        }
      };
      return service;
    },
  ]);
