"use strict";

var module = angular
  .module("aanstellingspas.registratie-keuze", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/registratie-keuze", {
      templateUrl: "app/modules/registratie-keuze/registratie-keuze.view.html",
      controller: "RegistratieKeuzeController",
      title: "titles.registratie_keuze",
      navigation_id: "registratie-keuze",
    });
  })

  .controller("RegistratieKeuzeController", [
    "backendBaseUrl",
    "$scope",
    "$location",
    "$log",
    "$translate",
    function (backendBaseUrl, $scope, $location, $log, $translate) {
      $scope.registrerenBedrijf = function () {
        $location.path("/bedrijfsregistratie");
      };

      $scope.registrerenGemeente = function () {
        $location.path("/gemeenteregistratie");
      };

      $scope.registrerenProvincie = function () {
        $location.path("/provincieregistratie");
      };

      $scope.registrerenRijkswaterstaat = function () {
        $location.path("/rijkswaterstaatregistratie");
      };
    },
  ]);
