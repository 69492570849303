"use strict";

var module = angular
  .module("aanstellingspas.toegankelijkheid", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/toegankelijkheid", {
      templateUrl: "app/modules/toegankelijkheid/toegankelijkheid.view.html",
      title: "titles.toegankelijkheid",
      hideTitle: false,
    });
  });
