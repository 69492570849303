"use strict";

var module = angular.module("aanstellingspas.redirect-pasaanvraag-wizard", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/redirect-pasaanvraag-wizard/beroeps", {
    templateUrl: "app/modules/general/redirect/redirect.view.html",
    controller: "RedirectPasaanvraagWizardController",
    hideTitle: true,
    requireAuth: true,
    pastype: "beroeps",
  });
});

module.config(function ($routeProvider) {
  $routeProvider.when("/redirect-pasaanvraag-wizard/transport", {
    templateUrl: "app/modules/general/redirect/redirect.view.html",
    controller: "RedirectTransportPasaanvraagWizardController",
    hideTitle: true,
    requireAuth: true,
    pastype: "transport",
  });
});

module.controller("RedirectPasaanvraagWizardController", [
  "$scope",
  "$rootScope",
  "$routeParams",
  "$location",
  function ($scope, $rootScope, $routeParams, $location) {
    // get parameters
    $scope.paramWaarmerk = $routeParams.waarmerk;
    $scope.paramGeboortejaar = $routeParams.geboortejaar;

    // get userroles and redirect to correct wizard
    var userRoles = $rootScope.userproperties.roles;
    for (var i = 0; i <= userRoles.length; i++) {
      if (userRoles[i] == "gemeente") {
        $location.url(
          "/pasaanvraag-eerste-gemeente/beroeps?waarmerk=" +
            $scope.paramWaarmerk +
            "&geboortejaar=" +
            $scope.paramGeboortejaar
        );
      } else if (userRoles[i] == "bedrijf") {
        $location.url(
          "/pasaanvraag-eerste/beroeps?waarmerk=" +
            $scope.paramWaarmerk +
            "&geboortejaar=" +
            $scope.paramGeboortejaar
        );
      }
    }
  },
]);

module.controller("RedirectTransportPasaanvraagWizardController", [
  "$scope",
  "$rootScope",
  "$routeParams",
  "$location",
  function ($scope, $rootScope, $routeParams, $location) {
    // get parameters
    $scope.paramWaarmerk = $routeParams.waarmerk;
    $scope.paramGeboortejaar = $routeParams.geboortejaar;

    // get userroles and redirect to correct wizard which, for now, is only bedrijf
    var userRoles = $rootScope.userproperties.roles;
    for (var i = 0; i <= userRoles.length; i++) {
      if (userRoles[i] == "bedrijf") {
        $location.url(
          "/pasaanvraag-eerste/transport?waarmerk=" +
            $scope.paramWaarmerk +
            "&geboortejaar=" +
            $scope.paramGeboortejaar
        );
      }
    }
  },
]);
