var module = angular.module("aanstellingspas.rijkswaterstaatregistratie", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/rijkswaterstaatregistratie", {
    templateUrl:
      "app/modules/rijkswaterstaatregistratie/rijkswaterstaatregistratie.view.html",
    controller: "RijkswaterstaatregistratieController",
    title: "titles.rwsregistratie",
    hideTitle: true,
  });
});

module.controller("RijkswaterstaatregistratieController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$anchorScroll",
  "$timeout",
  "authService",
  "$rootScope",
  "organisatieService",
  "notificationservice",
  "vcRecaptchaService",
  "$translate",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $anchorScroll,
    $timeout,
    authService,
    $rootScope,
    organisatieService,
    notificationService,
    vcRecaptchaService,
    $translate
  ) {
    $scope.backHandler = function () {
      $location.path("/registratie-keuze");
    };

    $scope.$on("validPasswordEvent", function (event, data) {
      $scope.validPassword = data;
    });

    /*
     * checks via the backend if a username is available
     */
    $scope.checkemailaddressAvailable = function () {
      var emailaddress = $scope.registerModel.emailaddress;
      if (!_.isEmpty(emailaddress)) {
        authService.emailaddressAvailable(emailaddress).$promise.then(
          function (result) {
            // set the validity of the 'unique' validator on the username form fields
            // 'unique' is a custom validation that gets 'spawned' here
            $rootScope.registerForm.emailaddress.$setValidity(
              "unique",
              result.available
            );
          },
          function failure() {}
        );
      }
    };

    /*
     * Does the actual registration api call after all fields are valid
     */
    $scope.register = function () {
      organisatieService
        .createRijkswaterstaatGebruiker(
          $scope.registerModel.emailaddress,
          $scope.registerModel.contactpersoonvoornaam +
            " " +
            $scope.registerModel.contactpersoonachternaam,
          $scope.registerModel.afdeling,
          $scope.registerModel.telefoonnummer,
          $scope.registerModel.password,
          $scope.registerModel.consentGiven
        )

        .$promise.then(
          function (result) {
            $scope.doneRegistering = true;
          },
          function (result) {
            $log.error("Could not create user/bedrijf, error: ", result);
            notificationService.error(
              $translate.instant("rijkswaterstaatregistratie.notificatie")
            );
            vcRecaptchaService.reload();
          }
        );
    };
  },
]);
