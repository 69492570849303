"use strict";

var module = angular
  .module("aanstellingspas.wachtwoordvergeten", ["vcRecaptcha"])

  .config(function ($routeProvider) {
    $routeProvider
      .when("/wachtwoord-vergeten", {
        templateUrl:
          "app/modules/wachtwoordvergeten/wachtwoordvergeten.view.html",
        controller: "WachtwoordVergetenController",
        title: "titles.wachtwoord-vergeten",
        hideTitle: true,
      })
      .when("/wachtwoord-vergeten/reset-wachtwoord", {
        templateUrl: "app/modules/wachtwoordvergeten/resetwachtwoord.view.html",
        controller: "WachtwoordResetController",
        title: "titles.reset-wachtwoord",
        hideTitle: true,
      });
  });

/**
 * This is the first view you'll see. It's where you input your email address
 */

module.controller("WachtwoordVergetenController", [
  "$scope",
  "$location",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$route",
  "$routeParams",
  "$rootScope",
  "$translate",

  function (
    $scope,
    $location,
    $resource,
    backendBaseUrl,
    $log,
    $route,
    $routeParams,
    $rootScope,
    $translate
  ) {
    $translate("wachtwoordvergeten.title").then(function (title) {
      translateItems();
    });

    $rootScope.$on("$translateChangeEnd", function (e) {
      translateItems();
    });

    var translateItems = function () {
      $scope.title = $translate.instant("wachtwoordvergeten.title");
    };

    /*
     * submits form with 'e-mailadress' of user that requests the password change
     * and 'recaptcha response token'
     */
    $scope.sendPasswordResetRequest = function () {
      $resource(backendBaseUrl + "/passwordresetrequest")
        .save({
          email: $scope.emailAddress,
          recaptchaResponse: $scope.gRecaptchaResponse,
        })
        .$promise.then(
          function success() {
            $scope.emailSent = true;
            $log.debug(
              "password reset from " +
                $scope.emailAddress +
                "sent to backend, mail should have been sent."
            );
          },
          function failure(result) {
            $log.error(result);
          }
        );
    };

    /*
     * resets the viewstate so that the user can now request the password
     * change mail again
     */
    $scope.resetViewState = function () {
      $location.search("emailAddress", $scope.emailAddress);
      $route.reload();
      $log.debug("view state reset by user");
    };

    /*
     * Init
     */

    // always redirects user to dashboard if he's logged in
    if ($rootScope.loggedin) {
      $location.path("/dashboard");
    }

    $scope.emailAddress = $routeParams.emailAddress;

    // set $scope.emailSent to true when successful to show 'e-mail is verstuurd' melding
  },
]);

/**
 * In this view, you'll enter your new password.
 */

module.controller("WachtwoordResetController", [
  "$scope",
  "$location",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$routeParams",
  "$rootScope",
  "$translate",
  function (
    $scope,
    $location,
    $resource,
    backendBaseUrl,
    $log,
    $routeParams,
    $rootScope,
    $translate
  ) {
    $translate("wachtwoordvergeten.resetwachtwoord_title").then(function (
      title
    ) {
      translateItems();
    });

    $rootScope.$on("$translateChangeEnd", function (e) {
      translateItems();
    });

    var translateItems = function () {
      $scope.title = $translate.instant(
        "wachtwoordvergeten.resetwachtwoord_title"
      );
    };

    $scope.registerModel = {};

    $scope.$on("validPasswordEvent", function (event, data) {
      $scope.validPassword = data;
    });

    /*
     * Submits new password with verification token to backend
     */
    $scope.sendPasswordResetRequest = function () {
      if ($scope.validPassword === true) {
        $resource(backendBaseUrl + "/passwordreset")
          .save({
            userId: userId,
            code: code,
            newPassword: $scope.registerModel.password,
          })
          .$promise.then(
            function success() {
              $scope.passwordReset = true;
              $log.debug("new password sent to backend for userId: " + userId);
            },
            function failure(result) {
              if (result.status === 410) {
                swal({
                  title: $translate.instant(
                    "wachtwoordvergeten.resetwachtwoord_tijd_verstreken"
                  ),
                  text: $translate.instant(
                    "wachtwoordvergeten.resetwachtwoord_tijd_verstreken_uitleg"
                  ),
                  icon: "warning",

                  buttons: {
                    confirm: {
                      text: $translate.instant(
                        "wachtwoordvergeten.resetwachtwoord_tijd_verstreken_nieuwe_link"
                      ),
                      value: true,
                      visible: true,
                      className: "",
                      closeModal: true,
                    },
                  },

                  closeOnClickOutside: false,
                  closeOnEsc: false,
                }).then(function () {
                  $location.path("/wachtwoord-vergeten");
                  $scope.$apply();
                });
              }
              $log.error(result);
            }
          );
      }
    };

    /*
     * Init
     */

    $scope.emailAddress = $routeParams.emailAddress;

    // always redirects user to dashboard if he's logged in
    if ($rootScope.loggedin) {
      $location.path("/dashboard");
    }

    var userId = $routeParams.userId;
    var code = $routeParams.code;

    // redirect user to home if url parameters 'user' and 'token' are not present
    if (!userId || !code) {
      $location.path("/home");
    }
  },
]);
