"use strict";

var module = angular
  .module("aanstellingspas.vrijstellingsaanvraag", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/vrijstellingsaanvraag", {
      templateUrl:
        "app/modules/vrijstellingsaanvraag/vrijstellingsaanvraag.view.html",
      controller: "VrijstellingsaanvraagController",
      title: "titles.vrijstelling_aanvraag",
      requireAuth: true,
      navigation_id: "pasaanvraag",
    });
  })

  .controller("VrijstellingsaanvraagController", [
    "backendBaseUrl",
    "$scope",
    "$location",
    "$log",
    "$routeParams",
    "$localStorage",
    "organisatieService",
    "BedrijfBvoService",
    "authService",
    "$rootScope",
    "$resource",
    "vcRecaptchaService",
    "$route",
    "$translate",
    "swangular",
    function (
      backendBaseUrl,
      $scope,
      $location,
      $log,
      $routeParams,
      $localStorage,
      organisatieService,
      BedrijfBvoService,
      authService,
      $rootScope,
      $resource,
      vcRecaptchaService,
      $route,
      $translate,
      swangular
    ) {
      $scope.ingediend = false;
      $scope.pasnummermask = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        placeholderChar: "\u2000",
      };
      $scope.geboortejaarmask = {
        mask: [/\d/, /\d/, /\d/, /\d/],
        placeholderChar: "\u2000",
      };
      $scope.submitVrijstelling = function () {
        $log.info($scope.pasnummer, $scope.geboortejaar);

        $resource(backendBaseUrl + "/vrijstelling", null, {})
          .save({
            pasnummer: $scope.pasnummer,
            geboortejaar: $scope.geboortejaar,
            captchatoken: $scope.gRecaptchaResponse,
          })
          .$promise.then(
            function succes() {
              $scope.ingediend = true;
              $rootScope.$broadcast("winkelmandchanged");
            },
            function failure(result) {
              vcRecaptchaService.reload();
              if (result.status === 404 || result.status === 406) {
                swangular.swal({
                  title: $translate.instant(
                    "vrijstellingsaanvraag.popup_niet_gevonden.titel"
                  ),
                  text: $translate.instant(
                    "vrijstellingsaanvraag.popup_niet_gevonden.tekst"
                  ),
                  type: "info",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              } else if (result.status === 410) {
                // this means the pas has expired
                swangular.swal({
                  title: $translate.instant(
                    "vrijstellingsaanvraag.popup_ongeldig.titel"
                  ),
                  text: $translate.instant(
                    "vrijstellingsaanvraag.popup_ongeldig.tekst"
                  ),
                  type: "error",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            }
          );
      };
      $scope.toWinkelmand = function () {
        $location.path("/winkelmand");
      };

      $scope.toVrijstelling = function () {
        $route.reload();
      };
    },
  ]);
