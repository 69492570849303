var module = angular
  .module("aanstellingspas.logout", [])
  .config(function ($routeProvider) {
    $routeProvider.when("/logout/", {
      controller: "logoutController",
      templateUrl: "app/modules/logout/logout.view.html",
    });
  });

module.controller("logoutController", [
  "$scope",
  "$rootScope",
  "$location",
  "authService",
  "notificationservice",
  "$localStorage",
  "$window",
  "winkelmandService",
  "$translate",
  "swangular",
  function (
    $scope,
    $rootScope,
    $location,
    authService,
    notificationService,
    $localStorage,
    $window,
    winkelmandService,
    $translate,
    swangular
  ) {
    $scope.logOut = function () {
      winkelmandService.hasWinkelmandItems().then(function succes(result) {
        $rootScope.items = result.items;
        if (result.items.length > 0) {
          swangular.swal({
            title: $translate.instant("sure"),
            text: $translate.instant("items"),
            showCancelButton: true,
            confirmButtonText: $translate.instant("logout"),
            cancelButtonText: $translate.instant("cancel"),
            focusConfirm: false,
            preConfirm: function confirmAction(confirmed) {
              if (confirmed == true) {
                authService.logout();

                $rootScope.userproperties = null;
                delete $localStorage.userproperties;

                notificationService.info($translate.instant("logged_out"));

                $location.path("/#/home");

                $scope.$apply();
              }
            },
          });
        } else {
          swangular.swal({
            title: $translate.instant("sure"),
            showCancelButton: true,
            confirmButtonText: $translate.instant("logout"),
            cancelButtonText: $translate.instant("cancel"),
            focusConfirm: false,
            preConfirm: function confirmAction(confirmed) {
              if (confirmed == true) {
                authService.logout();

                $rootScope.userproperties = null;
                delete $localStorage.userproperties;

                notificationService.info($translate.instant("logged_out"));

                $location.path("/#/home");

                $scope.$apply();
              }
            },
          });
        }
      });
    };
  },
]);
