var module = angular
  .module("aanstellingspas.service.bedrijfsservice", [])
  .factory("bedrijfsService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location
    ) {
      var bedrijfsServiceInstance = {};

      /**
       * Create a new bedrijf
       * @param  {string} email               email
       * @param  {string} contactpersoonnaam  contactpersoonnaam
       * @param  {string} afdeling            afdeling
       * @param  {string} telefoonnummer      telefoonnummer
       * @param  {string} password            password
       * @param  {string} kvkNummer           kvkNummer
       * @param  {string} land                land
       * @param  {string} bedrijfsnaam    	bedrijfsnaam
       * @param  {string} vestigingsplaats    	vestigingsplaats
       * @param  {string} straatnaam    		straatnaam
       * @param  {string} huisnummer    		huisnummer
       * @param  {string} toevoeging    		toevoeging
       * @param  {string} postcode    		postcode
       * @param  {string} plaatsnaam    		plaatsnaam
       */
      bedrijfsServiceInstance.createBedrijf = function (
        email,
        contactpersoonvoornaam,
        contactpersoonachternaam,
        afdeling,
        telefoonnummer,
        password,
        kvkNummer,
        btwNummer,
        land,
        handelsnaam,
        vestigingsplaats,
        straatnaam,
        huisnummer,
        toevoeging,
        postcode,
        plaatsnaam,
        consent,
        captchatoken
      ) {
        return $resource(backendBaseUrl + "/bedrijf").save({
          email: email,
          contactpersoonnaam:
            contactpersoonvoornaam + " " + contactpersoonachternaam,
          password: password,
          kvkNummer: kvkNummer,
          btwNummer: btwNummer,
          land: land,
          afdeling: afdeling,
          telefoonnummer: telefoonnummer,
          handelsnaam: handelsnaam,
          vestigingsplaats: vestigingsplaats,
          straatnaam: straatnaam,
          huisnummer: huisnummer,
          toevoeging: toevoeging,
          postcode: postcode,
          plaatsnaam: plaatsnaam,
          consent: consent,
          captchatoken: captchatoken,
          preferredLanguage: $localStorage.temporaryLanguage,
        });
      };

      bedrijfsServiceInstance.createFromToken = function (
        token,
        email,
        contactpersoonvoornaam,
        contactpersoonachternaam,
        afdeling,
        telefoonnummer,
        password,
        consent,
        captchatoken
      ) {
        return $resource(backendBaseUrl + "/bedrijf/invitetoken").save({
          inviteToken: token,
          emailaddress: email,
          voornaam: contactpersoonvoornaam,
          achternaam: contactpersoonachternaam,
          password: password,
          afdeling: afdeling,
          telefoonNummer: telefoonnummer,
          consent: consent,
          gRecaptchaResponse: captchatoken,
          preferredLanguage: $localStorage.temporaryLanguage,
        });
      };

      bedrijfsServiceInstance.inviteEmployee = function (email) {
        return $resource(backendBaseUrl + "/bedrijf/invite/").save({
          emailaddress: email,
        });
      };

      bedrijfsServiceInstance.getBedrijfResourceForUser = function (userId) {
        return $resource(backendBaseUrl + "/user/" + userId + "/bedrijf").get()
          .$promise;
      };

      bedrijfsServiceInstance.getBedrijfFromToken = function (token) {
        return $resource(
          backendBaseUrl + "/bedrijf/" + "/invitetoken/" + token
        ).get().$promise;
      };

      bedrijfsServiceInstance.getBestellingen = function (bedrijfsId) {
        return $resource(
          backendBaseUrl + "/bedrijfbestellingen/" + bedrijfsId
        ).get().$promise;
      };

      bedrijfsServiceInstance.getBedrijfResourceForThisUser = function () {
        return this.getBedrijfResourceForUser($localStorage.authticket.user_id);
      };

      return bedrijfsServiceInstance;
    },
  ]);
