"use strict";

var module = angular.module("aanstellingspas.dashboard", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/dashboard/", {
    templateUrl: "app/modules/dashboard/dashboard.view.html",
    controller: "DashboardController",
    title: "titles.dashboard",
    requireAuth: true,
    navigation_id: "dashboard",
  });
});

module.controller("DashboardController", [
  "$scope",
  "$http",
  "$log",
  "BedrijfBvoService",
  "organisatieService",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$window",
  "authService",
  "$location",
  "$rootScope",
  "downloadfileService",
  "$translate",
  function (
    $scope,
    $http,
    $log,
    bvoService,
    organisatieService,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $window,
    authService,
    $location,
    $rootScope,
    downloadfileService,
    $translate
  ) {
    // config
    $scope.itemsPerPage = 5;

    $scope.bestellingenresult = [];
    $scope.totalItems = 0;
    function getBestellingen() {
      $resource(backendBaseUrl + "/bestellingen")
        .get({
          pageNumber: 1,
          pageSize: 4,
        })
        .$promise.then(function success(result) {
          $scope.bestellingenResult = result;
        });
    }

    function getAanvragen() {
      $resource(backendBaseUrl + "/bedrijf/" + $scope.bedrijfId + "/aanvragen")
        .query({})
        .$promise.then(function success(result) {
          $scope.aanvragenResult = result.reverse();
        });
    }

    function getExamens() {
      $resource(backendBaseUrl + "/reservering")
        .query({})
        .$promise.then(function success(result) {
          $scope.examensResult = result.reverse();
        });
    }
    //go to the detail view of examen
    $scope.goToReservering = function (reserveringId) {
      $location.path("/reservering/" + reserveringId);
    };

    $scope.goToAanvraag = function (aanvraag) {
      var subType = "";
      switch (aanvraag.subType) {
        case "EersteAanvraag":
          subType = "eerste";
          break;
        case "Verlenging":
          subType = "verlenging";
          break;
        case "Duplicaat":
          subType = "duplicaat";
          break;
        case "OmzettingBijplaatsing":
          subType = "omzetting";
          break;
        default:
          alert("Subtype niet bekend. Fix me @ aanvragen.module.js");
          return;
      }

      if ($rootScope.isInRole("gemeente")) {
        $location.path(
          "/pasaanvraag-" +
            subType +
            "-gemeente/" +
            aanvraag.type +
            "/" +
            aanvraag.id
        );
      } else {
        $location.path(
          "/pasaanvraag-" + subType + "/" + aanvraag.type + "/" + aanvraag.id
        );
      }
    };

    /*
     * When user navigates to another page, then get bestellingen for that page
     */
    $scope.pageChanged = function (newPage) {
      getBestellingen(newPage);
    };

    /*
     * Shows a message that function is not implemented yet
     */
    $scope.notImplemented = function () {
      notificationService.info("Deze functie is nog niet geimplementeerd.");
    };

    $scope.downloadFactuur = function (bestelling) {
      downloadfileService.downloadFactuur(bestelling.factuurId);
    };

    $scope.getBestellingStatusTranslation = function (status) {
      return $translate.instant(
        "laatste_bestellingen.status." + status.toLowerCase()
      );
    };

    // init
    organisatieService
      .getOrganisatieResourceForUser($localStorage.authticket.user_id)
      .then(
        function success(result) {
          // we now have the organisatie for this user

          $scope.bedrijfId = result.id;

          getBestellingen();
          getAanvragen();

          if (authService.isInRole("bedrijf")) {
            getExamens();
          }
        },
        function failure(result) {
          $log.debug("failed to get bedrijf for user", result);
        }
      );
    // end init
  },
]);
