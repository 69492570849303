"use strict";

var module = angular
  .module("aanstellingspas.pasaanvraag-duplicaat", [
    "ngMessages",
    "validbirthdate",
    "angularDirtyformCheck",
    "validaanstellingsdate",
  ])

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-duplicaat/beroeps", {
      templateUrl:
        "app/modules/pasaanvraag-duplicaat/pasaanvraag-duplicaat.view.html",
      controller: "PasaanvraagDuplicaatController",
      title: "titles.duplicaat",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "beroeps",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-duplicaat/transport", {
      templateUrl:
        "app/modules/pasaanvraag-duplicaat/pasaanvraag-duplicaat-transport.view.html",
      controller: "PasaanvraagDuplicaatController",
      title: "titles.duplicaat",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "transport",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-duplicaat-gemeente/:soort", {
      templateUrl:
        "app/modules/pasaanvraag-duplicaat/pasaanvraag-duplicaat-gemeente.view.html",
      controller: "PasaanvraagDuplicaatController",
      title: "titles.duplicaat",
      requireAuth: true,
      requireGemeente: true,
      navigation_id: "pasaanvraag",
    });
  })

  .controller("PasaanvraagDuplicaatController", [
    "backendBaseUrl",
    "$scope",
    "authService",
    "$location",
    "BedrijfBvoService",
    "organisatieService",
    "$localStorage",
    "$rootScope",
    "$resource",
    "$log",
    "$timeout",
    "$route",
    "$routeParams",
    "WizardHandler",
    function (
      backendBaseUrl,
      $scope,
      authService,
      $location,
      bvoService,
      organisatieService,
      $localStorage,
      $rootScope,
      $resource,
      $log,
      $timeout,
      $route,
      $routeParams,
      WizardHandler
    ) {
      $scope.backendBaseUrl = backendBaseUrl;
      $scope.organisatieId = null;
      $scope.duplicaatSoort = $routeParams.soort
        ? $routeParams.soort
        : $route.current.pasType;
      $scope.pasAanvraagModel = {};

      organisatieService
        .getOrganisatieResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          // we now have the bedrijf for this user

          $scope.organisatieId = result.id;

          if ($scope.duplicaatSoort === "beroeps") {
            organisatieService.handlePermissionPasaanvraag(
              $scope.organisatieId
            );
          }
        });

      $scope.getstep = function () {
        return WizardHandler.wizard("aanvraag").currentStepNumber();
      };

      $scope.indienen = function () {
        var onderdelen = $scope.createOnderdelenFromForms();

        // do a correction for transport
        $resource(backendBaseUrl + "/pasaanvraag/").save(
          {
            onderdelen: onderdelen,
            pasType: $scope.duplicaatSoort,
            type: "duplicaat",
          },
          function success(result) {
            $scope.setAllFormsPristine();
            $location.path("/pasaanvraag/ingediend");
            $rootScope.$broadcast("winkelmandchanged");
          },
          function fail(error) {
            $log.error(error);
          }
        );
      };
    },
  ]);
