"use strict";

var module = angular
  .module("aanstellingspas.privacy-verklaring", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/privacy-verklaring/", {
      templateUrl:
        "app/modules/privacy-verklaring/privacy-verklaring.view.html",
      controller: "privacy-verklaringController",
      title: "Home",
      hideTitle: true,
      isPrivacyVerklaringPage: true,
    });
  })

  .controller("privacy-verklaringController", [
    "$scope",
    "$location",
    "$rootScope",
    "$localStorage",
    "$log",
    "$window",
    "$translate",
    function (
      $scope,
      $location,
      $rootScope,
      $localStorage,
      $log,
      $window,
      $translate
    ) {
      /*
       * triggered when the user clicks the 'I consent' button
       */
      $scope.giveCookieConsent = function () {
        $localStorage.cookieConsent = true;

        // tell analytics to fire up it's engines
        $rootScope.$broadcast("cookie-consent-given");
        $log.debug("cookie consent given");

        $location.path("/home");
        $window.location.reload();
      };
    },
  ]);
