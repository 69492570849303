var module = angular
  .module("aanstellingspas.service.aanstellingspasbanner", [])
  .factory("aanstellingspasbanner", [
    "$log",
    function ($log) {
      return function () {
        $log.debug(
          "                       _       _ _                                   "
        );
        $log.debug(
          "                      | |     | | |                                   "
        );
        $log.debug(
          "  __ _  __ _ _ __  ___| |_ ___| | (_)_ __   __ _ ___ _ __   __ _ ___ "
        );
        $log.debug(
          " / _` |/ _` | '_ \\/ __| __/ _ \\ | | | '_ \\ / _` / __| '_ \\ / _` / __|"
        );
        $log.debug(
          "| (_| | (_| | | | \\__ \\ ||  __/ | | | | | | (_| \\__ \\ |_) | (_| \\__ \\"
        );
        $log.debug(
          " \\__,_|\\__,_|_| |_|___/\\__\\___|_|_|_|_| |_|\\__, |___/ .__/ \\__,_|___/"
        );
        $log.debug(
          "                                            __/ |   | |              "
        );
        $log.debug(
          "                                           |___/    |_|              "
        );
      };
    },
  ]);
