var module = angular.module("aanstellingspas.provincieregistratie", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/provincieregistratie", {
    templateUrl:
      "app/modules/provincieregistratie/provincieregistratie.view.html",
    controller: "ProvincieregistratieController",
    title: "titles.provincieregistratie",
    hideTitle: true,
  });
});

module.controller("ProvincieregistratieController", [
	"$scope",
	"$resource",
	"backendBaseUrl",
	"$log",
	"$window",
	"$location",
	"$anchorScroll",
	"$timeout",
	"authService",
	"$rootScope",
	"provincieService",
	"notificationservice",
	"vcRecaptchaService",
	"$translate",
	function (
		$scope,
		$resource,
		backendBaseUrl,
		$log,
		$window,
		$location,
		$anchorScroll,
		$timeout,
		authService,
		$rootScope,
		provincieService,
		notificationService,
		vcRecaptchaService,
		$translate
	) {
		$scope.provincies = [
			{ text: "Drenthe" },
			{ text: "Flevoland" },
			{ text: "Frysl\u00E2n" },
			{ text: "Gelderland" },
			{ text: "Groningen" },
			{ text: "Limburg" },
			{ text: "Noord-Brabant" },
			{ text: "Noord-Holland" },
			{ text: "Overijssel" },
			{ text: "Utrecht" },
			{ text: "Zeeland" },
			{ text: "Zuid-Holland" },
		];

		$scope.$on("validPasswordEvent", function (event, data) {
			$scope.validPassword = data;
		});

    /*
     * checks via the backend if a username is available
     */
    $scope.checkemailaddressAvailable = function () {
      var emailaddress = $scope.registerModel.emailaddress;
      if (!_.isEmpty(emailaddress)) {
        authService.emailaddressAvailable(emailaddress).$promise.then(
          function (result) {
            // set the validity of the 'unique' validator on the username form fields
            // 'unique' is a custom validation that gets 'spawned' here
            $rootScope.registerForm.emailaddress.$setValidity(
              "unique",
              result.available
            );
          },
          function failure() {}
        );
      }
    };

		/*
		 * Function for setting registermodel when the user has clicked on the 'choose this company' button
		 */
		var setRegisterModel = function (subdossiernummer) {
			// creating scopes
			$scope.registerModel = {};
			$scope.gekozenBedrijf;

			// gets KvkNummer and subdossiernummer from previous step by URL parameters
			$scope.registerModel.kvkNummer = $scope.kvkNummer;
			$scope.registerModel.subdossiernummer = subdossiernummer;
		};

    /*
     * Does the actual registration api call after all fields are valid
     */
    $scope.register = function () {
      provincieService
        .createProvincie(
          $scope.registerModel.emailaddress,
          $scope.registerModel.contactpersoonvoornaam +
            " " +
            $scope.registerModel.contactpersoonachternaam,
          $scope.registerModel.provincieNaam,
          $scope.registerModel.afdeling,
          $scope.registerModel.huisnummer,
          $scope.registerModel.huisnummertoevoeging,
          $scope.registerModel.plaats,
          $scope.registerModel.vestigingsplaats,
          $scope.registerModel.postcode,
          $scope.registerModel.straatnaam,
          $scope.registerModel.telefoonnummer,
          $scope.registerModel.password,
          $scope.registerModel.consentGiven
        )

				.$promise.then(
					function (result) {
						$scope.doneRegistering = true;
					},
					function (result) {
						$log.error("Could not create user/bedrijf, error: ", result);
						notificationService.error($translate.instant("error"));
						vcRecaptchaService.reload();
					}
				);
		};

		$scope.backHandler = function () {
			$location.path("/registratie-keuze");
		};
	},
]);
