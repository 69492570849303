var module = angular
  .module("aanstellingspas.service.aanvullennotificatieservice", [])
  .factory("aanvullenNotificatieService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location
    ) {
      var aanvullenNotificatieServiceInstance = {};

      aanvullenNotificatieServiceInstance.needsAanvulling = function (
        deelnemerList
      ) {
        if (
          aanvullenNotificatieServiceInstance.onbetaaldeDeelnemers(
            deelnemerList
          ) ||
          aanvullenNotificatieServiceInstance.deelnemersAanTevullen(
            deelnemerList
          )
        ) {
          return true;
        }
        return false;
      };

      aanvullenNotificatieServiceInstance.onbetaaldeDeelnemers = function (
        deelnemerList
      ) {
        var x = _.filter(deelnemerList, function (d) {
          return d.betaald === false;
        }).length;
        if (x > 0) {
          return true;
        }
        return false;
      };

      aanvullenNotificatieServiceInstance.deelnemersAanTevullen = function (
        deelnemerList
      ) {
        return _.some(deelnemerList, function (deelnemer) {
          return deelnemerIsAangevuld(deelnemer) === false;
        });
      };

      var deelnemerIsAangevuld = function (deelnemer) {
        if (
          deelnemer.voorletters &&
          deelnemer.achternaam &&
          deelnemer.geboorteDatum &&
          deelnemer.werkgeverEmailAdres
        ) {
          return true;
        }
        return false;
      };
      return aanvullenNotificatieServiceInstance;
    },
  ]);
