var module = angular
  .module("aanstellingspas.pasaanvraag-eerste", [
    "ngMessages",
    "validbirthdate",
    "validaanstellingsdate",
    "angularDirtyformCheck",
  ])

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-eerste/beroeps", {
      templateUrl:
        "app/modules/pasaanvraag-eerste/pasaanvraag-eerste-beroeps.view.html",
      controller: "PasaanvraagEersteController",
      title: "titles.eerste",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "beroeps",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-eerste-gemeente/beroeps", {
      templateUrl:
        "app/modules/pasaanvraag-eerste/pasaanvraag-eerste-beroeps-gemeente.view.html",
      controller: "PasaanvraagEersteController",
      title: "titles.eerste",
      requireAuth: true,
      requireGemeente: true,
      navigation_id: "pasaanvraag",
      pasType: "beroeps",
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/pasaanvraag-eerste/transport", {
      templateUrl:
        "app/modules/pasaanvraag-eerste/pasaanvraag-eerste-transport.view.html",
      controller: "PasaanvraagEersteController",
      title: "titles.eerste",
      requireAuth: true,
      navigation_id: "pasaanvraag",
      pasType: "transport",
    });
  })

  .controller("PasaanvraagEersteController", [
    "backendBaseUrl",
    "$scope",
    "authService",
    "$location",
    "BedrijfBvoService",
    "organisatieService",
    "$localStorage",
    "$rootScope",
    "$resource",
    "$log",
    "$timeout",
    "$route",
    "$routeParams",
    "vcRecaptchaService",
    "WizardHandler",
    function (
      backendBaseUrl,
      $scope,
      authService,
      $location,
      bvoService,
      organisatieService,
      $localStorage,
      $rootScope,
      $resource,
      $log,
      $timeout,
      $route,
      $routeParams,
      vcRecaptchaService,
      WizardHandler
    ) {
      $scope.pasAanvraagModel = {};
      $scope.backendBaseUrl = backendBaseUrl;
      $scope.bedrijfsId = null;
      $scope.correctMode = false;
      $scope.userProperties;

      // check if the user has a valid bvo and set scope values accordingly
      organisatieService
        .getOrganisatieResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          // we now have the bedrijf for this user

          $scope.organisatieId = result.id;

          if ($route.current.pasType === "beroeps") {
            organisatieService.handlePermissionPasaanvraag(
              $scope.organisatieId
            );
          }
        });

      $scope.getUserProperties = function () {
        $resource(backendBaseUrl + "/userproperties")
          .get()
          .$promise.then(function success(userProperties) {
            $scope.userProperties = userProperties;
          });
      };
      $scope.getUserProperties();

      $scope.indienen = function () {
        var onderdelen = $scope.createOnderdelenFromForms();

        // do a correction for transport
        $resource(backendBaseUrl + "/pasaanvraag/").save(
          {
            onderdelen: onderdelen,
            type: "eerste",
            pasType: $route.current.pasType,
          },
          function success(result) {
            $scope.setAllFormsPristine();
            $location.path("/pasaanvraag/ingediend");
            $rootScope.$broadcast("winkelmandchanged");
          },
          function fail(error) {
            $log.error(error);
          }
        );
      };
    },
  ]);
