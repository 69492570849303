/**
 *                 <ctabar
                        back-handler="handleBackButton()"
                        back-button-text="Go BACK!"
                        primary-handler="handlePrimaryButton()"
                        primary-button-text="Maak weloverwogen keuzes"
                        disable-primary-button="!someForm.$valid"
                        back-icon="fa-sign-out"
                        primary-icon="fa-cog"
                        >
                </ctabar>
 */

var module = angular
  .module("aanstellingspas.helpbar", [])
  .directive("helpbar", [
    "$translate",
    function ($translate, $translatePartialLoader) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        replace: true,
        scope: {},
        templateUrl: "app/modules/directives/helpbar/helpbar.view.html",
        link: function (scope, element, attrs) {},
      };
    },
  ]);
