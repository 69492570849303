"use strict";

var module = angular
  .module("aanstellingspas.examenoverzicht", ["smart-table"])

  .config(function ($routeProvider) {
    $routeProvider.when("/examenoverzicht", {
      templateUrl: "app/modules/examenoverzicht/examenoverzicht.view.html",
      controller: "ExamenoverzichtController",
      title: "titles.my_exams",
      navigation_id: "examens",
    });
  })

  .controller("ExamenoverzichtController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$log",
    "$location",
    "$localStorage",
    "$translate",
    "swangular",
    function (
      $scope,
      $resource,
      backendBaseUrl,
      $log,
      $location,
      $localStorage,
      $translate,
      swangular
    ) {
      if ($localStorage.onboardingRead) {
        $log.debug("onboarding already shown, hiding from now");
      } else {
        $log.debug("first time on exams, showing onboarding");
        swangular
          .open({
            title: $translate.instant("onboarding.title"),
            htmlTemplate:
              "app/modules/general/modals/onboarding/onboarding.view.html",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            scope: $scope,
          })
          .then(function (result) {
            if (result) {
              $scope.hideOnboarding();
            }
          });
      }

      $scope.hideOnboarding = function () {
        $localStorage.onboardingRead = true;
        swangular.close();
      };

      //reserveringen from the get request
      $scope.examens = [];

      //the displayed and filtered reserveringen
      $scope.displayedExamens = [];

      //inital state for select2
      $scope.selectedStatus = "ALLES";

      //gets all reserveringen for bedrijf
      $resource(backendBaseUrl + "/reservering")
        .query()
        .$promise.then(
          function success(result) {
            $scope.examens = result;
            _.forEach($scope.examens, function (examen) {
              examen.friendlyReserveringStatus = $scope.getFriendlyStatus(
                examen.reserveringStatus
              );
            });

            $scope.examens = _.sortByOrder($scope.examens, "datum", "desc");
          },
          function failure(result) {
            $log.error(result);
          }
        );

      //the possible statii for a reservering
      $translate("status.all").then(function () {
        $scope.status = [
          {
            id: "ALLES",
            text: $translate.instant("status.all"),
          },
          {
            id: "NOG_AANVULLEN",
            text: $translate.instant("status.aanvullen"),
          },
          {
            id: "AANGEVULD",
            text: $translate.instant("status.afwachting"),
          },
          {
            id: "AFGESLOTEN",
            text: $translate.instant("status.voltooid"),
          },
        ];
      });
      //gets a displayable string for eacht status
      $scope.getFriendlyStatus = function (statusId) {
        if (statusId === "NOG_AANVULLEN") {
          return $translate.instant("friendlystatus.aanvullen");
        }
        if (statusId === "AANGEVULD") {
          return $translate.instant("friendlystatus.afwachting");
        }
        if (statusId === "AFGESLOTEN") {
          return $translate.instant("friendlystatus.voltooid");
        }
        if (statusId === "GEWEEST") {
          return $translate.instant("friendlystatus.voltooid");
        } else {
          return $translate.instant("friendlystatus.onbekend");
        }
      };

      //go to the detail view of examen
      $scope.selectDetails = function (reserveringId) {
        $location.path("/reservering/" + reserveringId);
      };

      //go to the deelnemer view of examen
      $scope.selectDeelnemers = function (reserveringId) {
        $location.path("/reservering/" + reserveringId + "/deelnemers");
      };

      // Do some formatting on select2 options
      $scope.s2_status_options = {
        formatSelection: function (status) {
          return (
            $translate.instant("examens.select.title") +
            " " +
            "<strong>" +
            status.text +
            "</strong>"
          );
        },
        allowClear: false,
        //don't display the searchbar
        minimumResultsForSearch: -1,
      };

      $scope.orderBy = function (column) {
        // if the currently sorted column was clicked again
        if ($scope.currentOrder === column) {
          if ($scope.currentOrderDirection === "desc") {
            $scope.currentOrderDirection = "asc";
          } else {
            $scope.currentOrderDirection = "desc";
          }
        } else {
          $scope.currentOrder = column;
          $scope.currentOrderDirection = "asc";
        }

        $scope.examens = _.sortByOrder(
          $scope.examens,
          column,
          $scope.currentOrderDirection
        );
      };
    },
  ]);

//directive to use select2 as filter for smart-table
module.directive("stSelectDistinctStatus", [
  function () {
    return {
      restrict: "E",
      require: "^stTable",
      template:
        '<select2 name="status" ng-model="selectedStatus" ng-change="optionChanged(selectedStatus)" s2-options="item.id as item.text for item in status track by item.id" options="s2_status_options"></select2>',
      link: function (scope, element, attr, table) {
        var getPredicate = function () {
          var predicate = scope.predicate;
          if (!predicate && scope.predicateExpression) {
            predicate = scope.predicateExpression;
          }
          return predicate;
        };

        scope.optionChanged = function (selectedOption) {
          var predicate = getPredicate();

          var query = {};

          query = selectedOption;

          if (query === "ALLES") {
            query = null;
          }

          table.search(query, predicate);
        };
      },
    };
  },
]);
