var module = angular
  .module("aanstellingspas.service.organisatieservice", [])
  .factory("organisatieService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    "$translate",
    "swangular",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location,
      $translate,
      swangular
    ) {
      var organisatieServiceInstance = {};

      organisatieServiceInstance.getOrganisatieResourceForUser = function (
        userId
      ) {
        return $resource(
          backendBaseUrl + "/user/" + userId + "/organisatie"
        ).get().$promise;
      };

      organisatieServiceInstance.getBestellingen = function (organisatieId) {
        return $resource(
          backendBaseUrl + "/organisatiebestellingen/" + organisatieId
        ).get().$promise;
      };

      organisatieServiceInstance.getOrganisatieResourceForThisUser = function () {
        return this.getOrganisatieResourceForUser(
          $localStorage.authticket.user_id
        );
      };
      /*
      Checks if vestigingsplaats has been confirmed.
      */
      organisatieServiceInstance.vestigingsplaatsNeedsConfirmation = function (
        organisatieId
      ) {
        return $resource(
          backendBaseUrl +
            "/organisatie/" +
            organisatieId +
            "/vestigingsplaatsneedsconfirmation"
        ).get().$promise;
      };

      /*
      Confirms the current verzendadres.plaatsnaam as vestigingsplaats.
      */
      organisatieServiceInstance.confirmVestigingsplaats = function (
        organisatieId
      ) {
        return $resource(
          backendBaseUrl +
            "/organisatie/" +
            organisatieId +
            "/confirmvestigingsplaats"
        ).save().$promise;
      };

      organisatieServiceInstance.checkIfAllowed = function (organisatieId) {
        return $resource(
          backendBaseUrl + "/organisatie/" + organisatieId + "/allowed"
        ).get().$promise;
      };

      organisatieServiceInstance.createRijkswaterstaatGebruiker = function (
        email,
        contactpersoonnaam,
        afdeling,
        telefoonnummer,
        password,
        consentGiven
      ) {
        return $resource(backendBaseUrl + "/rijkswaterstaatgebruiker").save({
          email: email,
          contactpersoonnaam: contactpersoonnaam,
          password: password,
          telefoonnummer: telefoonnummer,
          afdeling: afdeling,
          consent: consentGiven,
          preferredLanguage: $localStorage.temporaryLanguage,
        });
      };

      organisatieServiceInstance.handlePermissionPasaanvraag = function (
        organisatieId
      ) {
        this.checkIfAllowed(organisatieId).then(function succes(result) {
          if (result.allowed === false) {
            if (result.reason === "BVO") {
              $location.path("/profile/bvoneeded");
            } else if (result.reason === "NotApproved") {
              swangular
                .swal({
                  title: $translate.instant(
                    "services.organisatieservice.wacht_op_goedkeuring"
                  ),
                  text: $translate.instant(
                    "services.organisatieservice.nog_niet_bevestigd"
                  ),
                  type: "info",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                })
                .then(function () {
                  $location.path("/aanvragen");
                  $scope.$apply();
                });
            }
          } else {
            return "done";
          }
        });
      };

      return organisatieServiceInstance;
    },
  ]);
