"use strict";

var module = angular.module("aanstellingspas.sitemap", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/sitemap/", {
    templateUrl: "app/modules/sitemap/sitemap.view.html",
    title: "Sitemap",
    requireAuth: false,
    navigation_id: "sitemap",
  });
});
