var module = angular.module(
  "aanstellingspas.registratie-bestaande-organisatie",
  []
);

module.config(function ($routeProvider) {
  $routeProvider.when("/invite/", {
    templateUrl:
      "app/modules/registratie-bestaande-organisatie/registratie-bestaande-organisatie.view.html",
    controller: "RegistratieBestaandeOrganisatieController",
    title: "titles.registratie-bestaande-organisatie",
    hideTitle: true,
  });
});

module.controller("RegistratieBestaandeOrganisatieController", [
  "$scope",
  "$log",
  "$location",
  "bedrijfsService",
  "vcRecaptchaService",
  "$translate",
  "swangular",
  function (
    $scope,
    $log,
    $location,
    bedrijfsService,
    vcRecaptchaService,
    $translate,
    swangular
  ) {
    $scope.registerModel = {};
    $scope.registerModel.emailaddress = $location.search().email;
    $scope.token = $location.search().joinToken;

    $scope.$on("validPasswordEvent", function (event, data) {
      $scope.validPassword = data;
    });

    bedrijfsService.getBedrijfFromToken($scope.token).then(
      function success(result) {
        $scope.adres = result.adres;
        $scope.handelsnaam = result.handelsnaam;
      },
      function failure(result) {
        $log.error(result);
      }
    );

    $scope.register = function () {
      if ($scope.validPassword === true) {
        bedrijfsService
          .createFromToken(
            $scope.token,
            $scope.registerModel.emailaddress,
            $scope.registerModel.contactpersoonvoornaam,
            $scope.registerModel.contactpersoonachternaam,
            $scope.registerModel.afdeling,
            $scope.registerModel.telefoonnummer,
            $scope.registerModel.password,
            $scope.registerModel.consentGiven,
            $scope.gRecaptchaResponse
          )
          .$promise.then(
            function (result) {
              $scope.doneRegistering = true;
              $location.path("/profile");
            },
            function (result) {
              $log.error("Could not create user/bedrijf, error: ", result);
              vcRecaptchaService.reload();
              swangular.swal({
                title: $translate.instant(
                  "bedrijfsregistratie.error.general_titel"
                ),
                text: $translate.instant("bedrijfsregistratie.error.general"),
                type: "error",
              });
            }
          );
      }
    };
  },
]);
