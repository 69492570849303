"use strict";

var module = angular.module("aanstellingspas.bvo", [
  "aanstellingspas.service.bedrijfsservice",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/profile/bvoneeded", {
    templateUrl: "app/modules/bvo/notify.view.html",
    title: "titles.bvoneeded",
    requireAuth: true,
    navigation_id: "pasaanvraag",
  });

  $routeProvider.when("/profile/bvorouter", {
    requireAuth: true,
    // This resolve checks the bvo proces. It then redirects the user to the page he needs to be
    resolve: {
      redirect: function (
        bedrijfsService,
        $localStorage,
        BedrijfBvoService,
        $location,
        $log
      ) {
        var bedrijfId;

        return bedrijfsService
          .getBedrijfResourceForUser($localStorage.authticket.user_id)
          .then(
            function success(result) {
              // we now have the bedrijf for this user
              BedrijfBvoService.getBvoForBedrijf(result.id).then(
                function success(bvos) {
                  // we now have the bvo for this bedrijf
                  bedrijfId = result.id;

                  // with this bvo we can check the following cases:

                  // is there a bvo yet
                  if (bvos.length === 0) {
                    $log.debug(
                      "bedrijf has no bvo yet, bvo will be created with first indienpoging"
                    );
                    $location.path("/profile/bvo/new");
                    return;
                  } else {
                    var bvo = bvos[0];
                    // we have only one bvo per bedrijf at this time
                    if (BedrijfBvoService.bvoIsBewezen(bvo)) {
                      // user is done with bvo. show him the validbvo screen
                      $location.path("/profile/bvo/bvoapproved");
                      return;
                    }

                    if (BedrijfBvoService.bvoIsCorrigeerbaar(bvo)) {
                      // if the user can correct, redirect to correct page
                      $location.path("/profile/bvo/correct");
                      return;
                    }

                    // user did an indienpoging, redirect him to the screen where he can see his documents
                    if (BedrijfBvoService.bvoHasIndienpogingen(bvo)) {
                      $location.path("/profile/bvo/ingediend");
                      return;
                    }
                    // SVNL is currently checking his bvo. Redirect to screen where he can see his documents
                    if (BedrijfBvoService.bvoIsCurrentlyBeingChecked(bvo)) {
                      $location.path("/profile/bvo/ingediend");
                      return;
                    }
                  }
                },
                function failure(result) {
                  $log.error("could not retrieve bvo for bedrijf", result);
                }
              );
            },
            function failure(result) {
              console.log("failed to get bedrijf for user", result);
            }
          );
        // end init
      },
    },
  });

  $routeProvider.when("/profile/bvo/new", {
    templateUrl: "app/modules/bvo/new.view.html",
    controller: "BvoNewController",
    title: "titles.my_settings",
    requireAuth: true,
    navigation_id: "profile",
  });
  $routeProvider.when("/profile/bvo/correct", {
    templateUrl: "app/modules/bvo/correct.view.html",
    controller: "BvoCorrectController",
    title: "titles.my_settings",
    requireAuth: true,
    navigation_id: "profile",
  });
  $routeProvider.when("/profile/bvo/ingediend", {
    templateUrl: "app/modules/bvo/done.view.html",
    controller: "BvoDoneController",
    title: "titles.my_settings",
    requireAuth: true,
    navigation_id: "profile",
  });
  $routeProvider.when("/profile/bvo/bvoapproved", {
    templateUrl: "app/modules/bvo/bvoapproved.view.html",
    controller: "BvoApprovedController",
    title: "titles.my_settings",
    requireAuth: true,
    navigation_id: "profile",
  });
});

module.factory("BedrijfBvoService", [
  "backendBaseUrl",
  "$log",
  "$resource",
  function (backendBaseUrl, $log, $resource) {
    var bvoServiceInstance = {};

    /**
     * There is no bvo and thus no indienpogingen, user hasn't yet tried to
     * upload a bvo for this bedrijfId
     * @param  {int} bedrijfId
     * @return {boolean}
     */
    bvoServiceInstance.getBvoForBedrijf = function (bedrijfId) {
      return $resource(
        backendBaseUrl + "/bedrijf/:bedrijfsId/bewijsvanopdracht"
      ).query({
        bedrijfsId: bedrijfId,
      }).$promise;
    };

    /**
     * There are indienpogingen, so the user can only correct its bvo
     * @param  {object} bvo a bvo model as retrieved via this service
     */
    bvoServiceInstance.bvoHasIndienpogingen = function (bvo) {
      if (bvo.indienPogingen.length > 0) {
        $log.debug("bvoHasIndienpogingen returns true");

        return true;
      } else {
        $log.debug("bvoHasIndienpogingen returns false");

        return false;
      }
    };

    /**
     * There are indienpogingen but the user can not currently correct the bvo
     * because SVNL is checking it
     * @param  {object} bvo a bvo model as retrieved via this service
     * @return {boolean}
     */
    bvoServiceInstance.bvoIsCurrentlyBeingChecked = function (bvo) {
      if (bvo.isInKeuring === true) {
        $log.debug("bvoIsCurrentlyBeingChecked returns true");

        return true;
      } else {
        $log.debug("bvoIsCurrentlyBeingChecked returns false");

        return false;
      }
    };

    /**
     * Bvo is already bewezen
     * @param  {object} bvo a bvo model as retrieved via this service
     * @return {boolean}
     */
    bvoServiceInstance.bvoIsBewezen = function (bvo) {
      if (bvo.isBewezen === true) {
        $log.debug("bvoIsBewezen returns true");

        return true;
      } else {
        $log.debug("bvoIsBewezen returns false");

        return false;
      }
    };

    /**
     * Bvo is afgekeurd
     * @param  {object} bvo a bvo model as retrieved via this service
     * @return {boolean}
     */
    bvoServiceInstance.bvoIsAfgekeurd = function (bvo) {
      if (bvo.isAfgekeurd === true) {
        $log.debug("bvoIsAfgekeurd returns true");

        return true;
      } else {
        $log.debug("bvoIsAfgekeurd returns false");

        return false;
      }
    };

    /**
     * Bvo is corrigeerbaar
     * @param  {object} bvo a bvo model as retrieved via this service
     * @return {boolean}
     */
    bvoServiceInstance.bvoIsCorrigeerbaar = function (bvo) {
      if (bvo.isCorrigeerbaar === true) {
        $log.debug("bvoisCorrigeerbaar returns true");

        return true;
      } else {
        $log.debug("bvoisCorrigeerbaar returns false");

        return false;
      }
    };

    return bvoServiceInstance;
  },
]);

module.controller("BvoNewController", [
  "$scope",
  "Upload",
  "$timeout",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "BedrijfBvoService",
  "bedrijfsService",
  "notificationservice",
  "$translate",
  "swangular",
  function (
    $scope,
    Upload,
    $timeout,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    bvoService,
    bedrijfService,
    notificationservice,
    $translate,
    swangular
  ) {
    $scope.maxFileSizeBVO = window.__env.bvoSettings.maxFileSizeBVO;
    $scope.maxAmountOfDocuments = window.__env.bvoSettings.maxAmountOfDocuments;
    $scope.allowedFileTypes = window.__env.bvoSettings.allowedFileTypes;

    $scope.backendBaseUrl = backendBaseUrl;

    var bedrijfId;

    // init
    bedrijfService
      .getBedrijfResourceForUser($localStorage.authticket.user_id)
      .then(function success(result) {
        bedrijfId = result.id;
      });

    /**
     * Removes a document from the array of uploaded documents
     * note: does not remove from backend yet
     */

    $scope.remove = function (item) {
      $scope.uploadedBvoFiles.splice($scope.uploadedBvoFiles.indexOf(item), 1);
    };

    /**
     * This popup is shown when a user clicks on send
     * If he confirms this popup, the data gets send to the backend
     */
    $scope.confirmSubmit = function () {
      swangular.swal({
        title: $translate.instant("bvo.notificatie.titel"),
        text: $translate.instant("bvo.notificatie.text"),
        type: "info",
        showCancelButton: true,

        confirmButtonText: $translate.instant("bvo.notificatie.indienen"),
        cancelButtonText: $translate.instant("bvo.notificatie.aanpassen"),
        preConfirm: function confirmAction(confirmed) {
          if (confirmed == true) {
            var request = $resource(
              backendBaseUrl +
                "/bedrijf/" +
                bedrijfId +
                "/bewijsvanopdracht/indienpoging",
              {},
              {
                saveData: {
                  method: "POST",
                },
              }
            );

            var model = {};

            model.Documenten = $scope.uploadedBvoFiles;

            request.saveData({}, model).$promise.then(
              function success() {
                $location.path("/profile/bvo/ingediend");
                notificationservice.success(
                  $translate.instant("bvo.notificatie.succes")
                );
              },

              function (result) {
                // bvo locked because being viewed by secretariaat
                if (result.data.reason === "locked") {
                  notificationservice.error(
                    $translate.instant("bvo.notificatie.error")
                  );
                }
              }
            );
          }
        },
      });
    };
  },
]);

module.controller("BvoCorrectController", [
  "$scope",
  "BedrijfBvoService",
  "bedrijfsService",
  "$localStorage",
  "Upload",
  "backendBaseUrl",
  "$http",
  "$location",
  "notificationservice",
  "$timeout",
  "$resource",
  "$translate",
  "$sce",
  "swangular",
  function (
    $scope,
    bedrijfBvoService,
    bedrijfService,
    $localStorage,
    Upload,
    backendBaseUrl,
    $http,
    $location,
    notificationservice,
    $timeout,
    $resource,
    $translate,
    $sce,
    swangular
  ) {
    $scope.maxFileSizeBVO = window.__env.bvoSettings.maxFileSizeBVO;
    $scope.maxAmountOfDocuments = window.__env.bvoSettings.maxAmountOfDocuments;
    $scope.allowedFileTypes = window.__env.bvoSettings.allowedFileTypes;

    $scope.backendBaseUrl = backendBaseUrl;

    var bedrijfId;

    // init
    bedrijfService
      .getBedrijfResourceForUser($localStorage.authticket.user_id)
      .then(function success(result) {
        // we now have the bedrijf for this user
        bedrijfId = result.id;
        bedrijfBvoService
          .getBvoForBedrijf(result.id)
          .then(function success(bvos) {
            // we now have the bvo for this bedrijf
            $scope.bvo = bvos;
          });
      });

    /**
     * This popup is shown when a user clicks on send
     * If he confirms this popup, the data gets send to the backend
     */
    $scope.confirmSubmit = function () {
      swangular.swal({
        title: $translate.instant("bvo.notificatie.titel"),
        text: $translate.instant("bvo.notificatie.text"),
        type: "info",
        confirmButtonText: $translate.instant("bvo.notificatie.indienen"),
        cancelButtonText: $translate.instant("bvo.notificatie.aanpassen"),
        showCancelButton: true,

        preConfirm: function confirmAction(confirmed) {
          if (confirmed == true) {
            var request = $resource(
              backendBaseUrl +
                "/bedrijf/" +
                bedrijfId +
                "/bewijsvanopdracht/indienpoging",
              {},
              {
                saveData: {
                  method: "POST",
                },
              }
            );

            var model = {};

            model.Documenten = $scope.uploadedBvoFiles;

            request.saveData({}, model).$promise.then(
              function success() {
                $location.path("/profile/bvo/ingediend");
                notificationservice.success(
                  $translate.instant("bvo.notificatie.succes")
                );
              },

              function (result) {
                // bvo locked because being viewed by secretariaat
                if (result.data.reason === "locked") {
                  notificationservice.error(
                    $translate.instant("bvo.notificatie.error")
                  );
                }
              }
            );
          }
        },
      });
    };
    $scope.remove = function (item) {
      $scope.uploadedBvoFiles.splice($scope.uploadedBvoFiles.indexOf(item), 1);
    };

    // clears all ngf form errors
    $scope.clearErrors = function () {
      $timeout(function () {
        // delete selectfile error msgs
        $scope.uploadForm.selectbtnfile.$error = {};
        // delete drag and drop error msgs
        $scope.uploadForm.dndfile.$error = {};
      });
    };

    $scope.getSafeHTML = function (string) {
      return $sce.trustAsHtml(string);
    };
  },
]);

module.controller("BvoDoneController", [
  "$scope",
  "$http",
  "$location",
  "BedrijfBvoService",
  "bedrijfsService",
  "$localStorage",
  "$translate",
  function (
    $scope,
    $http,
    $location,
    bedrijfBvoService,
    bedrijfsService,
    $localStorage,
    $translate
  ) {
    // init
    bedrijfsService
      .getBedrijfResourceForUser($localStorage.authticket.user_id)
      .then(function success(result) {
        // we now have the bedrijf for this user
        bedrijfBvoService
          .getBvoForBedrijf(result.id)
          .then(function success(bvos) {
            // we now have the bvo for this bedrijf
            $scope.bvo = bvos;
          });
      });
  },
]);

module.controller("BvoApprovedController", [
  "$scope",
  "$http",
  "$location",
  "BedrijfBvoService",
  "bedrijfsService",
  "$localStorage",
  "$translate",
  function (
    $scope,
    $http,
    $location,
    bedrijfBvoService,
    bedrijfsService,
    $localStorage,
    $translate
  ) {
    // init
    bedrijfsService
      .getBedrijfResourceForUser($localStorage.authticket.user_id)
      .then(function success(result) {
        // we now have the bedrijf for this user
        bedrijfBvoService
          .getBvoForBedrijf(result.id)
          .then(function success(bvos) {
            // we now have the bvo for this bedrijf
            $scope.bvo = bvos;
          });
      });
  },
]);

module.filter("splitByPipes", function () {
  // splits a string with double pipe delimiters to array for iteration in view
  return function (value) {
    if (!value.length) return;
    return value.split("||");
  };
});

module.directive("bvofileuploader", [
  "Upload",
  "$timeout",
  "backendBaseUrl",
  "$translate",
  "swangular",
  function (Upload, $timeout, backendBaseUrl, $translate, swangular) {
    return {
      // http://stackoverflow.com/a/21714314/6077862
      restrict: "E",
      transclude: true,
      scope: {
        backendurl: "@",
        output: "@",
        formName: "@",
        allowedFileTypes: "@",
        maxFileSize: "@",
        buttonTextLarge: "@",
        buttonTextMobile: "@",
      },
      templateUrl: "app/modules/bvo/bvofileuploader.view.html",
      link: function (scope, element, attrs) {
        // clears all ngf form errors
        scope.clearErrors = function () {
          $timeout(function () {
            // delete selectfile error msgs
            scope.errorFile = {};
          });
        };

        /**
         * Does the actual uploading to the backend.
         * Gets called when a file is present in the input type
         */
        scope.uploadFiles = function (file) {
          scope.file = file;

          if (file) {
            file.upload = Upload.upload({
              url: scope.backendurl,
              method: "POST",
              data: {
                file: file,
              },
            });

            file.upload.then(
              function (response) {
                // appending fileName original users' file name in array for use in view
                file.fileName = response.data.filename;
                file.originalFilename = scope.file.name;
                file.fileUrl = backendBaseUrl + "/" + response.data.fileUrl;
                // setting isPdf boolean if the filename contains a .pdf extension
                var extension = file.fileName.substr(
                  file.fileName.lastIndexOf(".") + 1
                );
                if (extension === "pdf") {
                  file.isPdf = true;
                } else {
                  file.isPdf = false;
                }

                // save the output to the scope
                if (!_.isArray(scope.$parent[scope.output])) {
                  debugger;
                  scope.$parent[scope.output] = [];
                }
                scope.$parent[scope.output].push(file);
              },
              function (response) {
                if (response.status !== 200) {
                  scope.bvoBestand = null;
                  if (response.status === 413) {
                    swangular.swal({
                      title: $translate.instant(
                        "bvo.uploader.notificatie.titel"
                      ),
                      text: $translate.instant(
                        "bvo.uploader.notificatie.bestandsgrootte_text"
                      ),
                      type: "error",
                    });
                  }
                  if (response.status === 415) {
                    swangular.swal({
                      title: $translate.instant(
                        "bvo.uploader.notificatie.titel"
                      ),
                      text: $translate.instant(
                        "bvo.uploader.notificatie.bestandstype_text"
                      ),
                      type: "error",
                    });
                  }
                }
              },
              function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(
                  100,
                  parseInt((100.0 * evt.loaded) / evt.total)
                );
              }
            );
          }
        };
      },
    };
  },
]);
