"use strict";

var module = angular.module("aanstellingspas.reservering", [
  "aanstellingspas.service.bedrijfsservice",
  "rt.select2",
  "ngMessages",
  "validbirthdate",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/reservering/:id", {
    templateUrl: "app/modules/reservering/reservering.view.html",
    controller: "ExamenblokController",
    title: "titles.reservering",
    reloadOnSearch: false,
    navigation_id: "examens",
  });

  $routeProvider.when("/reservering/:id/deelnemers", {
    templateUrl: "app/modules/reservering/reserveringdeelnemers.view.html",
    controller: "ReserveringDeelnemersController",
    title: "titles.reservering",
    reloadOnSearch: false,
    navigation_id: "examens",
  });
});

module.controller("ExamenblokController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  "$routeParams",
  "aanvullenNotificatieService",
  "$translate",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload,
    $routeParams,
    aanvullenNotificatieService,
    $translate
  ) {
    $scope.reserveringId = $routeParams.id;

    $scope.regios = [];
    $scope.plaatsen = [];
    $scope.kruispunten = [];
    $scope.examenModel = {};
    $scope.dagdelen = [];
    $scope.isEditable = false;
    $scope.daysLeftToEdit;
    $scope.doneLoading = false;
    $scope.isNietVoorkeursPlaats = false;
    $scope.daysLeftList = [];
    $scope.backendBaseUrl = backendBaseUrl;
    $scope.workingDays = 0;
    $scope.formattedEndDate;
    $scope.selectedDeelnemer = null;
    $scope.selectedKruispunt = null;

    var countWeekendDays = function () {
      var endDate = moment().add($scope.daysLeftToEdit, "days");
      $scope.workingDays = moment().businessDiff(endDate);
      $scope.formattedEndDate = endDate.format("D MMMM YYYY");
    };

    $scope.selectKruispunt = function (id) {
      $scope.selectedKruispunt = _.find($scope.kruispunten, { id: id });
    };

    // get examenblok
    $resource(backendBaseUrl + "/reservering/" + $scope.reserveringId)
      .get()
      .$promise.then(
        function success(examenBlokResult) {
          $scope.examenModel = examenBlokResult;

          $scope.examenModel.datum = examenBlokResult.datum;

          $scope.examenModel.dagdeel = examenBlokResult.dagdeel.dagdeel;

          $scope.isEditable = examenBlokResult.isEditable;
          $scope.daysLeftToEdit = examenBlokResult.daysLeftToEdit;

          countWeekendDays();

          if ($scope.examenModel.type !== "Transportbegeleiders examenblok") {
            getPlaatsenForExamenBlok();

            if (examenBlokResult.plaats) {
              $scope.isNietVoorkeursPlaats =
                examenBlokResult.plaats.isNietVoorkeursPlaats;
              $scope.examenModel.plaats = examenBlokResult.plaats.id;
            }

            if (examenBlokResult.kruispunt) {
              $scope.examenModel.kruispunt = examenBlokResult.kruispunt.id;
            }
          } else {
            $scope.examenModel.plaats = null;
            $scope.examenModel.kruispunt = null;
          }

          $scope.doneLoading = true;
        },
        function failure(result) {
          if (result.status === 404) {
            notificationservice.info(
              $translate.instant("notifications.reservering_not_found")
            );
            $location.path("/dashboard");
          }
          if (result.status === 405) {
            notificationservice.info(
              $translate.instant("notifications.reservering_not_paid")
            );
            $location.path("/dashboard");
          }
          if (result.status === 403) {
            notificationservice.info(
              $translate.instant("notifications.reservering_invalid")
            );
            $location.path("/dashboard");
          } else {
            $log.error(result);
          }
        }
      );
    $resource(
      backendBaseUrl + "/reservering/" + $scope.reserveringId + "/deelnemers/"
    )
      .get()
      .$promise.then(function success(result) {
        $scope.needsAanvullingNotification = aanvullenNotificatieService.needsAanvulling(
          result.deelnemers
        );
      });

    $resource(backendBaseUrl + "/dagdeel")
      .query()
      .$promise.then(
        function success(result) {
          _.each(result, function (dagdeel) {
            dagdeel.dagdeelDescription = dagdeel.dagdeelDescription.replace(
              "Ochtend",
              $translate.instant("tijdsblok.ochtend")
            );

            dagdeel.dagdeelDescription = dagdeel.dagdeelDescription.replace(
              "Middag",
              $translate.instant("tijdsblok.middag")
            );
          });
          $scope.dagdelen = result;
        },
        function failure(result) {
          if (result.status === 404) {
            notificationservice.info(
              $translate.instant("notifications.dagdelen_notfound")
            );
            $location.path("/dashboard");
          } else {
            $log.error(result);
          }
        }
      );

    /*
     * triggered when a plaats has been selected. Updates list of kruispunten.
     */
    $scope.plaatsUpdated = function () {
      getKruispuntenForPlaatsForExamenblok();
      // Set kruispunt to 0, so no kruispunt in the dropdown is selected
      $scope.examenModel.kruispunt = 0;
    };

    var getKruispuntenForPlaatsForExamenblok = function () {
      $resource(
        backendBaseUrl + "/examenblok/:examenid/plaats/:plaatsid/kruispunt",
        {
          plaatsid: $scope.examenModel.plaats,
          examenid: $scope.examenModel.id,
        },
        {}
      )
        .query()
        .$promise.then(function success(result) {
          $scope.kruispunten = result;
          const id = $scope.examenModel.kruispunt;
          $scope.selectKruispunt(id);
        });
    };

    var getPlaatsenForExamenBlok = function () {
      $resource(
        backendBaseUrl + "/examenblok/" + $scope.examenModel.id + "/plaatsen"
      )
        .query()
        .$promise.then(
          function success(result) {
            $scope.plaatsen = result;

            // get all kruispunten for dropdown
            if ($scope.examenModel.plaats) {
              getKruispuntenForPlaatsForExamenblok();
            }
          },
          function failure(result) {
            if (result.status === 404) {
              notificationservice.info(
                $translate.instant("notifications.plaatsen_notfound")
              );
              $location.path("/dashboard");
            } else {
              $log.error(result);
            }
          }
        );
    };

    /*
     * Does the actual submitting of the data to the server
     */

    $scope.updateExamenblok = function () {
      var request = $resource(
        backendBaseUrl + "/reservering/" + $scope.reserveringId,
        {},
        {
          saveData: {
            method: "PUT",
            isArray: false,
          },
        }
      );

      var model = {};
      model.plaatsId = $scope.examenModel.plaats;
      model.kruispuntId = $scope.examenModel.kruispunt;
      model.examinatorNaam = $scope.examenModel.examinatorNaam;
      model.examinatorTelefoonNummer =
        $scope.examenModel.examinatorTelefoonNummer;

      request.saveData({}, model).$promise.then(
        function success() {
          notificationservice.success(
            $translate.instant("notifications.reservering_updated")
          );
          $route.reload();
        },
        function failure() {
          if (result.status === 409) {
            $log.error("Kruispunt is niet beschikbaar");
            notificationService.error(
              $translate.instant("notifications.kruispunt_unavailable")
            );
          }
        }
      );
    };
  },
]);

module
  .controller("ReserveringDeelnemersController", [
    "$scope",
    "authService",
    "$http",
    "$location",
    "backendBaseUrl",
    "$log",
    "$resource",
    "$localStorage",
    "notificationservice",
    "$route",
    "$filter",
    "$routeParams",
    "$rootScope",
    "aanvullenNotificatieService",
    "clipboard",
    "$translate",
    "swangular",
    function (
      $scope,
      authService,
      $http,
      $location,
      backendBaseUrl,
      $log,
      $resource,
      $localStorage,
      notificationservice,
      $route,
      $filter,
      $routeParams,
      $rootScope,
      aanvullenNotificatieService,
      clipboard,
      $translate,
      swangular
    ) {
      $scope.reserveringId = $routeParams.id;
      $scope.backendBaseUrl = backendBaseUrl;
      $scope.deelnemerToevoegingPrijs;
      $scope.isEditable = false;
      $scope.userHasSeenWaarmerkUitleg = true;
      $scope.firstView = false;
      $scope.doneLoading = false;
      $scope.canAddDeelnemers = false;
      $scope.canShareResults = false;

      $scope.getDeelnemers = function () {
        $resource(
          backendBaseUrl +
            "/reservering/" +
            $scope.reserveringId +
            "/deelnemers"
        )
          .get()
          .$promise.then(
            function success(examenBlokResult) {
              $scope.canShareResults = examenBlokResult.canShareResults;
              $scope.isEditable = examenBlokResult.isEditable;
              $scope.deelnemers = examenBlokResult.deelnemers;
              $scope.canAddDeelnemers = examenBlokResult.canAddDeelnemers;
              // check if we need to display notification bolletje
              $scope.needsAanvullingNotification = aanvullenNotificatieService.needsAanvulling(
                $scope.deelnemers
              );

              $scope.userHasSeenWaarmerkUitleg =
                examenBlokResult.userHasSeenWaarmerkUitleg;
              if (!examenBlokResult.userHasSeenWaarmerkUitleg) {
                $scope.firstView = true;
              }
              if (examenBlokResult.toeTeVoegenDeelnemers) {
                $scope.extraDeelnemers = examenBlokResult.toeTeVoegenDeelnemers;
              } else {
                $scope.extraDeelnemers = [];
              }

              $resource(backendBaseUrl + "/prijs/examendeelnemertoevoeging")
                .get()
                .$promise.then(function success(prijsModel) {
                  $scope.examenDeelnemerToevoegingPrijsModel = prijsModel;
                });

              $scope.doneLoading = true;
            },
            function failure(result) {
              if (result.status === 404) {
                notificationservice.info(
                  $translate.instant("notifications.reservering_not_found")
                );
                $location.path("/dashboard");
              }
              if (result.status === 405) {
                notificationservice.info(
                  $translate.instant("notifications.reservering_not_paid")
                );
                $location.path("/dashboard");
              }
              if (result.status === 403) {
                notificationservice.info(
                  $translate.instant("notifications.reservering_invalid")
                );
                $location.path("/dashboard");
              } else {
                $log.error(result);
              }
            }
          );
      };

      $scope.getDeelnemers();

      /*
       * Saves the changes
       */
      $scope.finishEditing = function (form) {
        var parts = $scope.deelnemerForModal.geboorteDatum.split("-");
        $scope.deelnemerForModal.geboorteDatum = new Date(
          parts[2],
          parts[1] - 1,
          parts[0]
        );

        if ($scope.deelnemerForModal.tempId) {
          // if new deelnemer, thus without id but with TempId
          var position = _.find($scope.extraDeelnemers, function (e) {
            return e.tempId === $scope.deelnemerForModal.tempId;
          });

          if (position) {
            // if the deelnemer dit already exist in the extradeelnemers list
            // copy it back into its old position
            angular.copy($scope.deelnemerForModal, position);
          } else {
            // if the deelnemer did not already exist in the extradeelnemers list
            // push it into that list
            $scope.extraDeelnemers.push($scope.deelnemerForModal);
          }
        } else {
          if ($scope.deelnemerForModal.betaald) {
            //---------------\/
            angular.copy(
              $scope.deelnemerForModal,
              _.find($scope.deelnemers, function (e) {
                return e.deelnemerId === $scope.deelnemerForModal.deelnemerId;
              })
            );
          } else {
            //----------------------------------------------------\/ see the difference?
            angular.copy(
              $scope.deelnemerForModal,
              _.find($scope.extraDeelnemers, function (e) {
                return e.deelnemerId === $scope.deelnemerForModal.deelnemerId;
              })
            );
          }
        }
      };

      // Share results via e-mail
      $scope.shareResults = function () {
        // $resource(backendBaseUrl + '/reservering/' + $scope.reserveringId + '/shareresults', {} , isArray : true)
        // .save({
        //     "deelnemerIds": $scope.selectedDeelnemers
        // })

        var sharedResults = $resource(
          backendBaseUrl +
            "/reservering/" +
            $scope.reserveringId +
            "/shareresults",
          {},
          {
            save: {
              method: "post",
              isArray: true,
            },
          }
        );

        sharedResults
          .save({
            deelnemerIds: $scope.selectedDeelnemers,
          })
          .$promise.then(
            function succes(response) {
              notificationservice.success(
                $translate.instant("notifications.email_sent")
              );
              $scope.getDeelnemers();
            },
            function error() {
              notificationservice.error(
                $translate.instant("notifications.error_generic")
              );
              // $route.reload();
            }
          );
      };

      /*
       * Sets a deelnemer to edit in the modal. (Begin editing)
       */
      $scope.editDeelnemer = function (deelnemer) {
        $scope.deelnemerForModal = {};
        angular.copy(deelnemer, $scope.deelnemerForModal);
        if (deelnemer.geboorteDatum) {
          $scope.deelnemerForModal.geboorteDatum = moment(
            $scope.deelnemerForModal.geboorteDatum
          ).format("DD-MM-YYYY");
        }
        swangular
          .open({
            title: $translate.instant("toevoegen.titel"),
            htmlTemplate:
              "app/modules/general/modals/deelnemer-edit/deelnemer-edit.view.html",
            showConfirmButton: false,
            customClass: "customModal",
            scope: $scope,
            controller: "ReserveringDeelnemersController",
            allowOutsideClick: false,
          })
          .then(function (result) {
            if (result.value === true) {
              $scope.finishEditing();
            }
          });
      };

      $scope.closeModal = function () {
        swangular.clickCancel();
      };

      $scope.confirmModal = function () {
        swangular.clickConfirm();
      };

      /*
       * Create a new deelnemer to add in the modal.
       */
      $scope.addDeelnemer = function () {
        $scope.deelnemerForModal = {
          tempId: _.random(1, 99999),
        };
        swangular
          .open({
            title: $translate.instant("toevoegen.titel"),
            htmlTemplate:
              "app/modules/general/modals/deelnemer-edit/deelnemer-edit.view.html",
            showConfirmButton: false,
            customClass: "customModal",
            scope: $scope,
            controller: "ReserveringDeelnemersController",
            allowOutsideClick: false,
          })
          .then(function (result) {
            if (result.value === true) {
              $scope.finishEditing();
            }
          });
      };

      /*
       * Calculates price for added deelnemers
       */
      $scope.calculateMutationPrice = function () {
        if ($scope.examenDeelnemerToevoegingPrijsModel) {
          var addedDeelnemersAmount = _.filter($scope.deelnemers, function (d) {
            return d.betaald == false;
          }).length;
          var addedExtraDeelnemersAmount = _.filter(
            $scope.extraDeelnemers,
            function (d) {
              return d.tempId;
            }
          ).length;
          return (
            (addedDeelnemersAmount + addedExtraDeelnemersAmount) *
            $scope.examenDeelnemerToevoegingPrijsModel.prijs *
            (1 + $scope.examenDeelnemerToevoegingPrijsModel.btw)
          );
        }
      };

      $scope.saveDeelnemers = function () {
        var request = $resource(
          backendBaseUrl +
            "/reservering/" +
            $scope.reserveringId +
            "/deelnemers",
          {},
          {
            saveData: {
              method: "PUT",
              isArray: true,
            },
          }
        );

        var model = {};
        model.deelnemers = $scope.deelnemers;
        model.deelnemers = model.deelnemers.concat($scope.extraDeelnemers);

        model.deelnemers = _.filter(model.deelnemers, function (d) {
          return d.achternaam;
        });

        request.saveData({}, model).$promise.then(function success() {
          notificationservice.success(
            $translate.instant("notifications.deelnemers_opgeslagen")
          );

          // for now, we trigger the getnotifications method manually
          notificationservice.getNotifications();

          $rootScope.$broadcast("winkelmandchanged");
        });
      };

      $scope.deleteDeelnemer = function (deelnemer) {
        if (deelnemer.deelnemerId == null) {
          _.pull($scope.extraDeelnemers, deelnemer);
        } else {
          $resource(
            backendBaseUrl +
              "/reservering/" +
              $scope.reserveringId +
              "/deelnemer/" +
              deelnemer.deelnemerId
          )
            .delete({})
            .$promise.then(function succes(result) {
              notificationservice.success(
                $translate.instant("notifications.deelnemer_verwijderd")
              );
              $rootScope.$broadcast("winkelmandchanged");

              _.pull($scope.deelnemers, deelnemer);
            });
        }
      };

      // select all items
      $scope.selectAll = function () {
        angular.forEach($scope.deelnemers, function (deelnemer) {
          deelnemer.selected = $scope.selectedAll;
        });
      };

      // use the array "every" function to test if ALL items are checked
      $scope.checkIfAllSelected = function () {
        $scope.selectedAll = $scope.deelnemers.every(function (deelnemer) {
          return deelnemer.selected == true;
        });
      };

      $scope.checkedCount = function () {
        $scope.selectedDeelnemers = [];
        $("input.deelnemer:checkbox:checked").each(function () {
          $scope.selectedDeelnemers.push(this.id);
        });
        return $scope.selectedDeelnemers.length;
      };

      $scope.onbetaaldeDeelnemers = function () {
        return aanvullenNotificatieService.onbetaaldeDeelnemers(
          $scope.deelnemers
        );
      };

      $scope.deelnemersAanTevullen = function () {
        return aanvullenNotificatieService.deelnemersAanTevullen(
          $scope.deelnemers
        );
      };

      // When deelnemers change, check again if we need to show notification bolletje
      $scope.$watch("$scope.deelnemers", function () {
        $scope.needsAanvullingNotification = aanvullenNotificatieService.needsAanvulling(
          $scope.deelnemers
        );
      });

      $scope.copySuccess = function () {
        notificationservice.success(
          $translate.instant("notifications.klembord")
        );
      };

      $scope.copyToClipboard = function (deelnemer) {
        var string = deelnemer.voorletters;
        if (deelnemer.tussenvoegsel) {
          string = string.concat(" " + deelnemer.tussenvoegsel);
        }

        string = string.concat(" " + deelnemer.achternaam);

        var geboorteDatum = moment(deelnemer.geboorteDatum).format(
          "DD-MM-YYYY"
        );

        string = string.concat(" / " + geboorteDatum);

        string = string.concat(" / " + deelnemer.waarmerk);

        clipboard.copyText(string);
      };

      $scope.showWaarmerkUitleg = function () {
        swangular
          .open({
            title: $translate.instant("waarmerkmodal.titel"),
            htmlTemplate:
              "app/modules/general/modals/waarmerk-info/waarmerk-info.view.html",
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "customModal",
            controller: "ReserveringDeelnemersController",
          })
          .then(function (result) {
            if (result.value) {
              $scope.closeWaarmerkUitleg();
            }
          });
      };

      $scope.closeWaarmerkUitleg = function () {
        $scope.userHasSeenWaarmerkUitleg = true;
        $scope.firstView = false;
        var request = $resource(
          backendBaseUrl + "/userproperties/waarmerkuitlegread/",
          {},
          {
            saveData: {
              method: "PUT",
            },
          }
        );
        request.saveData();
        swangular.close();
      };
    },
  ])
  .filter("nogNietAangevuld", function () {
    return function (input) {
      if (!!input) return input;
      else return "<span class='sr-only'>Gegevens nog niet ingevuld</span>";
    };
  });
