var module = angular
  .module("aanstellingspas.passwordChecker", [])
  .directive("passwordChecker", [
    "$rootScope",
    "$translate",
    function ($rootScope, $translate) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        replace: true,
        scope: false,
        templateUrl:
          "app/modules/directives/passwordchecker/passwordchecker.view.html",
        link: function (scope, element, attrs) {
          scope.checkPassword = function () {
            var password = scope.registerModel.password;
            scope.hasCapital = false;
            scope.hasDigit = false;
            scope.hasLength = false;
            scope.validPassword = false;

            if (/\d/.test(password)) {
              scope.hasDigit = true;
            }
            if (/[A-Z]/.test(password)) {
              scope.hasCapital = true;
            }
            if (password && password.length >= 10) {
              scope.hasLength = true;
            }
            if (
              scope.hasCapital === true &&
              scope.hasDigit === true &&
              scope.hasLength === true
            ) {
              scope.validPassword = true;
            }
            $rootScope.$broadcast("validPasswordEvent", scope.validPassword);
          };
        },
      };
    },
  ]);
