"use strict";

var module = angular.module("aanstellingspas.examenplannen", ["rt.select2"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/examenplannen/", {
    reloadOnSearch: false,
    templateUrl: "app/modules/examenplannen/examenplannen.view.html",
    controller: "ExamenPlannenController",
    title: "titles.examenplannen",
    requireAuth: true,
    navigation_id: "examens",
  });
});

module
  // Overrides function in angular-calendar.
  .config([
    "$provide",
    "calendarConfig",
    function ($provide, calendarConfig) {
      calendarConfig.dateFormatter = "moment"; // use moment to format dates

      $provide.decorator("calendarEventTitle", [
        "$delegate",
        function myServiceDecorator($delegate) {
          function newMonthViewTitle(event) {
            return (
              "Examen in " +
              event.regio +
              " , van " +
              event.startsAt.getHours() +
              " tot " +
              event.endsAt.getHours()
            );
          }

          function regio(event) {
            return event.regio;
          }

          function plaats(event) {
            return event.plaats;
          }

          function tijd(event) {
            return (
              moment(event.startsAt).format("HH:mm") +
              " - " +
              moment(event.endsAt).format("HH:mm")
            );
          }

          function kruispunt(event) {
            return event.kruispunt;
          }

          function aantalLocaties(event) {
            return event.aantalLocaties;
          }

          $delegate.monthView = newMonthViewTitle;
          $delegate.plaats = plaats;
          $delegate.regio = regio;
          $delegate.tijd = tijd;
          $delegate.kruispunt = kruispunt;
          $delegate.aantalLocaties = aantalLocaties;

          return $delegate;
        },
      ]);
    },
  ])

  .controller("ExamenPlannenController", [
    "$scope",
    "$http",
    "$log",
    "BedrijfBvoService",
    "bedrijfsService",
    "$localStorage",
    "notificationservice",
    "$resource",
    "backendBaseUrl",
    "$window",
    "$location",
    "$routeParams",
    "$translate",
    function (
      $scope,
      $http,
      $log,
      bvoService,
      bedrijfService,
      $localStorage,
      notificationService,
      $resource,
      backendBaseUrl,
      $window,
      $location,
      $routeParams,
      $translate
    ) {
      // Create a new Dateobject to use for the navigation
      var today = new Date();
      // if there is a date in the url, use these parameters to create a date at this time
      if ($routeParams.month && $routeParams.year) {
        today = new Date($routeParams.year, $routeParams.month);
      }

      /*
       * Gets called when user presses previous or back buttons on navigation
       * It updates the month and year parameters in the url, and if there is also a regio
       * parameter in the url, it keeps this the same.
       */
      $scope.updateDateParams = function () {
        $location.search("month", $scope.calendarDay.getMonth());
        $location.search("year", $scope.calendarDay.getFullYear());
      };

      /*
       * Gets called whenever the regio filter changes. It changes the regio parameter in the url.
       * If there are existing date filters, it also keeps these the same
       */
      function updateRegioParams() {
        if ($routeParams.month && $routeParams.year) {
          $location.search("month", $routeParams.month);
          $location.search("year", $routeParams.year);
        } else {
          $location.search("regio", $scope.regioFilter);
        }
      }

      // needed for desktop calendar
      $scope.calendarView = "month";
      // Set the navigation date to the previously created date object
      $scope.calendarDay = today;

      /*
       * Redirects user to the selected examenblok, to do more stuff
       */
      $scope.eventClicked = function (calendarEvent) {
        //$location.search({});
        $location.path("/examenreserveren/" + calendarEvent.id);
      };

      $scope.selectedProvincie = null;
      $scope.gemeenteIds = [];

      /*
       * Get all regios from backend. Then, if a filter is present in the url,
       * set the filter to that regio
       */
      $resource(backendBaseUrl + "/plaats")
        .query()
        .$promise.then(
          function success(result) {
            $scope.gemeenten = [];
            var selectedFromUrl = [];

            if ($routeParams.selectedPlaatsen) {
              selectedFromUrl = JSON.parse(
                decodeURIComponent($routeParams.selectedPlaatsen)
              );
            }

            _.forEach(result, function (provincie) {
              var provincieItem = {
                name: provincie.naam,
                id: provincie.id,
                isProvincie: true,
                disabled: true,
              };

              $scope.gemeenten.push(provincieItem);

              _.forEach(provincie.gemeentes, function (gemeente) {
                var gemeenteItem = {
                  name: gemeente.naam,
                  id: gemeente.id,
                  aantalExamens: gemeente.aantalExamens,
                  isProvincie: false,
                  provincieId: provincieItem.id,
                };

                if (_.indexOf(selectedFromUrl, gemeente.id) > -1) {
                  gemeenteItem.selected = true;
                }

                $scope.gemeenten.push(gemeenteItem);
              });
            });

            // set the first provincie as default selected item
            $scope.selectedProvincie = _.find($scope.gemeenten, {
              isProvincie: true,
            });
          },
          function failure(result) {
            if (result.status === 404) {
              notificationService.info($translate.instant("geen_gemeenten"));
              $location.path("/dashboard");
            } else {
              $log.error(result);
            }
          }
        );

      /**
       * Filter to show only events that are in the currently selected(by navigation, based on var today) month and year
       */
      $scope.filterByMonthAndYear = function (date) {
        return (
          date.startsAt.getMonth() === $scope.calendarDay.getMonth() &&
          date.startsAt.getYear() === $scope.calendarDay.getYear()
        );
      };

      /*
       * Function to format a date object into a human readable(and UX friendly) format
       */
      $scope.getBlokTijd = function (event) {
        return (
          moment(event.startsAt).format("HH:mm") +
          " - " +
          moment(event.endsAt).format("HH:mm")
        );
      };

      /*
       * eventDates is een lijst van alle unieke dagen waarop 1 of meerdere examenblokken plaats vinden
       */
      function updateUniqueEventDates() {
        $scope.eventDates = _.uniq($scope.events, function (x) {
          return moment(x.startsAt).format("DD-M-YYYY");
        });
      }

      /*
       * this gets examens from backend when applying gemeente filter, and when switching dates
       */
      $scope.updateEvents = function () {
        $resource(
          backendBaseUrl +
            "/examenblok/" +
            $scope.calendarDay.getFullYear() +
            "/" +
            ($scope.calendarDay.getMonth() + 1),
          null,
          {
            getExamens: {
              method: "POST",
              isArray: true,
            },
          }
        )
          .getExamens({
            plaatsIds: _.chain($scope.gemeenten)
              .filter("selected")
              .map("id")
              .value(),
          })
          .$promise.then(function success(examenblokken) {
            $scope.events = examenblokken;
            $scope.eventsToFilter = examenblokken;
            updateUniqueEventDates();
            // Convert the date string to a javascript date object
            angular.forEach($scope.events, function (value, key) {
              value.startsAt = new Date(value.startsAt);
              value.endsAt = new Date(value.endsAt);
            });
            $scope.showPlaatsSelector = false;
          });
      };

      /* counts how many events occur on a given date */
      $scope.countEvents = function (onDate) {
        return _.filter($scope.events, function (o) {
          if (
            onDate.startsAt.getDate() === o.startsAt.getDate() &&
            onDate.startsAt.getYear() === o.startsAt.getYear() &&
            onDate.startsAt.getMonth() === o.startsAt.getMonth()
          ) {
            return true;
          }

          return false;
        }).length;
      };

      $scope.selectAllGemeenten = function (selectAll, provincieId) {
        _.each($scope.gemeenten, function (gemeente) {
          if (gemeente.provincieId === provincieId) {
            gemeente.selected = selectAll;
          }
        });

        $scope.plaatsSelected();
      };

      $scope.selectNoGemeenten = function () {
        _.each($scope.gemeenten, function (gemeente) {
          gemeente.selected = false;
          gemeente.selectedAll = false;
        });

        $scope.plaatsSelected();
      };

      $scope.selectGemeente = function (id) {
        $scope.selectedProvincie = _.find($scope.gemeenten, { id: id });
      };

      // init
      $scope.matchedPlaatsenCount = 0;
      $scope.updateEvents();

      // calculate the amount of matched event in the modal
      $scope.plaatsSelected = function () {
        var selectedPlaatsen = _.filter($scope.gemeenten, {
          isProvincie: false,
          selected: true,
        });

        // update the url parameters
        var selectedPlaatsIds = [];

        _.forEach(selectedPlaatsen, function (selectedPlaats) {
          selectedPlaatsIds.push(selectedPlaats.id);
        });

        $location.search(
          "selectedPlaatsen",
          encodeURIComponent(JSON.stringify(selectedPlaatsIds))
        );

        $scope.matchedPlaatsenCount = 0;

        _.forEach(selectedPlaatsen, function (plaats) {
          $scope.matchedPlaatsenCount =
            $scope.matchedPlaatsenCount + plaats.aantalExamens;
        });
      };
    },
  ]);
