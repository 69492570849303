"use strict";

var module = angular
  .module("aanstellingspas.werknemersverklaring", [])

  .config(function ($routeProvider) {
    $routeProvider.when("/werknemersverklaring/", {
      templateUrl:
        "app/modules/werknemersverklaring/werknemersverklaring.view.html",
      controller: "WerknemersverklaringController",
      title: "titles.werknemersverklaring",
      hideTitle: false,
      isWerknemersVerklaringPage: true,
    });
  })

  .controller("WerknemersverklaringController", [
    "$scope",
    function ($scope) {
      var today = new Date();
      var day = today.getDate();
      var month = today.getMonth() + 1;
      var year = today.getFullYear();
      $scope.currentDate = day + "-" + month + "-" + year;

      /**
       * generate pdf
       */
      $scope.generatePdf = function () {
        var dateNow = new Date();
        var filename = "werknemersverklaring_" + dateNow.toISOString() + ".pdf";

        // eslint-disable-next-line
        var formElement = document.getElementById("werknemersverklaring-form");

        var options = {
          margin: 50,
          filename: filename,
          html2canvas: { scale: 1 },
          jsPDF: {
            format: "a4",
            unit: "pt",
            orientation: "portrait",
          },
        };

        // create copy for changing layout without changing DOM
        var copyFormElement = angular.copy(formElement);
        copyFormElement.style.width = 900 + "px"; // set pdf page width
        html2pdf().set(options).from(copyFormElement).toPdf().save();
      };
    },
  ]);
