angular.module("validaanstellingsdate", ["ngMessages"]);
angular
  .module("validaanstellingsdate")
  .directive("validaanstellingsdate", function () {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, element, attr, ctrl) {
        function customValidator(ngModelValue) {
          ctrl.$setValidity("validaanstellingsdate", false);
          ctrl.$setValidity("aftertoday", false);
          ctrl.$setValidity("beforefiveyears", false);

          // check if a valid date format was provided
          if (/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/.test(ngModelValue)) {
            ctrl.$setValidity("validaanstellingsdate", true);

            // http://stackoverflow.com/a/2587398/6077862
            var parts = ngModelValue.split("-");

            var year = parts[2];
            var month = parts[1];
            var day = parts[0];

            if (month > 12 || month < 1 || day > 31 || day < 1) {
              ctrl.$setValidity("validaanstellingsdate", false);

              return ngModelValue;
            }

            var date = new Date(year, month - 1, day);
            var tomorrow = moment().add(1, "day");

            if (moment(date) >= tomorrow) {
              ctrl.$setValidity("aftertoday", true);
            }

            var dateFiveYears = new Date();
            var fiveYears = moment(dateFiveYears).add(5, "year");

            if (date < fiveYears) {
              ctrl.$setValidity("beforefiveyears", true);
            }
          }

          return ngModelValue;
        }
        ctrl.$parsers.push(customValidator);
      },
    };
  });
