"use strict";

var module = angular
  .module("aanstellingspas.service.servererrorinterceptorservice", [])
  .factory("serverErrorInterceptorService", [
    "$q",
    "$injector",
    function ($q, $injector) {
      var serverErrorInterceptorService = {};
      var _responseError = function (rejection) {
        //we get $translate and swangular manually here because if we auto-inject it causes a circcular dependency
        var translate = $injector.get("$translate");
        var swangular = $injector.get("swangular");
        if (rejection.status === 500 || rejection.status === 400) {
          swangular.swal({
            title: translate.instant(
              "services.servererrorinterceptorservice.titel"
            ),
            text: translate.instant(
              "services.servererrorinterceptorservice.probeer_nogmaals"
            ),
            type: "error",
          });
        }

        if (rejection.status === 429) {
          swangular.swal({
            title: translate.instant(
              "services.servererrorinterceptorservice.titel"
            ),
            text: translate.instant(
              "services.servererrorinterceptorservice.teveel_berichten"
            ),
            type: "error",
          });
        }

        return $q.reject(rejection);
      };

      serverErrorInterceptorService.responseError = _responseError;

      return serverErrorInterceptorService;
    },
  ]);
