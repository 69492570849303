var module = angular
  .module("aanstellingspas.service.provincieservice", [])
  .factory("provincieService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location
    ) {
      var provincieServiceInstance = {};

      /**
       * Create a new provi
       * @param  {string} email               email
       * @param  {string} contactpersoonnaam  contactpersoonnaam
       * @param  {string} provincienaam       provincienaam
       * @param  {string} afdeling            afdeling
       * @param  {string} huisnummer          huisnummer
       * @param  {string} huisnummertoevoeging
       * @param  {string} plaats              plaats
       * @param  {string} vestigingsplaats    vestigingsplaats
       * @param  {string} postcode            postcode
       * @param  {string} straat              straat
       * @param  {string} telefoonnummer      telefoonnummer
       * @param  {string} password            password
       * @param  {bool}   consent             consent
       */

      provincieServiceInstance.createProvincie = function (
        email,
        contactpersoonnaam,
        provincieNaam,
        afdeling,
        huisnummer,
        huisnummertoevoeging,
        plaats,
        vestigingsplaats,
        postcode,
        straat,
        telefoonnummer,
        password,
        consent
      ) {
        return $resource(backendBaseUrl + "/provincie").save({
          email: email,
          contactpersoonnaam: contactpersoonnaam,
          password: password,
          telefoonnummer: telefoonnummer,
          provincieNaam: provincieNaam,
          huisnummer: huisnummer,
          huisnummertoevoeging: huisnummertoevoeging,
          plaats: plaats,
          vestigingsplaats: vestigingsplaats,
          postcode: postcode,
          afdeling: afdeling,
          straat: straat,
          consent: consent,
          preferredLanguage: $localStorage.temporaryLanguage,
        });
      };

      provincieServiceInstance.getProvincieResourceForUser = function (userId) {
        return $resource(backendBaseUrl + "/user/" + userId + "/bedrijf").get()
          .$promise;
      };

      provincieServiceInstance.getBestellingen = function (provincieId) {
        return $resource(
          backendBaseUrl + "/bedrijfbestellingen/" + provincieId
        ).get().$promise;
      };

      provincieServiceInstance.getProvincieResourceForThisUser = function () {
        return this.getProvincieResourceForUser(
          $localStorage.authticket.user_id
        );
      };

      return provincieServiceInstance;
    },
  ]);
