var module = angular
  .module("aanstellingspas.directives", [])

  .directive("numbersOnly", function () {
    return {
      require: "ngModel",
      scope: true,
      restrict: "A",
      link: function (scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          if (text) {
            var transformedInput = text.replace(/[^0-9]/g, "");

            if (transformedInput !== text) {
              ngModelCtrl.$setViewValue(transformedInput);
              ngModelCtrl.$render();
            }
            return transformedInput;
          }
          return undefined;
        }
        ngModelCtrl.$parsers.push(fromUser);
      },
    };
  })

  .directive("passwordMatch", [
    function () {
      return {
        restrict: "A",
        scope: true,
        require: "ngModel",
        link: function (scope, elem, attrs, control) {
          var checker = function () {
            //get the value of the first password
            var e1 = scope.$eval(attrs.ngModel);

            //get the value of the other password
            var e2 = scope.$eval(attrs.passwordMatch);
            return e1 == e2;
          };
          scope.$watch(checker, function (n) {
            //set the form control to valid if both
            //passwords are the same, else invalid
            control.$setValidity("pattern", n);
          });
        },
      };
    },
  ]);
