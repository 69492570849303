"use strict";

var module = angular
  .module("aanstellingspas.landingpage", ["ngMessages"])

  .config(function ($routeProvider) {
    $routeProvider.when("/landingpage", {
      templateUrl: "app/modules/landingpage/landingpage.view.html",
      controller: "LandingPageController",
      title: "titles.landingpage",
      hideTitle: true,
      requireAuth: true,
    });
  })
  .config(function ($routeProvider) {
    $routeProvider.when("/aansluitingLandingpage", {
      templateUrl: "app/modules/landingpage/aansluitingLandingpage.view.html",
      controller: "AansluitingLandingPageController",
      title: "titles.landingpage",
      hideTitle: true,
      requireAuth: true,
    });
  })

  .config(function ($routeProvider) {
    $routeProvider.when("/landingpage/banktransfer", {
      templateUrl:
        "app/modules/landingpage/bankoverschrijvinglandingpage.view.html",
      controller: "LandingPageBankTransferController",
      title: "titles.landingpage",
      hideTitle: true,
      requireAuth: true,
    });
  })

  .controller("LandingPageController", [
    "$resource",
    "$scope",
    "backendBaseUrl",
    "$routeParams",
    "$interval",
    "$location",
    "notificationservice",
    "$translate",
    function (
      $resource,
      $scope,
      backendBaseUrl,
      $routeParams,
      $interval,
      $location,
      notificationService,
      $translate
    ) {
      $scope.showSpinner = true;

      var amount = 0;

      $scope.bestellingId = $routeParams.bestellingId;

      $scope.factuuremailadres;

      $resource(backendBaseUrl + "/user/factuuremailadres")
        .get()
        .$promise.then(
          function success(result) {
            $scope.factuuremailadres = result.factuuremailadres;
          },
          function failure(result) {
            $log.error(result);
          }
        );

      $scope.startInterval = function () {
        $scope.model = {};

        //Initialize the Timer to run every 1000 milliseconds i.e. one second.
        $scope.Timer = $interval(function () {
          amount++;

          if (amount == 20) {
            $location.search("bestellingId", null);
            $location.path("/dashboard");
            notificationService.info(
              $translate.instant("notification_timeout")
            );
            $interval.cancel($scope.Timer);
          }

          $resource(
            backendBaseUrl +
              "/bestelling/betalingsstatus/" +
              $scope.bestellingId
          )
            .get()
            .$promise.then(
              function success(result) {
                $scope.model = result;
                if (
                  $scope.model.betalingsStatus != "Open" &&
                  $scope.model.betalingsStatus != "In behandeling"
                ) {
                  $interval.cancel($scope.Timer);
                  $scope.showSpinner = false;
                }
              },
              function failure(result) {
                $interval.cancel($scope.Timer);
              }
            );
        }, 1000);
      };

      if ($scope.bestellingId == undefined) {
        $location.path("/#/dashboard");
      } else {
        $scope.startInterval();
      }
    },
  ])

  .controller("LandingPageBankTransferController", [
    "$resource",
    "$scope",
    "backendBaseUrl",
    "$routeParams",
    "$interval",
    "$location",
    "$window",
    "notificationservice",
    "downloadfileService",
    "$translate",
    function (
      $resource,
      $scope,
      backendBaseUrl,
      $routeParams,
      $interval,
      $location,
      $window,
      notificationService,
      downloadfileService,
      $translate
    ) {
      $scope.showSpinner = true;

      var amount = 0;

      $scope.bestellingId = $routeParams.bestellingId;

      $scope.factuuremailadres;

      $resource(backendBaseUrl + "/user/factuuremailadres")
        .get()
        .$promise.then(
          function success(result) {
            $scope.factuuremailadres = result.factuuremailadres;
          },
          function failure(result) {
            $log.error(result);
          }
        );

      $scope.startInterval = function () {
        $scope.model = {};

        //Initialize the Timer to run every 1000 milliseconds i.e. one second.
        $scope.Timer = $interval(function () {
          amount++;

          if (amount == 20) {
            $location.search("bestellingId", null);
            $location.path("/dashboard");
            notificationService.info(
              $translate.instant("notification_timeout")
            );
            $interval.cancel($scope.Timer);
            return;
          }

          $resource(
            backendBaseUrl +
              "/bestelling/betalingsstatus/bankoverschrijving/" +
              $scope.bestellingId
          )
            .get()
            .$promise.then(
              function success(result) {
                $scope.model = result;

                if (
                  $scope.model.referentienummer != null &&
                  $scope.model.factuurId != null &&
                  $scope.model.factuurId != 0
                ) {
                  $interval.cancel($scope.Timer);
                  $scope.showSpinner = false;
                }
              },
              function failure(result) {
                $interval.cancel($scope.Timer);
              }
            );
        }, 1000);
      };

      if ($scope.bestellingId == undefined) {
        $location.path("/#/dashboard");
      } else {
        $scope.startInterval();
      }

      $scope.downloadFile = function (factuurId) {
        downloadfileService.downloadFactuur(factuurId);
      };
    },
  ])
  .controller("AansluitingLandingPageController", [
    "$translate",
    function ($translate) {},
  ]);
