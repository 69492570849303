"use strict";

// eslint-disable-next-line angular/module-setter
var module = angular.module(
  "aanstellingspas.profile-medewerker-uitnodigen",
  []
);

module.config(function ($routeProvider) {
  $routeProvider.when("/profile/medewerker-uitnodigen", {
    controller: "ProfileMedewerkerUitnodigenController",
    templateUrl:
      "app/modules/profile-medewerker-uitnodigen/profile-medewerker-uitnodigen.view.html",
    requireAuth: true,
    title: "titles.my_settings",
    navigation_id: "profile",
  });
});

module.controller("ProfileMedewerkerUitnodigenController", [
  "$scope",
  "$rootScope",
  "bedrijfsService",
  "notificationservice",
  "authService",
  "$translate",
  function (
    $scope,
    $rootScope,
    bedrijfsService,
    notificationService,
    authService,
    $translate
  ) {
    /*
     * checks via the backend if a username is available
     */
    $scope.checkemailaddressAvailable = function () {
      var emailaddress = $scope.emailAddress;
      if (!_.isEmpty(emailaddress)) {
        authService.emailaddressAvailable(emailaddress).$promise.then(
          function (result) {
            // set the validity of the 'unique' validator on the username form fields
            // 'unique' is a custom validation that gets 'spawned' here
            $scope.inviteForm.emailAddress.$setValidity(
              "unique",
              result.available
            );
          },
          function failure() {}
        );
      }
    };

    //send the email
    $scope.sendEmail = function () {
      bedrijfsService.inviteEmployee($scope.emailAddress).$promise.then(
        function success() {
          notificationService.success(
            $translate.instant("medewerker_uitnodigen.notificatie.success")
          );
          // Clear form after successfully submitting
          $scope.emailAddress = "";
          $scope.inviteForm.$setPristine(true);
        },
        function failure(result) {
          notificationService.error(
            $translate.instant("medewerker_uitnodigen.notificatie.error")
          );
          $log.error(result);
        }
      );
    };
  },
]);
