var module = angular.module("aanstellingspas.examenreserveren", ["rt.select2"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/examenreserveren/:examenid", {
    templateUrl: "app/modules/examenreserveren/examenreserveren.view.html",
    controller: "ExamenReserverenController",
    title: "titles.examenreserveren",
    navigation_id: "examens",
  });
});

module.controller("ExamenReserverenController", [
  "$scope",
  "$resource",
  "backendBaseUrl",
  "$log",
  "$window",
  "$location",
  "$anchorScroll",
  "$timeout",
  "authService",
  "$rootScope",
  "bedrijfsService",
  "$routeParams",
  "notificationservice",
  "$translate",
  "swangular",
  function (
    $scope,
    $resource,
    backendBaseUrl,
    $log,
    $window,
    $location,
    $anchorScroll,
    $timeout,
    authService,
    $rootScope,
    bedrijfsService,
    $routeParams,
    notificationService,
    $translate,
    swangular
  ) {
    $scope.selectedKruispunt = null;
    $scope.examenblokid = $routeParams.examenid;
    $scope.examenblok = {};
    $scope.kruispunten = [];
    $scope.plaatsen = [];
    $scope.model = {};
    $scope.gereserveerd = false;
    $scope.tijd = "";
    $scope.deelnemerstoevoegenradio = "false";

    $scope.aantal = [
      { id: "4", text: "4 deelnemers (minimum)" },
      { id: "5", text: "5 deelnemers" },
      { id: "6", text: "6 deelnemers" },
      { id: "7", text: "7 deelnemers" },
      { id: "8", text: "8 deelnemers" },
    ];
    $scope.model.aantalDeelnemers = 4;
    $scope.examenblokPrijs = "";
    $scope.deelnemerPrijs = "";
    $scope.prijs = "";
    $scope.calculatePrijs = function () {
      return (
        $scope.examenblokPrijs +
        ($scope.model.aantalDeelnemers - 4) * $scope.deelnemerPrijs
      );
    };
    $scope.$watch("model.aantalDeelnemers", function () {
      $scope.prijs = $scope.calculatePrijs();
    });

    $resource(backendBaseUrl + "/examenblok/:examenid/aanvulling")
      .get({
        examenid: $scope.examenblokid,
      })
      .$promise.then(
        function success(result) {
          $scope.examenblok = result;
          $scope.tijd =
            moment($scope.examenblok.startsAt).format("HH:mm") +
            " - " +
            moment($scope.examenblok.endsAt).format("HH:mm");
          $scope.examenblokPrijs = result.examenblokPrijs;
          $scope.deelnemerPrijs = result.deelnemerPrijs;
          $scope.prijs = $scope.calculatePrijs();
          //get the plaatsen for the regio this examenblok is in
          $resource(backendBaseUrl + "/examenblok/:examenblokId/plaatsen")
            .query({
              examenblokId: $scope.examenblokid,
            })
            .$promise.then(function success(result) {
              $scope.plaatsen = result;
            });
        },
        function failure(result) {
          if (result.status === 410) {
            $log.error("Examenblok is niet meer beschikbaar");
            notificationService.warn(
              $translate.instant("notifications.unavailable")
            );
            $window.history.back();
          } else if (result.status === 404) {
            $log.error("Examenblok niet gevonden");

            notificationService.warn(
              $translate.instant("notifications.unavailable")
            );
            $window.history.back();
          } else if (result.status === 409) {
            $log.error("Examenblok al in bestelling");
            swangular.swal({
              title: $translate.instant(
                "notifications.al_in_winkelmand_swal.title"
              ),
              text: $translate.instant(
                "notifications.al_in_winkelmand_swal.text"
              ),
              type: "warning",
              confirmButtonText: $translate.instant(
                "notifications.al_in_winkelmand_swal.confirm"
              ),
              cancelButtonText: $translate.instant(
                "notifications.al_in_winkelmand_swal.cancel"
              ),
              allowOutsideClick: false,
              allowEscapeKey: false,

              preConfirm: function confirmAction(confirmed) {
                if (confirmed == true) {
                  $location.path("/winkelmand");
                  $scope.$apply();
                } else {
                  $window.history.back();
                }
              },
            });
          } else {
            $log.error("Examenblok kon niet worden opgehaald", result);
          }
        }
      );
    $scope.reserveren = function () {
      $resource(
        backendBaseUrl + "/examenblok/:examenid/reservering",
        {
          examenid: $scope.examenblokid,
        },
        {
          post: { method: "POST" },
        }
      )
        .post($scope.model)
        .$promise.then(
          function success() {
            $scope.gereserveerd = true;
            $rootScope.$broadcast("winkelmandchanged");
          },
          function failure(result) {
            if (result.status === 410) {
              $log.error("Examenblok is niet meer beschikbaar");
              notificationService.warn(
                $translate.instant("notifications.unavailable")
              );
              $window.history.back();
            }
            if (result.status === 409) {
              $log.error("Kruispunt is niet beschikbaar");
              notificationService.error(
                $translate.instant("notifications.kruispunt_unavailable")
              );
            }
          }
        );
    };
    /*
     *triggered when a plaats has been selected. Updates list of kruispunten.
     */
    $scope.plaatsUpdated = function () {
      $resource(
        backendBaseUrl + "/examenblok/:examenblokid/plaats/:plaatsid/kruispunt",
        {
          plaatsid: $scope.model.plaatsId,
          examenblokid: $scope.examenblokid,
        },
        {}
      )
        .query()
        .$promise.then(function success(result) {
          $scope.kruispunten = result;
        });
    };

    $scope.selectKruispunt = function (id) {
      $scope.selectedKruispunt = _.find($scope.kruispunten, { id: id });
    };
  },
]);
