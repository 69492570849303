"use strict";

var module = angular
  .module("aanstellingspas.aanvragen", ["smart-table"])

  .config(function ($routeProvider) {
    $routeProvider.when("/aanvragen", {
      templateUrl: "app/modules/aanvragen/aanvragen.view.html",
      controller: "AanvragenController",
      title: "titles.mijn_aanvragen",
      navigation_id: "pasaanvraag",
    });
  })

  .controller("AanvragenController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$log",
    "$location",
    "bedrijfsService",
    "$localStorage",
    "$rootScope",
    "$translate",
    function (
      $scope,
      $resource,
      backendBaseUrl,
      $log,
      $location,
      bedrijfService,
      $localStorage,
      $rootScope,
      $translate
    ) {
      //reserveringen from the get request
      $scope.aanvragen = [];

      //the displayed and filtered reserveringen
      $scope.displayedAanvragen = [];

      //inital state for select2
      $scope.selectedStatus = "ALLES";

      bedrijfService
        .getBedrijfResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          //gets all reserveringen for bedrijf
          $resource(backendBaseUrl + "/bedrijf/" + result.id + "/aanvragen")
            .query()
            .$promise.then(
              function success(result) {
                $scope.aanvragen = result;

                _.forEach($scope.aanvragen, function (aanvraag) {
                  aanvraag.friendlyStatus = $scope.getFriendlyStatus(
                    aanvraag.status
                  );
                });
                $scope.aanvragen = _.sortByOrder(
                  $scope.aanvragen,
                  "aanvraagDatum",
                  "desc"
                );
              },
              function failure(result) {
                $log.error(result);
              }
            );
        });
      $translate("mijn_aanvragen.status.all").then(function () {
        $scope.status = [
          {
            id: "ALLES",
            text: $translate.instant("mijn_aanvragen.status.all"),
          },
          {
            id: "Ingediend",
            text: $translate.instant("mijn_aanvragen.status.ingediend"),
          },
          {
            id: "Goedgekeurd",
            text: $translate.instant("mijn_aanvragen.status.goedgekeurd"),
          },
          {
            id: "Afgekeurd",
            text: $translate.instant("mijn_aanvragen.status.afgekeurd"),
          },
          {
            id: "DefinitiefAfgekeurd",
            text: $translate.instant(
              "mijn_aanvragen.status.definitiefafgekeurd"
            ),
          },
          {
            id: "Verzonden",
            text: $translate.instant("mijn_aanvragen.status.verzonden"),
          },
          {
            id: "NogNietBetaald",
            text: $translate.instant("mijn_aanvragen.status.nognietbetaald"),
          },
        ];
      });

      //gets a displayable string for some of the statusses
      $scope.getFriendlyStatus = function (status) {
        if (status === "Ingediend") {
          return $translate.instant("mijn_aanvragen.status.ingediend");
        }
        if (status === "Gecorrigeerd") {
          return $translate.instant("mijn_aanvragen.status.ingediend");
        }
        if (status === "DefinitiefAfgekeurd") {
          return $translate.instant(
            "mijn_aanvragen.status.definitiefafgekeurd"
          );
        }
        if (status === "NogNietBetaald") {
          return $translate.instant("mijn_aanvragen.status.nognietbetaald");
        } else {
          return status;
        }
      };

      //gets a displayable string for each aanvraagtype
      $scope.getFriendlyType = function (aanvraagType) {
        if (aanvraagType === "EersteAanvraag") {
          return $translate.instant("mijn_aanvragen.soort.nieuwe_aanvraag");
        } else if (aanvraagType === "Duplicaat") {
          return $translate.instant("mijn_aanvragen.soort.duplicaat");
        } else if (aanvraagType === "OmzettingBijplaatsing") {
          return $translate.instant(
            "mijn_aanvragen.soort.omzettingBijplaatsing"
          );
        } else {
          return $translate.instant("mijn_aanvragen.soort.verlenging");
        }
      };

      //go to the detail view of examen
      $scope.selectDetails = function (reserveringId) {
        $location.path("/reservering/" + reserveringId);
      };

      //go to the deelnemer view of examen
      $scope.selectDeelnemers = function (reserveringId) {
        $location.path("/reservering/" + reserveringId + "/deelnemers");
      };

      $scope.goToVerbeteren = function (aanvraag) {
        var subType = "";
        switch (aanvraag.subType) {
          case "EersteAanvraag":
            subType = "eerste";
            break;
          case "Verlenging":
            subType = "verlenging";
            break;
          case "Duplicaat":
            subType = "duplicaat";
            break;
          case "OmzettingBijplaatsing":
            subType = "omzetting";
            break;
          default:
            alert("Subtype niet bekend. Fix me @ aanvragen.module.js");
            return;
        }

        if ($rootScope.isInRole("gemeente")) {
          $location.path(
            "/pasaanvraag-" +
              subType +
              "-gemeente/" +
              aanvraag.type +
              "/" +
              aanvraag.id
          );
        } else {
          $location.path(
            "/pasaanvraag-" + subType + "/" + aanvraag.type + "/" + aanvraag.id
          );
        }
      };

      $scope.getAanvraagStatusTranslation = function (status) {
        return $translate.instant(
          "mijn_aanvragen.status." + status.toLowerCase()
        );
      };

      // Do some formatting on select2 options
      $scope.s2_status_options = {
        language: "nl",
        placeholder: $translate.instant("mijn_aanvragen.zoeken_op_naam"),
        formatSelection: function (status) {
          return (
            $translate.instant("mijn_aanvragen.filter_title") +
            " " +
            "<strong>" +
            status.text.toLowerCase() +
            "</strong>"
          );
        },
        allowClear: false,
        //don't display the searchbar
        minimumResultsForSearch: -1,
      };

      $scope.orderBy = function (column) {
        // if the currently sorted column was clicked again
        if ($scope.currentOrder === column) {
          if ($scope.currentOrderDirection === "desc") {
            $scope.currentOrderDirection = "asc";
          } else {
            $scope.currentOrderDirection = "desc";
          }
        } else {
          $scope.currentOrder = column;
          $scope.currentOrderDirection = "asc";
        }

        $scope.aanvragen = _.sortByOrder(
          $scope.aanvragen,
          column,
          $scope.currentOrderDirection
        );
      };
    },
  ]);
