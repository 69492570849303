var module = angular
  .module("aanstellingspas.service.notificationservice", [])
  .factory("notificationservice", [
    "lrNotifier",
    "backendBaseUrl",
    "$sce",
    "$rootScope",
    "$resource",
    function (lrNotifier, backendBaseUrl, $sce, $rootScope, $resource) {
      var notificationServiceInstance = {};

      // for now, the only channel is notification.
      // later, this might become a variable if we need more channels
      var channel = lrNotifier("notification");

      /*
       * Spawns info message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.info = function (message) {
        channel.info($sce.trustAsHtml(message));
      };

      /*
       * Spawns warn message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.warn = function (message) {
        channel.warn($sce.trustAsHtml(message));
      };

      /*
       * Spawns error message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.error = function (message) {
        channel.error($sce.trustAsHtml(message));
      };

      /*
       * Spawns success message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.success = function (message) {
        channel.success($sce.trustAsHtml(message));
      };

      notificationServiceInstance.getAanvragenNotifications = function () {
        $resource(backendBaseUrl + "/notification/aanvragennotifications")
          .get()
          .$promise.then(
            function success(result) {
              $rootScope.aanvraagNotifications = result.aantal;
            },
            function failure(result) {}
          );
      };

      notificationServiceInstance.getExamenNotifications = function () {
        $resource(backendBaseUrl + "/notification/examennotifications")
          .get()
          .$promise.then(
            function success(result) {
              $rootScope.examenNotifications = result.aantal;
            },
            function failure(result) {}
          );
      };

      notificationServiceInstance.getNotifications = function () {
        this.getAanvragenNotifications();
        this.getExamenNotifications();
      };

      return notificationServiceInstance;
    },
  ]);
