var module = angular
  .module("aanstellingspas.filters.getextention", [])

  .filter("getextention", function () {
    return function (filename) {
      if (!!filename) {
        return filename.substr(filename.lastIndexOf(".") + 1);
      }
    };
  });
