var module = angular
  .module("aanstellingspas.service.winkelmandservice", [])
  .factory("winkelmandService", [
    "backendBaseUrl",
    "$resource",
    "$rootScope",
    "$log",
    "$timeout",
    "$location",
    function (
      backendBaseUrl,
      $resource,
      $rootScope,
      $log,
      $timeout,
      $location
    ) {
      var winkelmandServiceInstance = {};

      winkelmandServiceInstance.hasWinkelmandItems = function () {
        return $resource(backendBaseUrl + "/winkelmand").get().$promise;
      };
      return winkelmandServiceInstance;
    },
  ]);
