var module = angular
  .module("aanstellingspas.language-selection", [])
  .directive("languageSelection", [
    function () {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        replace: true,
        templateUrl:
          "app/modules/directives/language-selection/language-selection.view.html",
        scope: false,
        link: function (scope, element, attrs) {},
      };
    },
  ]);
