var module = angular
  .module("aanstellingspas.benodigdedocumenten", ["ngSanitize"])
  .directive("benodigdeDocumenten", [
    "Upload",
    "$timeout",
    "$resource",
    "$localStorage",
    "$rootScope",
    "$translate",
    function (
      Upload,
      $timeout,
      $resource,
      $localStorage,
      $rootScope,
      $translate
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: false,
        templateUrl:
          "app/modules/directives/benodigde-documenten/benodigde-documenten.html",
        link: function (scope, element, attrs) {
          scope.type = attrs.type;

          scope.checkIfShowAgain = function (value) {
            if ($localStorage["skip-" + value]) {
              $rootScope.$broadcast("hide-intro");
              return false;
            } else {
              $rootScope.$broadcast("show-intro");
              scope.showIntro = true;
              return true;
            }
          };

          /*
           * Save the 'do not show again' preference for beroepspas wizard to localstorage
           */
          scope.dontShowAgain = function (property, value) {
            $localStorage["skip-" + property] = value;
          };

          scope.nextButtonPressed = function () {
            scope.showIntro = false;
            $rootScope.$broadcast("hide-intro");
          };

          scope.checkIfShowAgain(scope.type);
        },
      };
    },
  ]);
