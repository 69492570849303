var module = angular
  .module("aanstellingspas.service.authservice", [])
  .factory("authService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    "$translate",
    "swangular",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location,
      $translate,
      swangular
    ) {
      var authServiceInstance = {};

      /**
       * @return {boolean} whether or not the user is logged in
       */
      authServiceInstance.loggedin = function () {
        if ($localStorage.authticket) {
          $log.debug("authticket found in localstorage");
        } else {
          $log.debug("no authticket found in localstorage");

          return false;
        }

        // if the token is expired
        if (
          $localStorage.authticket.expires_at > Math.round(new Date() / 1000)
        ) {
          return true;
        } else {
          $log.debug("authticket expired");
          this.destroyAuthTicket();
          $translate("services.authservice.sessie_verlopen.titel").then(
            function (translation) {
              // redirect to login page, maybe show the user a message?
              swangular.swal({
                title: $translate.instant(
                  "services.authservice.sessie_verlopen.titel"
                ),
                text: $translate.instant(
                  "services.authservice.sessie_verlopen.tekst"
                ),
                type: "error",
                confirmButtonText: $translate.instant(
                  "services.authservice.sessie_verlopen.inloggen"
                ),
                allowOutsideClick: false,
                allowEscapeKey: false,

                preConfirm: function confirmAction(confirmed) {
                  $location.path("/login");
                },
              });
            }
          );
          return false;
        }

        return false;
      };

      /**
       * Logs the user in and sets the authentication ticket in the localstorage.
       * notifies via 'swal()' if something went wrong (or right)
       * @param  {string} username
       * @param  {string} password
       * @param  {string} redirectTo redirects user to route of choice, falls back to /home by default
       */
      authServiceInstance.login = function (emailadres, password, redirectTo) {
        $http({
          url: backendBaseUrl + "/newauthtoken",
          method: "POST",
          data: {
            client_id: "CustomerFrontend",
            grant_type: "password",
            username: emailadres,
            password: password,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Accept: "application/json, text/plain, */*",
          },
          transformRequest: function (obj) {
            var str = [];
            for (var p in obj)
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
              );
            return str.join("&");
          },
        }).then(
          function (received_authticket) {
            $localStorage.authticket = {};
            $localStorage.authticket.token =
              received_authticket.data.access_token;
            // set the expiration date in seconds since epoch (http://www.epochconverter.com/)
            $localStorage.authticket.expires_at =
              Math.round(new Date() / 1000) +
              received_authticket.data.expires_in;
            $localStorage.authticket.user_id =
              received_authticket.data[".userid"];
            $localStorage.authticket.roles = received_authticket.data[".roles"];

            $log.debug("token retrieved and set, user logged in");

            // get additional properties that are not directly related to authentication
            var userId = $localStorage.authticket.user_id;

            $resource(backendBaseUrl + "/user/:userid/properties")
              .get({
                userid: userId,
              })
              .$promise.then(function (properties) {
                // construct an userproperties model and save it to local storage
                // this model will be retrieved and set to the view when the app starts
                $localStorage.userproperties = {};

                $localStorage.userproperties.emailaddress =
                  properties.emailaddress;
                $localStorage.userproperties.realName = properties.realName;
                $localStorage.userproperties.roles = properties.roles;
                $localStorage.userproperties.preferredLanguage =
                  properties.preferredLanguage;
                $localStorage.temporaryLanguage = properties.preferredLanguage;
                // because we are not restarting the app, set the properties now!

                $rootScope.userproperties = $localStorage.userproperties;
                $rootScope.$broadcast("userpropertieschanged");

                $rootScope.loggedin = true;
                $rootScope.$broadcast("authorizationchanged");

                // redirect
                $location.url(redirectTo);

                // clear url parameters after redirecting
                $location.search("redirectTo", null);
              });
          },
          function (error) {
            // unserialize the error description (Yes, we have embedded json in json :')
            var error_description = angular.fromJson(
              error.data.error_description
            );

            // check some common error scenarios

            // email not confirmed yet
            if (
              error.data.error === "invalid_grant" &&
              error_description.reason === "email_notconfirmed"
            ) {
              swangular
                .swal({
                  confirmButtonText: $translate.instant(
                    "services.authservice.email_bevestigen.verstuur_knop"
                  ),
                  cancelButtonText: "Terug",
                  showCancelButton: true,
                  title: $translate.instant(
                    "services.authservice.email_bevestigen.titel"
                  ),
                  text: $translate.instant(
                    "services.authservice.email_bevestigen.tekst"
                  ),
                  type: "info",
                })
                .then(function (result) {
                  if (result.value) {
                    authServiceInstance.resendEmailVerification();
                  }
                });

              return;
            }

            //invalid username/password
            if (
              error.data.error === "invalid_grant" &&
              error_description.reason === "invalid_credentials"
            ) {
              swangular.swal({
                title: $translate.instant(
                  "services.authservice.incorrect.titel"
                ),
                text: $translate.instant(
                  "services.authservice.incorrect.tekst"
                ),
                type: "error",
              });

              return;
            }

            // account disabled
            if (
              error.data.error === "invalid_grant" &&
              error_description.reason === "account_disabled"
            ) {
              swangular.swal({
                title: $translate.instant(
                  "services.authservice.gedeactiveerd.titel"
                ),
                text: $translate.instant(
                  "services.authservice.gedeactiveerd.tekst"
                ),
                type: "error",
              });

              return;
            }

            //secretariaatrol
            if (
              error.data.error === "invalid_credentials" &&
              error_description.reason === "not_authorized"
            ) {
              swangular.swal({
                title: $translate.instant(
                  "services.authservice.incorrect.titel"
                ),
                text: $translate.instant(
                  "services.authservice.incorrect.tekst"
                ),
                type: "error",
              });

              return;
            }

            // account locked out
            if (
              error.data.error === "invalid_grant" &&
              error_description.reason === "account_lockedout"
            ) {
              var minutes = parseInt(error_description.lockoutEndDate / 60, 10);
              var seconds = error_description.lockoutEndDate % 60;

              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              if (seconds < 10) {
                seconds = "0" + seconds;
              }

              var timeout = minutes + ":" + seconds;

              swangular.swal({
                title: $translate.instant(
                  "services.authservice.mislukte_pogingen.titel"
                ),
                text:
                  $translate.instant(
                    "services.authservice.mislukte_pogingen.tekst_voor"
                  ) +
                  timeout +
                  $translate.instant(
                    "services.authservice.mislukte_pogingen.tekst_na"
                  ),
                type: "error",
              });

              return;
            }

            $log.error("failed to login: ", error);

            swangular.swal({
              title: $translate.instant("services.authservice.fout.titel"),
              text: $translate.instant("services.authservice.fout.tekst"),
              type: "error",
            });

            return;
          }
        );
      };

      /**
       * logs the user out by removing the authticket
       */
      authServiceInstance.logout = function () {
        this.destroyAuthTicket();
        $rootScope.loggedin = false;
        $rootScope.$broadcast("authorizationchanged");
        $rootScope.$broadcast("loggedout");
      };

      /**
       * Destroys the local authentication ticket, thereby effectively
       * logging the user out
       */
      authServiceInstance.destroyAuthTicket = function () {
        $log.debug("destroying authticket");

        delete $localStorage.authticket;
      };

      /**
       * Checks if a username is available for registration
       * @param  {string} username the username to be checked
       * @return {promise}          the resulting promise
       */
      authServiceInstance.emailaddressAvailable = function (emailaddress) {
        return $resource(backendBaseUrl + "/emailaddressavailabilitycheck", {
          emailaddress: "@emailaddress",
        }).get({
          emailaddress: emailaddress,
        });
      };

      /**
       * Get a user by username
       * @param  {string} username     username
       */
      authServiceInstance.getUserByUsername = function (emailaddress) {
        return $resource(backendBaseUrl + "/usercontroller", {
          emailaddress: "@emailaddress",
        }).get({
          emailaddress: emailaddress,
        });
      };

      /**
       * Register a new user in the backend
       * @param  {string} emailaddress emailaddress
       * @param  {string} username     username
       * @param  {string} password     password
       */
      authServiceInstance.registerUser = function (
        emailaddress,
        username,
        password
      ) {
        return $resource(backendBaseUrl + "/accountregistration").save({
          email: emailaddress,
          username: username,
          password: password,
        });
      };

      /**
       * Get user roles based on localstorage
       */
      authServiceInstance.getCurrentUserRoles = function () {
        if ($localStorage.authticket) {
          var roles = $localStorage.authticket.roles;

          return JSON.parse(roles);
        } else {
          return false;
        }
      };

      /**
       * Checks if current logged in user has role
       */
      authServiceInstance.isInRole = function (role) {
        return _.contains(this.getCurrentUserRoles(), role);
      };

      /**
       * Checks if the current logged in user has secretariaat role
       * Otherwise, redirected to dashboard
       */
      authServiceInstance.checkIsSecretariaat = function () {
        if (this.isInRole("secretariaat")) {
          return;
        } else {
          alert(
            $translate.instant("services.authservice.geen_secretariaatsrol")
          );
          $location.path("/dashboard");
        }
      };

      // /**
      //  * resend email verification mail
      //  */
      authServiceInstance.resendEmailVerification = function () {
        $location.path("/resendemailverification");
      };

      return authServiceInstance;
    },
  ]);
