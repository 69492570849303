angular.module("validbirthdate", ["ngMessages"]);
angular.module("validbirthdate").directive("validbirthdate", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attr, ctrl) {
      function customValidator(ngModelValue) {
        ctrl.$setValidity("validbirthdateformat", false);
        ctrl.$setValidity("plausiblebirthdate", false);
        ctrl.$setValidity("eighteenplus", false);

        // check if a valid date format was provided
        if (/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/.test(ngModelValue)) {
          ctrl.$setValidity("validbirthdateformat", true);

          // http://stackoverflow.com/a/2587398/6077862
          var parts = ngModelValue.split("-");

          var year = parts[2];
          var month = parts[1];
          var day = parts[0];

          if (month > 12 || month < 1 || day > 31 || day < 1) {
            ctrl.$setValidity("validbirthdateformat", false);

            return ngModelValue;
          }

          var date = new Date(year, month - 1, day);

          if (date > new Date(1850, 0, 0)) {
            ctrl.$setValidity("plausiblebirthdate", true);
          }

          var dateEighteenYearsAgo = new Date();
          dateEighteenYearsAgo.setFullYear(
            dateEighteenYearsAgo.getFullYear() - 18
          );

          if (date < dateEighteenYearsAgo) {
            ctrl.$setValidity("eighteenplus", true);
          }
        }

        return ngModelValue;
      }
      ctrl.$parsers.push(customValidator);
    },
  };
});
