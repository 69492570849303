var module = angular
  .module("aanstellingspas.service.downloadfile", [])
  .factory("downloadfileService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    "FileSaver",
    "Blob",
    "$translate",
    "swangular",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location,
      FileSaver,
      Blob,
      $translate,
      swangular
    ) {
      var downloadServiceInstance = {};

      downloadServiceInstance.downloadFactuur = function (factuurId) {
        $http({
          method: "GET",
          url: backendBaseUrl + "/factuur/" + factuurId,
          responseType: "arraybuffer",
        }).then(
          function successCallback(response) {
            var file = new Blob([response.data], {
              type: "application/pdf",
            });

            FileSaver.saveAs(file, "factuur-" + factuurId + ".pdf");
          },
          function errorCallback(response) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.

            swangular.swal({
              title: $translate.instant("services.filedownload.mislukt"),
              type: "error",
              confirmButtonText: $translate.instant("services.filedownload.ok"),
              allowClickOutside: false,
              allowEscapeKey: false,
            });
          }
        );
      };

      return downloadServiceInstance;
    },
  ]);
