var module = angular
  .module("aanstellingspas.pasaanvraag-verlenging-correct", [
    "ngMessages",
    "validbirthdate",
    "angularDirtyformCheck",
  ])

  .config(function ($routeProvider) {
    $routeProvider.when(
      "/pasaanvraag-verlenging/beroeps/:correctableAanvraagId",
      {
        templateUrl:
          "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-beroeps.view.html",
        controller: "PasaanvraagVerlengingCorrectController",
        title: "titles.verbeteren",
        requireAuth: true,
        navigation_id: "pasaanvraag",
        pastype: "beroeps",
      }
    );
  })

  .config(function ($routeProvider) {
    $routeProvider.when(
      "/pasaanvraag-verlenging-gemeente/beroeps/:correctableAanvraagId",
      {
        templateUrl:
          "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-beroeps-gemeente.view.html",
        controller: "PasaanvraagVerlengingCorrectController",
        title: " titles.verbeteren",
        requireAuth: true,
        requireGemeente: true,
        navigation_id: "pasaanvraag",
        pastype: "beroeps",
      }
    );
  })

  .config(function ($routeProvider) {
    $routeProvider.when(
      "/pasaanvraag-verlenging/transport/:correctableAanvraagId",
      {
        templateUrl:
          "app/modules/pasaanvraag-verlenging/pasaanvraag-verlenging-transport.view.html",
        controller: "PasaanvraagVerlengingCorrectController",
        title: "titles.verbeteren",
        requireAuth: true,
        navigation_id: "pasaanvraag",
        pastype: "transport",
      }
    );
  })

  .controller("PasaanvraagVerlengingCorrectController", [
    "backendBaseUrl",
    "$scope",
    "authService",
    "$location",
    "BedrijfBvoService",
    "bedrijfsService",
    "$localStorage",
    "$rootScope",
    "$resource",
    "$log",
    "$timeout",
    "$route",
    "$routeParams",
    "vcRecaptchaService",
    "WizardHandler",
    "$http",
    "notificationservice",
    function (
      backendBaseUrl,
      $scope,
      authService,
      $location,
      bvoService,
      bedrijfService,
      $localStorage,
      $rootScope,
      $resource,
      $log,
      $timeout,
      $route,
      $routeParams,
      vcRecaptchaService,
      WizardHandler,
      $http,
      notificationservice
    ) {
      $scope.pasAanvraagModel = {};
      $scope.backendBaseUrl = backendBaseUrl;
      $scope.bedrijfsId = null;
      $scope.userProperties;

      //dit is bound aan de angular-wizard directive
      $scope.editMode = true;

      var waitForRenderAndDoSomething = function () {
        if ($http.pendingRequests.length > 0) {
          $timeout(waitForRenderAndDoSomething); // Wait for all templates to be loaded
        } else {
          //the code which needs to run after dom rendering
          $scope.getAanvraag();
        }
      };
      $timeout(waitForRenderAndDoSomething); // Waits for first digest cycle

      // check if the user has a valid bvo and set scope values accordingly
      bedrijfService
        .getBedrijfResourceForUser($localStorage.authticket.user_id)
        .then(function success(result) {
          // we now have the bedrijf for this user

          $scope.bedrijfsId = result.id;
        });

      $scope.getUserProperties = function () {
        $resource(backendBaseUrl + "/userproperties")
          .get()
          .$promise.then(function success(userProperties) {
            $scope.userProperties = userProperties;
          });
      };
      $scope.getUserProperties();

      $log.debug("get starts now");

      $scope.getAanvraag = function () {
        $resource(
          backendBaseUrl +
            "/pasaanvraagverbetering/" +
            $routeParams.correctableAanvraagId
        )
          .get()
          .$promise.then(
            function success(aanvraag) {
              $scope.correctableAanvraag = aanvraag;

              $timeout($scope.bindFormsToSteps).then(function () {
                $scope.goToFirstUncorrectedStep();
              });
            },
            function failure(response) {
              if (response.status === 422) {
                notificationservice.info(
                  "Deze pasaanvraag is al gecorrigeerd."
                );
                $location.path("/aanvragen");
                $scope.$apply();
              } else if (response.status === 404) {
                notificationservice.info(
                  "Deze pasaanvraag kon niet gevonden worden."
                );
                $location.path("/aanvragen");
                $scope.$apply();
              } else {
                $log.error(response);
              }
            }
          );
      };

      $scope.indienen = function () {
        var onderdelen = $scope.createOnderdelenFromForms();

        // do a correction for transport
        $resource(
          backendBaseUrl +
            "/pasaanvraagverbetering/" +
            $routeParams.correctableAanvraagId
        ).save(
          {
            onderdelen: onderdelen,
          },
          function success(result) {
            $scope.setAllFormsPristine();

            // for now, we trigger the getnotifications method manually
            notificationservice.getNotifications();

            $location.path("/pasaanvraag/verbeterd");
          },
          function fail(error) {
            $log.error(error);
          }
        );
      };
    },
  ]);
