var module = angular
  .module("aanstellingspas.verlengingModal", [])
  .directive("verlengingModal", [
    "$translate",
    "$rootScope",
    function ($translate, $rootScope) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        replace: true,
        scope: false,
        link: function (scope, element, attrs) {
          scope.isGemeente = $rootScope.isInRole("gemeente");
        },
      };
    },
  ]);
